import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   NavLink,
//   Link,
//   Redirect,
// } from "react-router-dom";

import backendurl from "../constants";
import { Link } from "react-router-dom";
// import SinglePage from './SinglePage';

function Expertise_second() {
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch(backendurl + "expertise-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "expertiseitem-show-list")
      .then((reslist) => reslist.json())
      .then((list) => setList(list));

    fetch(backendurl + "expertiseitem-show")
      .then((res) => res.json())
      .then((item) => setItem(item));
  }, []);
   
  return (
    <>
      {item ? (
        <section className="our-expertise-wr" scroll-color="white">
          <div className="center-wrapper">
            <div className="our-expertise-heading">
              <div className="common-heading">
                <h3>
                  <span className="wow fadeIndown" data-wow-duration="2s">
                    {data.name || "Our Expertise"}
                  </span>
                  <span className="wow fadeIndown" data-wow-duration="2s">
                    {data.title || "With Conative, you are guaranteed the perfect web solution"}
                  </span>
                </h3>
              </div>
            </div>
            <div className="all-development-blocks clearfix">
              <div className="scroll-line all-development-line"></div>
              <div className="development-blocks left">
              <div className="development-block-con clearfix">
                    {item?.length > 0 ? (
                      item.map((post) => (
                        <div key={post._id} className="development-block left">
                          <figure className="development-block-figure" key={post._id}>
                            <img
                              src={"/assets/imgupload/" + post.image}
                              alt="service_web_development"
                            />
                          </figure>
                          <div className="block-heading">
                            <h4>
                              <Link to={post.url}>
                                <span data-content={post.description}>
                                  {post.description}
                                </span>
                              </Link>
                            </h4>
                          </div>
                          <div className="development-block-icon">
                            <Link to={post.url}>
                              <i className="fa-solid fa-arrow-right-long"></i>
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                      <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_Web_Design_new.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            Web Design & Development
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>
                    
                    <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_eCommerce_Development_Icon_new.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            eCommerce Development
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>

                    <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_Mobile_App_Development_new.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            Mobile App Development
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>

                    <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_Graphic_Design_new.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            Creating Design Solution
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>

                    <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_Animation_New.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            Animation
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>

                    <div  className="development-block left">
                      <figure className="development-block-figure" >
                        <img
                          src="/assets/imgupload/2022-11_Digital_Marketing_New.svg" 
                          alt="service_web_development"
                        />
                      </figure>
                      <div className="block-heading">
                        <h4>
                          <Link>
                            <span>
                            Digital Solution
                            </span>
                          </Link>
                        </h4>
                      </div>
                      <div className="development-block-icon">
                        <Link>
                          <i className="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>
                    </>

                    
                    )}
                  </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}

export default Expertise_second;
