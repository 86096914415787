import React, { useEffect, useState } from "react";
import Banner from "../common/CommonBanner";
// import Career_form from './Career_form';
import backendurl from "../constants";
import Meta from "../Meta/Meta";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import conativeDataService from "../../services/dataService";
import { Link } from "react-router-dom";
import ScrollToTop from "../../services/ScrollToTop";
function Career() {
  const [data, setData] = useState([]);
  const [perkSlide, setPerkSlideData] = useState([]);
  const [lifeSlide, setLifeSlideData] = useState([]);
  const [careerpost, setCareerPostData] = useState([]);
  const [trigger, setTrigger] = useState(false)

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[trigger])

  async function fetchData(){
    try {
      const response = await conativeDataService.getCareerContent()
      setData(response.data)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    fetchData()
    

    fetch(backendurl + "perksSlideItem-show")
      .then((res) => res.json())
      .then((perkSlide) => setPerkSlideData(perkSlide));

    fetch(backendurl + "conativeLifeslide-show")
      .then((res) => res.json())
      .then((lifeSlide) => setLifeSlideData(lifeSlide));

    fetch(backendurl + "careerpost-show")
      .then((res) => res.json())
      .then((careerpost) => setCareerPostData(careerpost));
  }, []);


  return (
    <>
      <Meta
        title={data.meta_title}
        desc={data.meta_desc}
        keywords={data.meta_keywords}
      />
      <Banner title="Careers" />
      {data.content ? (
        <section className="cits-inner-head-content-wr">
          <div className="center-wrapper">
            <div
              className="cits-inner-head-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>
          </div>
        </section>
      ) : (
        ""
      )}
      <section className="build-career-wr">
        <div className="center-wrapper">
          <div className="build-career-heading wow fadeIn">
            <h4 className="common-heading">
              <span>{data.grow_career_title}</span>
              <span>{data.grow_career_heading}</span>
            </h4>
          </div>
          <div className="build-career-all-blocks">
            <div className="build-career-blocks-inner">
              {careerpost?.map((careerpostItem) => (
                <div className="build-career-blk clearfix">
                  <a
                    href={"/careers/" + careerpostItem.slug}
                    title="Conative Careers"
                  >
                    <div className="build-career-img left">
                      <figure>
                        <img
                          src={"/assets/imgupload/" + careerpostItem.image}
                          alt="career-web-development"
                        />
                      </figure>
                    </div>
                    <div className="build-career-text left">
                      <div className="page-medium-heading">
                        <h3>{careerpostItem.title}</h3>
                      </div>
                      <div className="build-career-para">
                        <p>{careerpostItem.description}</p>
                      </div>
                    </div>
                    <div className="build-career-icon right">
                      <a href={"/careers/" + careerpostItem.slug}>
                        <img
                          src="/assets/images/build-career-icon.svg"
                          alt="Build Career"
                        />
                      </a>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {perkSlide ? (
        <section className="perks-benefit-wr">
          <div className="center-wrapper">
            <div className="career-page-heading">
              <h3>{data.perks_benefits_heading}</h3>
            </div>
            <div className="perks-benefit-all-boxes clearfix">
              {perkSlide?.map((perkSlideItem) => (
                <div
                  className="perks-benefit-box wow fadeIn left"
                  data-wow-duration="2s"
                  data-wow-dealy="0.5s"
                >
                  <div className="perks-benefit-box-img">
                    <figure>
                      <img
                        src={"assets/imgupload/" + perkSlideItem.image}
                        alt="career-learing-dev"
                      />
                    </figure>
                  </div>
                  <div className="page-medium-heading perks-benefit-box-heading">
                    <h3>
                      <a >{perkSlideItem.perks_item_title}</a>
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {lifeSlide ? (
        <section className="life-at-conative-wr">
          <div className="center-wrapper">
            <div
              className="career-page-heading wow fadeInDown"
              data-wow-duration="2s"
              data-wow-dealy="0.5s"
            >
              <h3>{data.conative_life_heading}</h3>
            </div>
            <div className="life-at-conative-content">
              <div className="life-at-conative-slider">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  navigation={{ clickable: true }}
                >
                  {lifeSlide?.map((lifeSlideItem) => (
                    <SwiperSlide>
                      <div className="life-at-conative-slider-content">
                        <img
                          src={"assets/imgupload/" + lifeSlideItem.image}
                          alt="Anniversary of Conative"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="check-our-culture-btn">
              <Link
                to={data.our_culture_url}
                className="common-round-btn common-round-btn2"
              >
                CHECK OUR CULTURE
              </Link>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}

export default Career;
