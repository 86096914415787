import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import Banner from "../common/CommonBanner";
// import ModalVideo from "react-modal-video";
import "react-responsive-modal/styles.css";
import ReactPlayer from "react-player";
import { Modal } from "react-responsive-modal";
import "react-modal-video/scss/modal-video.scss";
import "react-modal-video/scss/modal-video.scss";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-coverflow";
import { Autoplay, EffectCoverflow } from "swiper";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Meta from "../Meta/Meta";
import { Link } from "react-router-dom";

const Testimonial = () => {
  const [data, setData] = useState([]);
  const [testimonialItem, setTestimonialItem] = useState([]);

  useEffect(() => {
    fetch(backendurl + "testimonial-content-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "testimonial-show")
      .then((response) => response.json())
      .then((testimonialItem) => setTestimonialItem(testimonialItem));
  }, []);
  const [isOpen, setOpen] = useState(false);
  const [videourl, setVideoUrl] = useState("");

  const open_modal = (val) => {
    var video_url = "/assets/imgupload/" + val;
    // 
    setVideoUrl(video_url);
    setOpen(true);
  };

  return (
    <>
      <Meta
        title={data.meta_title}
        desc={data.meta_desc}
        keywords={data.meta_keywords}
      />
      <Banner title="Testimonials" />

      <section className="con-testimonial-wr con-space-wr">
        <div className="center-wrapper">
          <div className="con-life-block">
            <div
              className="con-head-blk wow fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <h2>{data.heading}</h2>
              <span
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></span>
            </div>
          </div>
        </div>
        <div className="con-testimonial-slider-wr">
          <div className="con-testimonial-slider">
            <Swiper
              modules={[Autoplay, EffectCoverflow]}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 1,
                slideShadows: true,
              }}
              creativeEffect={{
                prev: {
                  shadow: true,
                  origin: "left center",
                  translate: ["-5%", 0, -200],
                  rotate: [0, 100, 0],
                },
                next: {
                  origin: "right center",
                  translate: ["5%", 0, -200],
                  rotate: [0, -100, 0],
                },
              }}
              pagination={true}
              spaceBetween={50}
              slidesPerView={2}
              initialSlide={1}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                979: {
                  slidesPerView: 2,
                  effect: "coverflow",
                },
              }}
            >
              {[...testimonialItem]?.reverse()?.map((post, index) => {
                return post.show_homepage === "true" ? (
                  <div className="con-testimonial-slide">
                    <SwiperSlide>
                      <figure className="con-testimonial-thumbnail">
                        <img
                          src={"./assets/imgupload/" + post.thumbImage}
                          alt="Testimonials Thumbnail"
                        />
                        <figcaption>
                          <div className="testimonial-slide-caption ">
                            <div className="quote-icon-img">
                              <img
                                src="../assets/images/testimonials_quotes_icon.svg"
                                alt="Quote Icon"
                              />
                            </div>
                            <div className="testimonial-slide-title">
                              <h4>{post.name}</h4>
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                      <div
                        className="testimonial-video-play-btn video-btn"
                        data-target="youtube"
                        data-v-id={"./assets/imgupload/" + post.video}
                        onClick={() => open_modal(post.video)}
                      >
                        <div className="play-btn-icon">
                          <i className="fa-solid fa-play"></i>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                ) : (
                  ""
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="testimonial-post-wr">
        <div className="center-wrapper">
          <div className="testimonial-post-area" id="testimonial-content">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 320: 1, 567: 2, 1199: 3, 1400: 4 }}
            >
              <Masonry
                columnsCount={3}
                gutter="40px"
                className="testimonial-masonry-grid"
              >
                {[...testimonialItem]?.reverse()?.map((post, index) => (
                  <div
                    className="testimonial-post-blk wow fadeIn"
                    data-wow-duration="2s"
                    data-wow-dealy="2s"
                  >
                    <div className="testimonial-post-inner-blk">
                      <div className="testimonial-front-blk">
                        <div className="post-head clearfix">
                          {post.name ? (
                            <div className="testimonial-slide-title left">
                              <h4>{post.name}</h4>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="quote-icon-img right">
                            <img
                              src="../assets/images/testimonials_quotes_icon.svg"
                              alt="Quote Icon"
                            />
                          </div>
                        </div>
                        <div className="post-img-blk">
                          <figure>
                            <img
                              src={"./assets/imgupload/" + post.image}
                              alt="Client"
                            />
                          </figure>
                        </div>
                        <div className="post-btn">
                          <Link  className="site-btn">
                            Read Feedback
                            <img
                              src="../assets/images/perk-and-benefit-icon.svg"
                              alt="Icon"
                            />
                          </Link>
                        </div>
                      </div>
                      {post.content ? (
                        <div className="testimonial-back-blk">
                          <div className="inner testimonial-content-inner" dangerouslySetInnerHTML={{__html:post.content}}>
                            {/* <p>{post.content}</p> */}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
          {/* <div className="view-more-btn" id="testimonial-content-loading">
            <Link to="/" className="common-round-btn common-round-btn2">
              View more
            </Link>
          </div> */}
        </div>
      </section>

      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        center
        classNames={{
          overlay: "testimonial-custom-overlay",
          modal: "testimonial-custom-modal",
        }}
      >
        <ReactPlayer
          url={videourl}
          volume={true}
          muted={false}
          autoPlay={1}
          loop={true}
          controls={true}
          playsinline
          playing={true}
        />
      </Modal>
    </>
  );
};

export default Testimonial;
