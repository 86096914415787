import React from "react";
import pageNotFound from "../../assets/images/404.svg";
const PageNotFound = () => {
  return (
    <>
      <main id="site-content" className="error-page-wr">
        <section className="error404-content">
          <div className="center-wrapper">
            <figure>
              <img src={pageNotFound} alt=" Conative Can not find this page." />
            </figure>
            <div className="error-heading">
              <h1>We couldn't find the page...</h1>
            </div>
            <div className="intro-text">
              <p>
                The page you were looking for could not be found. It might have
                been removed, renamed, or did not exist in the first place.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PageNotFound;
