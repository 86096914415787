import React from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import BannerImage from "../../assets/images/Services/Mobile_App_Header_Image.jpg";
import AndroidDevelopment from "../../assets/images/Services/Android_Development_Image.png";
import ReactNative from "../../assets/images/Services/React_Native_Image.png";
import FlutterApp from "../../assets/images/Services/Flutter_App_Image.jpg";
import FlutterAppDevelopment from "../../assets/images/Services/Flutter_App_Development.png";
import CrossPlatform from "../../assets/images/Services/Servie_Offering_Image.jpg";
import IosDevelopment from "../../assets/images/Services/Ios_Development.png";
import { Link } from "react-router-dom";

function mobileappdevelopment() {
  return (
    <>
      <Banner title="Mobile App Development" />
      <section className="con-mobile-app-wr con-space-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={BannerImage} alt="Conative Mobile App Development" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Mobile App Development</h3>
            </div>
          </div>
          <div className="mobile-header-left-right-wr clearfix">
            <div className="con-page-content left">
              <p>
                Mobile applications make it easier for customers to stay
                connected and informed about their favourite brands and offers,
                while also obtaining business information more quickly.
              </p>
              <p>
                We at <strong>Conative IT Solutions</strong> are committed to
                offering services related to mobile app development that are
                optimally usable on any android device and are mobile-friendly.
                Many websites do not work properly in the mobile application and
                users have to face various challenges related to,
              </p>
            </div>
            <div className="mobile-header-list con-motion-list-wr con-tab-list-wr right">
              <ul>
                <li>Page display</li>
                <li>Readable fonts</li>
                <li>Text formatting</li>
                <li>Optimize media display</li>
                <li>Slow loading speed</li>
                <li>An issue with Call to Action buttons</li>
              </ul>
            </div>
          </div>
          <div className="con-page-content mobile-bottom-header-wr">
            <p>
              Conative IT Solutions has the <strong>perfect solution</strong>{" "}
              for you if you're looking for the top mobile apps and web design
              services.{" "}
            </p>
            <p>
              A dedicated team at Conative IT Solutions possesses a superb
              combination of technical, professional and functional knowledge,
              a team that is focused on relevant outcomes and a wealth of
              experience.{" "}
            </p>
            <p>
              We always create a{" "}
              <strong>long-lasting commercial solution</strong> that satisfies
              your requirements.
            </p>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-space-wr con-react-service-wr">
        <div
          className="white-ring-circle wow pulse animated animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div className="con-left-right-wr clearfix">
            <div className="con-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>React Native</h3>
              </div>
              <div className="con-page-content">
                <p>
                  We at Conative IT Solutions provide React Native web and
                  mobile app development services all over the world, with
                  companies of varying sizes, leveraging JavaScript open-source
                  libraries at top.{" "}
                </p>
                <p>
                  We are highly skilled resources have mastered ReactJS
                  development in order to deliver outstanding services related
                  to development to our valued clients.
                </p>
              </div>
            </div>
            <div className="con-right-wr right">
              <img src={ReactNative} alt="React Native" />
            </div>
          </div>
          <div className="react-lib-wr image-content-wr">
            <div className="icon-content-blk">
              <div className="content-blk">
                <h5>React Native Android App Development</h5>
              </div>
            </div>
            <div className="icon-content-blk">
              <div className="content-blk right">
                <h5>UI / UX and API Development</h5>
              </div>
            </div>
            <div className="icon-content-blk">
              <div className="content-blk">
                <h5>ReactJS Front-End Development</h5>
              </div>
            </div>
            <div className="icon-content-blk">
              <div className="content-blk">
                <h5>eCommerce Development</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-space-wr con-android-develop-wr">
        <div className="center-wrapper">
          <div className="con-left-right-wr clearfix">
            <div className="con-left-wr left">
              <img src={AndroidDevelopment} alt="Android Development" />
            </div>
            <div className="con-right-wr right">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Android App Development</h3>
              </div>
              <div className="con-page-content">
                <p>
                  In the era of the digital world android applications are the
                  most versatile open-source mobile operating systems. It runs
                  on a variety of mobile devices and creates the space for{" "}
                  <strong>human life betterment.</strong> Our Android app
                  development team has years of experience in developing apps
                  which are uses on the daily basis like,
                </p>
                <p>
                  Health and Fitness, Real estate, Corporate level software and
                  application, Related to education and career development, Law
                  based issue and Others. In order to provide a personalised
                  and easy-to-use application, we design it based on your
                  requirements.{" "}
                </p>
              </div>
              <div className="con-tab-list-wr">
                <ul>
                  <li>Open Source Platform</li>
                  <li>Android App Development</li>
                  <li>Business Tools Application</li>
                  <li>Travel Application</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-flutter-app-wr con-space-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={FlutterApp} alt="Conative Flutter App Development" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Flutter app development</h3>
            </div>
          </div>
          <div className="con-flutter-bottom-app-wr con-left-right-wr clearfix">
            <div className="con-left-wr left">
              <div className="con-page-content mobile-bottom-header-wr">
                <p>
                  Our Flutter app development services help you create engaging
                  and highly functional mobile, web and desktop applications.
                  By combining native performance with a flexible and expressive
                  UI we come up with top-notch solutions for our clients.
                </p>
                <p>
                  <strong>We have an experienced team</strong> who can code your
                  application on the open-source development framework, ensuring
                  a high-loading speed,
                </p>
              </div>
              <div className="con-tab-list-wr">
                <ul>
                  <li>Consultation Services</li>
                  <li>Cross-Platform App Development</li>
                  <li>Flutter App UI/UX Design</li>
                  <li>Support & Maintenance</li>
                </ul>
              </div>
            </div>
            <div className="con-right-wr right">
              <img src={FlutterAppDevelopment} alt="Flutter App Development" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-space-wr con-android-develop-wr">
        <div className="center-wrapper">
          <div className="con-left-right-wr clearfix">
            <div className="con-right-wr right">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Ios Development</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Developing iOS applications is the most effective solution for
                  creating scalable, secure digital solutions. We at Conative IT
                  Solutions deliver iOS software developers' services to make
                  sure that your applications are designed according to this
                  fast evolving world.
                </p>
                <p>
                  We offer full-stack iPhone app development. We have released
                  many native iOS applications that have seen exponential
                  growth. We have created apps for a variety of use cases,
                  including real-time analytics, route matching, location and
                  more across several industries.{" "}
                </p>
              </div>
              <div className="con-blocquote-wr">
                <ul>
                  <li>iOS mobile app development</li>
                  <li>iOS UI/UX Design</li>
                  <li>iOS Universal App Development</li>
                  <li>custom iOS Apps</li>
                </ul>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={IosDevelopment} alt="Ios Development" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-space-wr con-cross-plateform-wr">
        <div className="center-wrapper">
          <div className="con-cross-outer-wr">
            <div className="con-cross-inner-plateform-wr">
              <div
                className="white-ring-circle wow pulse animated animated animated"
                data-wow-duration="2s"
                data-wow-dealy="3s"
                data-wow-iteration="infinite"
              ></div>
              <div className="con-head-blk">
                <h3>Cross Platform</h3>
              </div>
              <div className="con-page-content">
                <p>
                  The development of mobile applications has increased the
                  popularity of cross-platform apps. The ability to run software
                  on multiple mobile platforms allows startups to save money and
                  reduce development time.
                </p>
                <p>
                  Cross-platform mobile applications are developed to function
                  on multiple mobile platforms. The apps are compatible with
                  more than one operating system, such as iOS and Android. We
                  are working on multiple Cross-Platform Technology and
                  Frameworks like, Flutter, React Native, Node.js, jQuery Mobile
                </p>
              </div>
              <div className="con-cross-bottom-wr con-tab-list-wr">
                <h5>Services we are offering</h5>
                <ul>
                  <li className="for-mob-res">Native Mobile app development</li>
                  <li>Hybrid Mobile app development</li>
                  <li>Progressive web app development</li>
                  <li>Agile development</li>
                </ul>
              </div>
            </div>
            <div className="con-cross-image-blk">
              <img src={CrossPlatform} alt="Cross Platform" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-work-service-wr con-mobile-work-wr">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/ecommerce-development-services"
                    className="circle-fill-btn"
                  >
                    <span>eCommerce & CMS</span>
                    <img
                      src="../assets/images/service_read_more.svg"
                      alt=" E-Commerce & CMS"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Digital Marketing & <br />
                      Content Solutions
                    </span>
                    <img
                      src="../assets/images/service_read_more.svg"
                      alt="Digital Marketing Content Solutions"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Web Design & <br />
                      Development
                    </span>
                    <img
                      src="../assets/images/service_read_more.svg"
                      alt="Web Deisgn Development"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img
                      src="../assets/images/service_read_more.svg"
                      alt="Animation"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/graphic-design-services"
                    className="circle-fill-btn"
                  >
                    <span>Designing</span>
                    <img
                      src="../assets/images/service_read_more.svg"
                      alt=" Designing"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default mobileappdevelopment;
