import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import "./header-submenu.css";
import WebsiteDesign from "../../assets/images/menu-icons/Website_Design.svg";
import GraphicsDesign from "../../assets/images/menu-icons/Graphics_Design.svg";
import BrochureDesign from "../../assets/images/menu-icons/Brochure_Design.svg";
import CoroprateBrandIdentity from "../../assets/images/menu-icons/coroprate_brand_identity.svg";
import LogoDesign from "../../assets/images/menu-icons/Logo_Design.svg";
import BusinessCardDesign from "../../assets/images/menu-icons/Business_card_design.svg";
import SocialMediaDesign from "../../assets/images/menu-icons/Social_Media_design.svg";
import FlyerPamphletDesign from "../../assets/images/menu-icons/Flyer_Design.svg";
import TshirtDesign from "../../assets/images/menu-icons/Tshirt_Design.svg";
import { Link,useNavigate } from "react-router-dom";

const PortfolioSubMenu = (props) => {
  const [socialslink, setSocial] = useState([]);
  const [filler, setFiller] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    
      fetch(backendurl + "option-show")
        .then((response) => response.json())
        .then((data) => {setFiller(data)});
      fetch(backendurl + "social-media")
        .then((res) => res.json())
        .then((socialslink) => setSocial(socialslink));
    
    }, []);
    const handleLinkClick = (targetUrl) => {
      try {
        props.close_menu();
  
        if (window.location.pathname !== targetUrl) {
          navigate(targetUrl);
        }
  
      } catch (error) {
        
      }
    };
  return (
    <>
      <div className="compnay-submenu-wr">
        <ul className="submenu">
          <li> 
          <Link to={socialslink?.length > 0 ? "/website-portfolio" : "/" }
              onClick={()=> handleLinkClick("/website-portfolio")}
              className="menu-text">

              <span className="submenu-image">
                <img src={WebsiteDesign} alt="Website Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Website Design</span>
                <span className="header-submenu-content">
                  <p>We build what you think.</p>
                  <a href={socialslink?.length > 0 ? "/website-portfolio" : "/" } 
              onClick={()=> handleLinkClick("/website-portfolio")}
              >Know More</a>
                </span>
              </span>
            </Link>
          </li>
          <li>
            <a href={socialslink?.length > 0 ? "/graphic-portfolio" : "/" } 
              onClick={()=> handleLinkClick("/graphic-portfolio")}
              className="menu-text">
              <span className="submenu-image">
                <img src={GraphicsDesign} alt="Graphic Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Graphic Design</span>
                <span className="header-submenu-content">
                  <p>We innovate what you think.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio" : "/" } 
              onClick={()=> handleLinkClick("/graphic-portfolio")}
              >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a href={socialslink?.length > 0 ? "/graphic-portfolio/brochure-design" : "/" } 
              onClick={()=> handleLinkClick("/graphic-portfolio/brochure-design")}
              className="menu-text">
              <span className="submenu-image">
                <img src={BrochureDesign} alt="Brochure Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Brochure Design</span>
                <span className="header-submenu-content">
                  <p>Be remarkable to others.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/brochure-design" : "/" }
              onClick={()=> handleLinkClick("/graphic-portfolio/brochure-design")}
              >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a
            href={socialslink?.length > 0 ? "/graphic-portfolio/brand-identity-design" : "/" }
            onClick={()=> handleLinkClick("/graphic-portfolio/brand-identity-design")}
              className="menu-text"
            >
              <span className="submenu-image">
                <img
                  src={CoroprateBrandIdentity}
                  alt="Coroprate Brand Identity Design"
                />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">
                  Coroprate Brand Identity Design
                </span>
                <span className="header-submenu-content">
                  <p>Your reputation is our responsibility.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/brand-identity-design" : "/" }
                     onClick={()=> handleLinkClick("/graphic-portfolio/brand-identity-design")}>Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a href={socialslink?.length > 0 ? "/graphic-portfolio/logo-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/logo-design")}
                 className="menu-text">
              <span className="submenu-image">
                <img src={LogoDesign} alt=" Logo Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Logo Design</span>
                <span className="header-submenu-content">
                  <p>We make you inimitable.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/logo-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/logo-design")}
                >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a
              href={socialslink?.length > 0 ? "/graphic-portfolio/business-card-design" : "/" }
              onClick={()=> handleLinkClick("/graphic-portfolio/business-card-design")}
              className="menu-text"
            >
              <span className="submenu-image">
                <img src={BusinessCardDesign} alt="Business Card Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Business Card Design</span>
                <span className="header-submenu-content">
                  <p>Have your business in everybody’s pocket.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/business-card-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/business-card-design")}
                >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a
                href={socialslink?.length > 0 ? "/graphic-portfolio/social-media-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/social-media-design")}
              	className="menu-text"
            >
              <span className="submenu-image">
                <img src={SocialMediaDesign} alt="Social Media Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Social Media Design</span>
                <span className="header-submenu-content">
                  <p>We make you go with the trend.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/social-media-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/social-media-design")}
                >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a
                  href={socialslink?.length > 0 ? "/graphic-portfolio/flyer-pamphlet-design" : "/" }
              className="menu-text"
              onClick={()=> handleLinkClick("/graphic-portfolio/flyer-pamphlet-design")}

            >
              <span className="submenu-image">
                <img src={FlyerPamphletDesign} alt="Flyer Pamphlet Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Flyer/Pamphlet Design</span>
                <span className="header-submenu-content">
                  <p>
                    Take your business to the elevation(incomparable) with us.
                  </p>
                  <a href="/graphic-portfolio/flyer-pamphlet-design"
                onClick={()=> handleLinkClick("/graphic-portfolio/flyer-pamphlet-design")}
                  >Know More</a>
                </span>
              </span>
            </a>
          </li>
          <li>
            <a href={socialslink?.length > 0 ? "/graphic-portfolio/tshirt-design" : "/" } 
                onClick={()=> handleLinkClick("/graphic-portfolio/tshirt-design")}
                className="menu-text">
              <span className="submenu-image">
                <img src={TshirtDesign} alt="Tshirt Design" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">T-shirt Design</span>
                <span className="header-submenu-content">
                  <p>Grab the deal, to make your competitor defeat.</p>
                  <a href={socialslink?.length > 0 ? "/graphic-portfolio/tshirt-design" : "/" }
                onClick={()=> handleLinkClick("/graphic-portfolio/tshirt-design")}
                >Know More</a>
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default PortfolioSubMenu;
