import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import backendurl from "../constants";
import Menu from "../Menu/HeaderMenu";
import LogoIconImage from "../../assets/images/Logo_Icon.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import ConfettiSmall from "./loaders/ConfettiSmall";


function Header() {
    const [data, setData] = useState(null);
    const [isOpen, setOpen] = useState(true);
    const location = useLocation();

    useEffect(() => { 
        fetch(backendurl + "option-show")
            .then((response) => response.json())
            .then((data) => setData(data));

        // Close the menu on route change
        close_menu();

        // Clean up the event listener when the component unmounts
        return () => {
            // Cleanup, if needed
        };
    }, [location.pathname]);

    const open_menu = () => {
        setOpen(!isOpen);
    };

    const close_menu = () => {
        setOpen(true);
    };

	useEffect(() => {
        $('body').css('overflow', isOpen ? 'auto' : 'hidden');
    }, [isOpen]);
	
	return (
			<div style={{width:"100%", backgroundColor:"#fbfbfb"}}>	
			<div className="center-wrapper-header">
			<header>
			{/* <marquee className="header-marquee-container" ><span>Cheers to a Decade of Excellence! CITS Celebrates 10 Years of Success – Crafting Solutions, Illuminating Journeys.</span></marquee> */}
			{/* <div className="marquee-wr">
			<div className="marquee-container">
			<figure className="clinking-glass-img"><img src="./assets/images/clinkingGlass.svg" alt="conative 10th aniversary props image"/> </figure>
				<h3><span className="not-bold"> Cheers to a</span> <span className="bold"> Decade of Excellence!</span> <span className="not-bold">CITS Celebrates </span><span className="bold"> 10 Years </span><span className="not-bold"> of Success – Crafting Solutions, Illuminating Journeys.</span></h3>
			<figure className="clinking-glass-img"><img src="./assets/images/clinkingGlass.svg" alt="conative 10th aniversary props image"/> </figure>

				</div>

    		</div> */}
			
				<div className="header-area clearfix">

					{data?.image ? (
						<div className="logo-block left">
							<a href="https://conativeitsolutions.com/" className="non-sticky">
							<div className="header-confetti-left">
				</div>
								<img
									src={"../assets/imgupload/" + data?.image}
									alt="Conative It Solutions Logo"
								/>
											<div className="header-confetti-right" >
				</div>

							</a>
							<a href="https://conativeitsolutions.com/" className="sticky">
								<img src={LogoIconImage} alt="Conative It Solutions Logo" />
							</a>
						</div>
					) : (
						<div className="logo-block  left">
						<a href="https://conativeitsolutions.com/" className="non-sticky">
							
							<img
								src={"../assets/imgupload/2023-5_Conative_Logo_1.svg"}
								alt="Conative It Solutions Logo"
							/>
						</a>
						<Link href="https://conativeitsolutions.com/" className="sticky">
							<img src={LogoIconImage} alt="Conative It Solutions Logo" />
						</Link>
					</div>
					)}

		
					<div className="header-contact-content right">
						<div className="contact-blk">
							{data?.alternateemail ? (
								<Link
									to={"mailto:" + data?.alternateemail}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/hr_mail_Icon_new.svg"
										alt="header mail icon"
									/>
									<span data-content={data?.alternateemail}>
										{data?.alternateemail}
									</span>
								</Link>
							) : (
								<Link
									to={"mailto:" + "hr@conative.in"}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/hr_mail_Icon_new.svg"
										alt="header mail icon"
									/>
									<span>
									hr@conative.in
									</span>
								</Link>
							)}
							{data?.email ? (
								<Link
									to={"mailto:" + data?.email}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/header_mail_icon.svg"
										alt="header mail icon"
									/>
									<span data-content={data?.email}>{data?.email}</span>
								</Link>
							) : (
								<Link
									to={"mailto:" + "info@conative.in"}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/header_mail_icon.svg"
										alt="header mail icon"
									/>
									<span>
									info@conative.in
									</span>
								</Link>
							)}
							{data?.phone ? (
								<Link
									to={"tel:" + data?.phone}
									className="circle-hover-effect-call"
								>
									<div className="hover-circle-call"></div>
									<img
										src="../assets/images/header_whatsapp_icon.svg"
										alt="header mail icon"
									/>
									<span data-content={data?.phone}>{data?.phone}</span>
								</Link>
							) : (
								<Link
								to={"tel:" + "+91 82697 88173"}
								className="circle-hover-effect-call"
							>
								<div className="hover-circle-call"></div>
								<img
									src="../assets/images/header_whatsapp_icon.svg"
									alt="header mail icon"
								/>
								<span>+91-82697 88173</span>	
							</Link>
							)}
						</div>
						
						<div className="our-work-blk">
						{data?.phone ? (
							<a
								href="/website-portfolio"
								className="site-btn site-btn1"
							>
								<span>our work</span> <i className="fas fa-arrow-up"></i>
							</a>):(
								<a
								href="/website-portfolio"
								className="site-btn site-btn1"
							>
								<span>our work</span> <i className="fas fa-arrow-up"></i>
							</a>
							)}
						</div>
						{data?.phone ? (
						<div className="menu-blk" onClick={open_menu}>
							<img src="../../assets/images/Menu.svg" alt="Menu" />
						</div> ):(
							<div className="offilne-menu-blk" onClick={open_menu}>
							<img src="../../assets/images/Menu.svg" alt="Menu" />
						</div>	
						)}
					</div>
				</div>
			</header>
			<div
                className={
                    isOpen ? "header-menu-main-wr hide" : "header-menu-main-wr show"
                }
            >
                {data &&  <Menu close_menu={close_menu} />}
            </div>
			</div>
		</div>
	);
}

export default Header;
