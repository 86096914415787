import "../../assets/css/Loading.css";
// import "../../../public/assets/js/hurray-confetti.min.js";
// import "../../../public/assets/js/custom";
import footerLogo from "../../assets/images/conative_It_solutions_logo.svg";
// import React from "react";
import LoaderVideo from "../../assets/gif/Conative_loader.gif";
function Loadingpage() {
	 // Generate a timestamp to make the GIF URL unique
	 const timestampQueryParam = new Date().getTime();

	 // Append the timestamp to the imported local GIF file path
	 const gifPath = `${LoaderVideo}?${timestampQueryParam}`;
  return (
    <>
      <div className="loader-video-block">
        <img src={gifPath} alt="Conative It Solutions" />
      </div>
    </>
  );
}

export default Loadingpage;