import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import "./header-submenu.css";
import { Link,useNavigate } from "react-router-dom";
import Animation from "../../assets/images/menu-icons/Animation.svg";
import Designing from "../../assets/images/menu-icons/Designing.svg";
import Digital_marketing from "../../assets/images/menu-icons/Digital_marketing.svg";
import eCommerce_Development from "../../assets/images/menu-icons/eCommerce_Development.svg";
import Mobile_App_Dev from "../../assets/images/menu-icons/Mobile_App_Dev.svg";
import Web_Design_and_Dev from "../../assets/images/menu-icons/Web_Design_and_Dev.svg";

const ServicesSubMenu = (props) => {
  const [socialslink, setSocial] = useState([]);
  const [filler, setFiller] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    
      fetch(backendurl + "option-show")
        .then((response) => response.json())
        .then((data) => {setFiller(data)});
      fetch(backendurl + "social-media")
        .then((res) => res.json())
        .then((socialslink) => setSocial(socialslink));
    
    }, []);
        
    const handleLinkClick = (targetUrl) => {
      try {
        props.close_menu();
  
        if (window.location.pathname !== targetUrl) {
          navigate(targetUrl);
        }
  
      } catch (error) {
        
      }
    };

  return (
    <>
      <div className="compnay-submenu-wr">
        <ul className="submenu">
          <li>
            <Link
             to={socialslink?.length > 0 ? "/website-design-development-services" : "/" }
              className="menu-text"
              onClick={()=> handleLinkClick("/website-design-development-services")}
            >
              <span className="submenu-image">
                <img src={Web_Design_and_Dev} alt="Web Design & Development" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Web Design & Development</span>
                <span className="header-submenu-content">
                  <p>Excel the Top-notch web services.</p>
                  <Link  to={socialslink?.length > 0 ? "/website-design-development-services" : "/" }
              onClick={()=> handleLinkClick("/website-design-development-services")}
              >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
            <Link     to={socialslink?.length > 0 ? "/mobile-app-development-services" : "/" }
              onClick={()=> handleLinkClick("/mobile-app-development-services")}
              className="menu-text">
              <span className="submenu-image">
                <img src={Mobile_App_Dev} alt="Mobile App Development" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Mobile App Development</span>
                <span className="header-submenu-content">
                  <p>Get user based services at oneplace.</p>
                  <Link to={socialslink?.length > 0 ? "/mobile-app-development-services" : "/" }
              onClick={()=> handleLinkClick("/mobile-app-development-services")}
              >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
            <Link to={socialslink?.length > 0 ? "/ecommerce-development-services" : "/" }
              onClick={()=> handleLinkClick("/ecommerce-development-services")}
              className="menu-text">
              <span className="submenu-image">
                <img src={eCommerce_Development} alt="E-Commerce And CMS" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">E-Commerce And CMS</span>
                <span className="header-submenu-content">
                  <p>Attract the customers with the best ROI.</p>
                  <Link to={socialslink?.length > 0 ? "/ecommerce-development-services" : "/" }
              onClick={()=> handleLinkClick("/ecommerce-development-services")}
              >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
            <Link to={socialslink?.length > 0 ? "/animation-services" : "/" }
              onClick={()=> handleLinkClick("/animation-services")}
              className="menu-text">
              <span className="submenu-image">
                <img src={Animation} alt="Animation" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Animation</span>
                <span className="header-submenu-content">
                  <p>We convert your idea into reality.</p>
                  <Link to={socialslink?.length > 0 ? "/animation-services" : "/" }
                  onClick={()=> handleLinkClick("/animation-services")}
                  >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
          <Link to={socialslink?.length > 0 ? "/graphic-design-services" : "/" }
                  onClick={()=> handleLinkClick("/graphic-design-services")}
                  className="menu-text">
              <span className="submenu-image">
                <img src={Designing} alt="Designing" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Designing</span>
                <span className="header-submenu-content">
                  <p>Get the first-rate design to entice.</p>
                  <Link to={socialslink?.length > 0 ? "/graphic-design-services" : "/" }
                  onClick={()=> handleLinkClick("/graphic-design-services")}
                  >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
          <Link to={socialslink?.length > 0 ? "/digital-marketing-services" : "/" }className="menu-text"
                  onClick={()=> handleLinkClick("/digital-marketing-services")}
                  >
              <span className="submenu-image">
                <img
                  src={Digital_marketing}
                  alt="Digital Marketing & Content Solutions"
                />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">
                  Digital Marketing & Content Solutions
                </span>
                <span className="header-submenu-content">
                  <p>We are ready to make you a brand.</p>
                  <Link to={socialslink?.length > 0 ? "/digital-marketing-services" : "/" }
                  onClick={()=> handleLinkClick("/digital-marketing-services")}
                  >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ServicesSubMenu;
