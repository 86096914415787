import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import Common_Banner from "../common/CommonBanner";
import Meta from "../Meta/Meta";
import { Link } from "react-router-dom";
const Team = () => {
  const [data, setData] = useState([]);
  const [teamItem, setTeamItem] = useState([]);
	
  useEffect(() => {
    fetch(backendurl + "team-content-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "teamitem-show")
      .then((response) => response.json())
      .then((teamItem) => setTeamItem(teamItem));
	  
	  
  }, []);
  return (
    <>
      <Meta
        title={data.meta_title}
        desc={data.meta_desc}
        keywords={data.meta_keywords}
      />
      <Common_Banner title="Our Team" />
      {data.description ? (
        <section className="con-team-content-wr">
          <div
            className="white-ring-circle wow pulse animated animated animated"
            data-wow-duration="2s"
            data-wow-dealy="3s"
            data-wow-iteration="infinite"
          ></div>
          <div className="center-wrapper">
            <div className="con-img-title-blk">
              <div
                className="con-about-img con-team-header-image fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img
                  src={"./assets/imgupload/" + data.mainimage}
                  alt="Conative It Solutions Team"
                />
              </div>
              <div className="con-team-content con-overlay-title common-heading">
                <div
                  className="con-team-inner con-page-content wow fadeIn"
                  data-wow-duartion="2s"
                  data-wow-delay="0.2s"
                >
                  <h2>{data.heading}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {teamItem ? (
        <section className="con-team-built-wr">
          <div className="center-wrapper">
            <div className="con-team-built-head common-heading ">
              <h3>
                <span>{data.believe_title}</span>
                <span>{data.believe_heading}</span>
              </h3>
            </div>
            <div className="con-icon-title-wr team-icon-title-wr">
              {teamItem?.map((item) => (
                <div
                  className="con-icon-title-blk wow fadeInDown"
                  data-wow-duartion="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="con-icon-blk">
                    <img
                      src={"./assets/imgupload/" + item.image}
                      alt="Innovative Managers "
                    />
                  </div>
                  <div className="con-title-blk">
                    <h4>{item.name}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <section className="con-team-contact-wr">
        <div className="con-team-contact-inner clearfix">
          <div className="con-team-contact-left left">
            <div
              className="common-heading wow fadeInDown"
              data-wow-duartion="2s"
              data-wow-delay="0.2s"
            >
              <h3>
                {data.part_title ? <span>{data.part_title}</span> : ""}
                {data.part_heading ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: data.part_heading }}
                  ></span>
                ) : (
                  ""
                )}
              </h3>
            </div>
            <div className="con-team-upload-wr">
              {data.upload_resume_url ? (
                <Link
                  to={data.upload_resume_url}
                  className="common-round-btn common-round-btn2"
                >
                  Upload resume
                </Link>
              ) : (
                ""
              )}
              {data.linkedin_url ? (
                <Link
                  to={data.linkedin_url}
                  className="linkdin-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
            <div
              className="common-heading wow fadeInDown"
              data-wow-duartion="2s"
              data-wow-delay="0.2s"
            >
              <h3>
                {data.start_title ? <span>{data.start_title}</span> : ""}
                {data.start_heading ? <span>{data.start_heading}</span> : ""}
              </h3>
            </div>
            {data.fill_form_url ? (
              <div className="con-team-contact-wr">
                <Link
                  to={data.fill_form_url}
                  className="common-round-btn common-round-btn2"
                >
                  Fill out the form!!
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          {data.wantimage ? (
            <div className="con-team-contact-right right">
              <figure
                className="wow fadeInRight"
                data-wow-duartion="2s"
                data-wow-delay="0.2s"
              >
                <img
                  src={"./assets/imgupload/" + data.wantimage}
                  alt="Conative service worker"
                />
              </figure>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      {/* team */}
    </>
  );
};
export default Team;
