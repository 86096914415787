import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import Common_Banner from "../common/CommonBanner";
import axios from "axios";
import swal from "sweetalert";
import Meta from "../Meta/Meta";
import { Link } from "react-router-dom";

const Contact = () => {
  const [data, setData] = useState([]);
  const [socialslink, setSocial] = useState([]);
  const [contactus, setContactus] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();

    var spinner = document.getElementById("loading_spinner_wr");
    spinner.style.display = "block";

    var filename = "";
    var filepath = "";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const file_res = await axios.post(backendurl + "filesUpload", formData);
      document.getElementById("upload_file_path").value =
        file_res["data"]["filepath"];
      document.getElementById("upload_file_name").value =
        file_res["data"]["file"];
      filename = file_res["data"]["file"];
      filepath = file_res["data"]["filepath"];
    } catch (ex) {
      
    }

    const { name, email, contact, message } = mailerState;

    var checkboxes = document.getElementsByName("services[]");
    var services = "";
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        services += "," + checkboxes[i].value;
      }
    }
    if (services) services = services.substring(1);
    document.getElementById("allservices").value = services;

    var subject = document.getElementById("subject").value;

    // 

    if (
      name == "" ||
      email == "" ||
      contact == "" ||
      services == "" ||
      subject == "" ||
      message == ""
    ) {
      swal("", "Please fill all the required* fields!", "info");
	  spinner.style.display = "none";
    } else {
      const response = await fetch(backendurl + "sendmail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          contact,
          message,
          services,
          filename,
          filepath,
          subject,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setMailerState({
            name: "",
            email: "",
            contact: "",
            message: "",
          });
          document.getElementById("contactformreset").reset();
          spinner.style.display = "none";
          var res_message = data["message"];
          if (data["code"] == "success") {
            swal({
              text: `${res_message}`,
              icon: "success",
            }).then((value) => {
              window.location.reload();
            });
          } else {
            swal({
              text: `${res_message}`,
              icon: "info",
            }).then((value) => {
              window.location.reload();
            });
          }
        });
    }
  };

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // fetch only on the initial render
  useEffect(() => {
    fetch(backendurl + "option-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "social-media")
      .then((res) => res.json())
      .then((socialslink) => setSocial(socialslink));

    fetch(backendurl + "contactus-content-show")
      .then((res) => res.json())
      .then((contactus) => setContactus(contactus));
  }, []);

  return (
    <>
      <Meta
        title={contactus.meta_title}
        desc={contactus.meta_desc}
        keywords={contactus.meta_keywords}
      />
      <Common_Banner title="Contact Us" />
      <section className="your-project-wr">
        <div className="center-wrapper">
          {contactus.heading ? (
            <div className="your-project-heading">
              <h4
                className="common-heading wow fadeIn"
                dangerouslySetInnerHTML={{
                  __html: contactus.heading,
                }}
              ></h4>
            </div>
          ) : (
            ""
          )}
          {contactus.sub_heading ? (
            <div className="your-project-sub-heading wow fadeIn">
              <h4>{contactus.sub_heading}</h4>
            </div>
          ) : (
            ""
          )}
          <div className="contact-page-form-wr">
            <div className="contact-page-form-con clearfix">
              <div className="conatct-form-detail left">
                <div className="conatct-form-detail-inner">
                  {data.alternatephone ? (
                    <div className="call-us-on con-combo-heading-blk">
                      <h4 className="contact-form-left-heading">
                        <span>Call us on</span>
                        <Link to={"tel:" + data.alternatephone}>
                          {data.alternatephone}
                        </Link>
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.email ? (
                    <div className="mail-us con-combo-heading-blk">
                      <h4 className="contact-form-left-heading">
                        <span>Mail us</span>
                        <Link to={"mailto:" + data.email}>{data.email}</Link>
                        <br />
                        <br />
                        <Link to={"mailto:" + data.alternateemail}>
                          {data.alternateemail}
                        </Link>
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="follow-us con-combo-heading-blk">
                    <h4 className="contact-form-left-heading">
                      <span>Follow us</span>
                    </h4>
                  </div>
                  {socialslink ? (
                    <div className="social-icon-form-left">
                      <ul className="contact-social-icon-list">
                        {socialslink?.map((social) => {
                          return social.socialurl != "" ? (
                            <li>
                              <Link
                                to={social.socialurl}
                                target="_blank"
                                rel="noreferrer"
                                dangerouslySetInnerHTML={{
                                  __html: social.description,
                                }}
                              ></Link>
                            </li>
                          ) : (
                            ""
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {contactus.grow_content ? (
                    <div className="grow-with-us">
                      <h3>{contactus.grow_title}</h3>
                      <p>{contactus.grow_content}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {contactus.join_us_url ? (
                    <div className="conatct-form-detail-btn">
                      <Link to={contactus.join_us_url} className="common-round-btn">
                        {contactus.join_us_text}
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="contact-page-form-in right">
                <div className="contact-page-form-content">
                  <div className="contact-page-form-inner">
                    <div className="form-top-heading">
                      <div className="form-heading">
                        <h4>Ready to Serve you first!</h4>
                      </div>
                    </div>
                    <div className="contact-page-form-con">
                      <form id="contactformreset" data-toggle="validator">
                        <input
                          type="hidden"
                          name="subject"
                          id="subject"
                          value="Contact Us"
                        />
                        <input
                          type="hidden"
                          name="upload_file_path"
                          id="upload_file_path"
                        />
                        <input
                          type="hidden"
                          name="upload_file_name"
                          id="upload_file_name"
                        />
                        <input
                          name="allservices"
                          id="allservices"
                          type="hidden"
                        />
                        <div className="enquiry-form-name-email clearfix">
                          <div className="form-group enquiry-form-name form-field left">
                            <input
                              type="text"
                              name="name"
                              placeholder="Full Name *"
                              id="name"
                              onChange={handleStateChange}
                              autoComplete="off"
                              value={mailerState.name}
                              pattern="\b\w+\b(?:.*?\b\w+\b){1}"
                              data-error="The field is required."
                              required
                            />
                            <div className="ksh-form-error help-block with-errors"></div>
                          </div>
                          <div className="form-group enquiry-form-email form-field right">
                            <input
                              type="text"
                              name="email"
                              id="email"
                              onChange={handleStateChange}
                              placeholder="Work Email *"
                              autoComplete="off"
                              value={mailerState.email}
                              pattern="\S+@\S+\.\S+"
                              data-error="The field is required."
                              required
                            />
                            <div className="ksh-form-error help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="form-group enquiry-form-contact form-field">
                          <input
                            type="text"
                            name="contact"
                            id="fig-phone"
                            onChange={handleStateChange}
                            placeholder="Contact No.*"
                            autoComplete="off"
                            value={mailerState.contact}
                            pattern="^\d{3}-\d{3}-\d{4}$"
                            maxlength="12"
                            data-error="The field is required."
                            required
                          />
                          <div className="ksh-form-error help-block with-errors"></div>
                        </div>
                        <div className="enquiry-form-services-looking-for">
                          <h4>Which services are you looking for?*</h4>
                          <div className="form-checkbox">
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="Web Design & Development"
                                id="web-development"
                              />
                              <label htmlFor="web-development">
                                Web Design &amp; Development
                              </label>
                            </div>
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="Graphic Design"
                                id="creative-design"
                              />
                              <label htmlFor="creative-design">
                                Graphic Design
                              </label>
                            </div>
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="Mobile App Development"
                                id="mobile-app-developemnt"
                              />
                              <label htmlFor="mobile-app-developemnt">
                                Mobile App Development
                              </label>
                            </div>
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="eCommerce and CMS"
                                id="eCommerce-design"
                              />
                              <label htmlFor="eCommerce-design">
                                eCommerce and CMS
                              </label>
                            </div>
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="Animation"
                                id="animation"
                              />
                              <label htmlFor="animation">Animation</label>
                            </div>
                            <div className="form-checkbox-input">
                              <input
                                type="checkbox"
                                name="services[]"
                                value="Digital Marketing & Content Solutions"
                                id="seo-digital-marketing"
                              />
                              <label htmlFor="seo-digital-marketing">
                                Digital Marketing &amp; Content Solutions
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="enquiry-form-requirements">
                          <h4>
                            Share your requirements*
                            <span>(minimum 250 characters)</span>
                          </h4>
                          <p>
                            (You can add links to your shareable material if
                            any)
                          </p>
                          <div className="form-group form-textarea form-field">
                            <textarea
                              rows="2"
                              name="message"
                              onChange={handleStateChange}
                              id="message"
                              value={mailerState.message}
                              data-error="The field is required."
                              required
                            ></textarea>
                            <div className="ksh-form-error help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="enquiry-form-upload-field form-field">
                          <h4>Upload your document</h4>
                          <div className="form-upload-field">
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={saveFile}
                            />
                            <span>Drag &amp; Drop your file</span>
                          </div>
                        </div>
                        <div className="form-submit-btn">
                          <input
                            type="submit"
                            name="submit"
                            value="Submit enquiry"
                            onClick={submitEmail}
                          />
                          {/* <button>Send Message</button> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {data.address_1 || data.address ? (
        <section className="map-image-wr">
          <div className="center-wrapper">
            <div className="map-wr">
              <figure className="map-image-blk">
                <img
                  src="../assets/images/conact-page-map.svg"
                  alt="conact-page-map"
                />
              </figure>
              <div className="detailTab">
                <ul className="map-dots">
                  {data.address_1 ? (
                    <li className="map-tabing" data-tab="loc-4">
                      <div className="location-bubble">
                        <figure className="location-icon">
                          <img
                            src="../assets/images/Canada_flag.jpg"
                            alt="India Location"
                          />
                        </figure>
                        <div className="location-address">
                          <Link
                            to="https://goo.gl/maps/K98yMWb1mvtmWVax6"
                            target="_blank"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data.address_1,
                              }}
                            ></p>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {data.address ? (
                    <li className="map-tabing" data-tab="loc-2">
                      <div className="location-bubble">
                        <figure className="location-icon">
                          <img
                            src="../assets/images/India_location.png"
                            alt="India Location"
                          />
                        </figure>
                        <div className="location-address">
                          <Link
                            to="https://maps.app.goo.gl/z1rAzUkoNbbKv99V9"
                            target="_blank"
                          >
                            <p>{data.address}</p>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Contact;
