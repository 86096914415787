import React from 'react'
import Meta from './Meta/Meta'
import Common_Banner from "./common/CommonBanner";
import YoutubeCarouselLatestHeader from './shopifyapps/youtube-latest-carousel/YoutubeCarouselLatestHeader';
import YoutubeCarouselLatestFooter from './shopifyapps/youtube-latest-carousel/YoutubeCarouselLatestFooter';

function ShopifyCarouselPrivacyPolicy() {
	return (
		<>
			<YoutubeCarouselLatestHeader />
			<Meta
				title={"Youtube Latest Video Carousel Privacy Policy"}
				desc={"Privacy Policy for Youtube Latest Video Carousel"}
				keywords={""}
			/>
			<Common_Banner title="Privacy Policy" />
			<section className="con-about-wr con-space-wr">
				<div className="center-wrapper">
					<div className="con-img-title-blk">
						<div
							className="con-about-img fadeIn"
							data-wow-duration="2s"
							data-wow-dealy="2s"
						>
							<img
								src={"../assets/imgupload/shopify-privacy-banner.svg"}
								alt="Conative It"
								width={500}
							/>
						</div>
						<div className="con-overlay-title common-heading">
							<h2>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									Youtube Latest Video Carousel
								</span>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									Privacy Policy
								</span>
							</h2>
						</div>
					</div>
					<div
						className="con-page-content"
					>
						<div style={{marginTop:"20px"}}>
							<h3>Information We Collect through Shopify’s APIs</h3>
							<p>
								We collect your store's name and domain through Shopify's APIs to authenticate your store and provide our services.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>Information We Collect Directly from the Merchant</h3>
							<p>
								We collect the YouTube API key, the selected YouTube channel ID, and any other preferences you set within our app. This information is used to configure and display the YouTube carousel on your online store. Additionally, we store the API key in an encrypted format in our database to ensure its security.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>Information We Collect Directly from Merchants’ Customers</h3>
							<p>
								We do not collect any information directly from your customers. We do not use cookies or other tracking technologies on your customers' devices. All customer data is handled by Shopify, and our app does not have access to it.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>How We Use the Information We Collect</h3>
							<p>
								The information we collect is used solely to provide and improve our app’s services, such as displaying the YouTube carousel on your store. We do not use this information for any other purposes without your explicit consent.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>Data Retention</h3>
							<p>
								We retain the data we collect for as long as your account is active or as needed to provide you with our services. We may also retain and use your information to comply with our legal obligations, resolve disputes, and enforce our agreements.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>International Data Transfers</h3>
							<p>
								We are not established in Europe, but we may process your information outside of Europe. We ensure that appropriate safeguards are in place to protect your data when it is transferred internationally.
							</p>
						</div>

						<div style={{marginTop:"20px"}}>
							<h3>Contact Us</h3>
							<p>
								If you have any questions or concerns about our privacy practices, please contact us at:
							</p>
							<address>
								<p>Conative IT Solutions</p>
								<p>Office No. 217-218, Onam Plaza Beside Industry House</p>
								<p>Indore, M.P, 452018</p>
								<p>Email: info@conative.in</p>
								<p>Phone: +91-8269788173</p>
							</address>
						</div>
					</div>
				</div>
			</section>
			<YoutubeCarouselLatestFooter />
		</>
	)
}

export default ShopifyCarouselPrivacyPolicy