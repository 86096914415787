import React from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import DigitalSolutions from "../../assets/images/Services/Digital_Marketing_Content_Solutions.jpg";
import SEO from "../../assets/images/Services/Search_Engine_Optimization.png";
import SocialMedia from "../../assets/images/Services/Social_Media_Marketing.png";
import contentMarketing from "../../assets/images/Services/Content_Marketing.png";
import AccountSetup from "../../assets/images/Services/Account_setup.svg";
import ProfileCreation from "../../assets/images/Services/Profile_Creation.svg";
import ProfileOptimization from "../../assets/images/Services/Profile_Optimization.svg";
import PostScheduling from "../../assets/images/Services/Best_practices_of_post_scheduling.svg";
import Posting from "../../assets/images/Services/Posting.svg";
import PaidAids from "../../assets/images/Services/Paid_Ads.svg";
import HastagsSuggestions from "../../assets/images/Services/Hashtag_Suggestions.svg";
import TrendingUpdates from "../../assets/images/Services/Trending_updates.svg";
import ConetntForPost from "../../assets/images/Services/Content_for_post.svg";
import ServicesPromotion from "../../assets/images/Services/Promotion.svg";
import Managment from "../../assets/images/Services/Management.svg";
import MonitoringReporting from "../../assets/images/Services/Monitoring_&_Reporting.svg";
import socialMediaMarketing from "../../assets/images/Services/box-contains-social-media-icons.png";
import contentManagment from "../../assets/images/Services/young-millennial-student-searches.jpg";
import { Link } from "react-router-dom";

function digitalmarketingandcontent() {
  return (
    <>
      <Banner title="Digital Marketing & Content Solutions" />
      <section className="con-space-wr con-digital-page-wr">
        <div className="center-wrapper">
          <div className="services-page-content-blk services-digital-wr">
            <div className="con-img-title-blk">
              <div
                className="con-about-img wow fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img
                  src={DigitalSolutions}
                  alt="Digital Marketing & Content Solutions"
                />
              </div>
              <div className="image-content-wr">
                <div className="icon-content-blk clearfix">
                  <div className="icon-blk left">
                    <img src={SEO} alt="Search Engine Optimization" />
                  </div>
                  <div className="content-blk right">
                    <h5>Search Engine Optimization</h5>
                  </div>
                </div>
                <div className="icon-content-blk clearfix">
                  <div className="icon-blk left">
                    <img src={SocialMedia} alt="Social Media Marketing" />
                  </div>
                  <div className="content-blk right">
                    <h5>Social Media Marketing</h5>
                  </div>
                </div>
                <div className="icon-content-blk clearfix">
                  <div className="icon-blk left">
                    <img src={contentMarketing} alt="Content Marketing" />
                  </div>
                  <div className="content-blk right">
                    <h5>Content Marketing</h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="con-page-content wow fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <p>
                With the help of our Digital Solutions, our clients will get the{" "}
                <strong>added benefits</strong> of Web Development and Web
                Designing solutions on Digital platforms.
              </p>

              <p>
                We assure you that our services will come up with the expected
                results that develop the business growth. <br />
                <strong>
                  Conative IT Solutions is the leading SEO service provider
                </strong>{" "}
                company in Indore, rendering world-className assistance for both
                small and large businesses.
              </p>

              <p>
                If you want to get started with a business or expand your online
                business, we are ready to serve you with high quality digital
                solutions and services. Our Conative IT Solution team has
                effective problem-solving digital solutions. We collaborate with
                you on digital marketing{" "}
                <strong>services to increase visibility</strong>, generate
                business and results and provide a seamless user experience.
              </p>

              <p>
                A successful Optimized Digital marketing plan requires striking
                the appropriate mix between content, keyword analysis, website
                organization, back-end structure and off-site online marketing.
              </p>
            </div>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-digital-services-wr">
        <div className="center-wrapper">
          <div className="con-anim-left-right-wr clearfix">
            <div className="con-anim-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>SEO</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Search Engine Optimization (SEO) is a set of tactics, methods
                  and techniques used to increase the amount of getting leads to
                  your website and also obtain a high-ranking position on SERP.
                </p>
                <p>
                  <strong>
                    Conative IT Solutions is providing the Best SEO services
                  </strong>{" "}
                  all over the world and follows the best practices which
                  include optimizing your websites according to search engine
                  listings. The availability of a website to a web index is
                  supported by SEO services that boost the chances to come on
                  the Search Engine Result Page.
                </p>
              </div>
            </div>
            <div className="con-anim-right-wr right">
              <div className="con-motion-list-wr">
                <h5>Our effective SEO services include</h5>
                <div className="con-tab-list-wr">
                  <ul>
                    <li>Keyword Research and Analysis</li>
                    <li>Local Business SEO</li>
                    <li>Onsite and Offsite Optimizations</li>
                    <li>Tag Implementation</li>
                    <li>Local Business SEO</li>
                    <li>Google Ads(PPC)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="con-left-right-wr clearfix">
            <div className="con-right-wr right">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Social Media Marketing</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Our team has created a toolkit of social media marketing
                  services to help you connect with your target audience and
                  enhance brand visibility by fusing best practices with
                  contemporary strategies.
                </p>
                <p>
                  We offer services that will boost visibility on social media
                  platforms to get the targeted audience, intent posting
                  management and creative content. With the help of our
                  experience and these successful social media marketing
                  initiatives, we are ready to serve our customers and take
                  their business to its peak.
                </p>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={socialMediaMarketing} alt="Social Media Marketing" />
            </div>
          </div>
          <div className="social-media-marketing-content">
		  <ul>
              <li>
                <span>
                  <img src={AccountSetup} alt="Account setup" />
                </span>
                <span className="list-title" style={{paddingTop:"10px"}}>Account Setup</span>
              </li>
              <li>
                <span>
                  <img src={ProfileCreation} alt="Profile Creation" />
                </span>
                <span className="list-title">Profile Creation</span>
              </li>
              <li>
                <span>
                  <img src={ProfileOptimization} alt="Profile Optimization" />
                </span>
                <span className="list-title">Profile Optimization</span>
              </li>
              <li>
                <span>
                  <img
                    src={PostScheduling}
                    alt="Best practices of post scheduling"
                  />
                </span>
                <span className="list-title">
                  Best Practices of Post Scheduling
                </span>
              </li>
              <li>
                <span>
                  <img src={Posting} alt="Posting" />
                </span>
                <span className="list-title">Posting</span>
              </li>
              <li>
                <span>
                  <img src={PaidAids} alt="Paid Ads" />
                </span>
                <span className="list-title">Paid Ads</span>
              </li>
              <li>
                <span>
                  <img src={HastagsSuggestions} alt="Hashtag Suggestions" />
                </span>
                <span className="list-title">Hashtag Suggestions</span>
              </li>
              <li>
                <span>
                  <img src={TrendingUpdates} alt="Trending updates" />
                </span>
                <span className="list-title">Trending Updates</span>
              </li>
              <li>
                <span>
                  <img src={ConetntForPost} alt="Content for post" />
                </span>
                <span className="list-title">Content for Post</span>
              </li>
              <li>
                <span>
                  <img src={ServicesPromotion} alt="Promotion" />
                </span>
                <span className="list-title">Promotion</span>
              </li>
              <li>
                <span>
                  <img src={Managment} alt="Management" />
                </span>
                <span className="list-title">Management</span>
              </li>
              <li>
                <span>
                  <img src={MonitoringReporting} alt="Monitoring & Reporting" />
                </span>
                <span className="list-title">Monitoring & Reporting </span>
              </li>
            </ul>
            <div className="con-page-content">
              <p>
                For our clients, we manage a variety of social media channels,
                including LinkedIn, Google My Business, Instagram, Twitter,
                Facebook and YouTube.
              </p>
              <p>
                We will analyze and determine which platforms meet your needs
                and develop a strategy for leveraging those platforms.{" "}
                <strong>We provide ample services</strong> in addition to social
                media management to support these efforts.
              </p>
              <p>
                So what are waiting for, if you are looking for such services
                who can help out for finding targeted reach and new customers
                using a purposeful social media campaign,{" "}
                <strong>contact Conative IT Solutions today.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="con-content-manage-wr" scroll-color="white">
        <div
          className="white-ring-circle wow pulse animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={contentManagment} alt="Content Management" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Content Management</h3>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              <strong>Content of high quality appeals</strong> to your site
              visitors easily. It is recognised as a primary principle is to
              split the website design method from the content.
            </p>
            <p>
              We mainly focused on the Easy meta tag for creating the content
              for the website so that your content will rank high. Provides
              SEO-optimized image upload facility and flash content management
              system.
            </p>
            <p>
              At Conative, our core objective is to provide{" "}
              <strong>immense content management services</strong> that are
              outstanding and profitable for your online business.
            </p>
            <p>
              We offer an extensive range of Content Management Services that
              include high-quality content that meets the requirements of our
              clients.
            </p>
            <p>
              The goal behind our strategies is to provide the content that the
              user mind searches for on the web. By doing this, they will relate
              to your page and stay on it longer,{" "}
              <strong>which will reduce bounce rates.</strong>
            </p>
          </div>
        </div>
      </section>

      <section className="con-work-service-wr con-digi-work-service-wr">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/ecommerce-development-services"
                    className="circle-fill-btn"
                  >
                    <span>eCommerce & CMS</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Mobile App <br /> Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      WebDesign & <br />
                      Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/graphic-design-services"
                    className="circle-fill-btn"
                  >
                    <span>Designing</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default digitalmarketingandcontent;
