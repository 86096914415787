import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../component/common/Header'
import Footer_second from '../component/common/Footer_second'

function MainLayout({pathname}) {
	return (
		<>
			{/* <ConfettiEB/> */}
			<Header />
			<Outlet />
			{/* <AnivStrip/> */}
			<Footer_second pathname={pathname} />
		</>
	)
}

export default MainLayout