import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
const Analytics_Counter = () => {
  const [item, setItem] = useState([]);
  useEffect(() => {
    fetch(backendurl + "cpi-show")
      .then((res) => res.json())
      .then((item) => setItem(item));
  }, []);
  const [isVisible, setVisibility] = useState(false);

  const hardCodednumArr = [
    {
      num:"15",
      name:"Years of Expertise",
      num_suffix:"+"
    },
    {
      num:"24",
      name:"SERVICE",
      num_suffix:"*7"
    },
    {
      num:"4",
      name:"FINISHED PROJECTS",
      num_suffix:"K"
    },
    {
      num:"40",
      name:"Skilled Experts",
      num_suffix:"+"
    }
  ]

  const onChange = (visiblity) => {
    setVisibility(visiblity);
  };
  useEffect(() => {
    if (isVisible) {
      setVisibility(true);
    }
  }, [isVisible]);

  return (
    <>
     
        <div className="counter-area clearfix">
        {item?.length > 0 ? (
              item.map((post, index) => (
                <div
                  className={"counter-blk counter left counter-blk-" + index}
                  key={post._id}
                >
                  <div className="counter-value">
                    <CountUp end={post.num} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor
                          partialVisibility
                          offset={{ top: 200 }}
                          onChange={start}
                          delayedCall
                        >
                          <h3
                            data-count={post.num}
                            className={isVisible ? "visible" : "novisible"}
                            ref={countUpRef}
                          >
                            {post.num}
                          </h3>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span>{post.num_suffix}</span>
                  </div>
                  <div className="counter-caption">
                    <h5>{post.name}</h5>
                  </div>
                </div>
              ))
            ) : (
              hardCodednumArr.map((post, index) => (
                <div
                  className={"counter-blk counter left counter-blk-" + index}
                  key={index}
                >
                  <div className="counter-value">
                    <CountUp end={post.num} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor
                          partialVisibility
                          offset={{ top: 200 }}
                          onChange={start}
                          delayedCall
                        >
                          <h3
                            data-count={post.num}
                            className={isVisible ? "visible" : "novisible"}
                            ref={countUpRef}
                          >
                            {post.num}
                          </h3>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span>{post.num_suffix}</span>
                  </div>
                  <div className="counter-caption">
                    <h5>{post.name}</h5>
                  </div>
                </div>
              ))
            )}

        </div>
   
    </>
  );
};

export default Analytics_Counter;
