import React, { useState } from "react";
import backendurl from "../constants";
import Common_Banner from "../common/CommonBanner";
import axios from "axios";
import swal from "sweetalert";

const QueriesForm = () => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    var spinner = document.getElementById("loading_spinner_wr");

    var filename = "";
    var filepath = "";

    var valid = true;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const file_res = await axios.post(backendurl + "filesUpload", formData);
      document.getElementById("upload_file_path").value =
        file_res["data"]["filepath"];
      document.getElementById("upload_file_name").value =
        file_res["data"]["file"];
      filename = file_res["data"]["file"];
      filepath = file_res["data"]["filepath"];
    } catch (ex) {
      
    }

    const { name, email, contact, message } = mailerState;

    var checkboxes = document.getElementsByName("services[]");
    var services = "";
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        services += "," + checkboxes[i].value;
      }
    }
    if (services) services = services.substring(1);
    document.getElementById("allservices").value = services;

    var subject = document.getElementById("subject").value;

    

    var subject = document.getElementById("subject").value;

    if (
      name == "" ||
      email == "" ||
      contact == "" ||
      message == "" ||
      services == "" ||
      subject == ""
    ) {
      swal("", "Please fill all the required* fields!", "info");
    } else {
      spinner.style.display = "block";
      const response = await fetch(backendurl + "sendmail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          contact,
          services,
          message,
          filename,
          filepath,
          subject,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setMailerState({
            name: "",
            email: "",
            contact: "",
            message: "",
          });

          document.getElementById("query_form").reset();
          spinner.style.display = "none";
          var res_message = data["message"];
          if (data["code"] == "success") {
            swal({
              text: `${res_message}`,
              icon: "success",
            }).then((value) => {
              window.location.reload();
            });
          } else {
            swal({
              text: `${res_message}`,
              icon: "info",
            }).then((value) => {
              window.location.reload();
            });
          }
        });
    }
  };
  return (
    <>
      <section className="con-queries-wr">
        <div className="center-wrapper">
          <div className="con-queries-content">
            <div className="con-head-blk">
              <h2>Drop Your Queries</h2>
            </div>
            <div className="con-queries-form">
              <form id="query_form" data-toggle="validator">
                <input
                  type="hidden"
                  name="subject"
                  id="subject"
                  value="Drop your queries"
                />
                <input
                  type="hidden"
                  name="upload_file_path"
                  id="upload_file_path"
                />
                <input
                  type="hidden"
                  name="upload_file_name"
                  id="upload_file_name"
                />
                <input name="allservices" id="allservices" type="hidden" />
                <div className="form-group enquiry-form-name form-field">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name *"
                    autoComplete="off"
                    onChange={handleStateChange}
                    value={mailerState.name}
                    pattern="\b\w+\b(?:.*?\b\w+\b){1}"
                    required
                    data-error="The field is required."
                  />
                  <div className="ksh-form-error help-block with-errors"></div>
                </div>
                <div className="form-group enquiry-form-email form-field">
                  <input
                    type="text"
                    name="email"
                    placeholder="Work Email *"
                    autoComplete="off"
                    pattern="\S+@\S+\.\S+"
                    onChange={handleStateChange}
                    value={mailerState.email}
                    required
                    data-error="The field is required."
                  />
                  <div className="ksh-form-error help-block with-errors"></div>
                </div>
                <div className="form-group enquiry-form-contact form-field">
                  <input
                    type="text"
                    name="contact"
                    placeholder="Contact Number *"
                    autoComplete="off"
                    id="fig-phone"
                    pattern="^\d{3}-\d{3}-\d{4}$"
                    maxlength="12"
                    onChange={handleStateChange}
                    value={mailerState.contact}
                    required
                    data-error="The field is required."
                  />
                  <div className="ksh-form-error help-block with-errors"></div>
                </div>
                {/* <div className="enquiry-form-select form-field">
                  <select name="service_type" id="service_type">
                    <option value="">Types of Services *</option>
                    <option value="Graphic Design">Graphic Design</option>
                    <option value="Web Design">Web Design</option>
                    <option value="Web Development">Web Development</option>
                  </select>
                </div> */}
                <div className="form-group services-checkbox enquiry-form-services-looking-for">
                  <h4>Which services are you looking for?*</h4>
                  <div className="form-checkbox">
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-web-development"
                        value="Web Design &amp; Development"
                      />
                      <label for="services-web-development">
                        Web Design &amp; Development
                      </label>
                    </div>
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-creative-design"
                        value="Graphic Design"
                      />
                      <label for="services-creative-design">
                        Graphic Design
                      </label>
                    </div>
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-mobile-app-developemnt"
                        value="Mobile App Development"
                      />
                      <label for="services-mobile-app-developemnt">
                        Mobile App Development
                      </label>
                    </div>
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-eCommerce"
                        value="eCommerce and CMS"
                      />
                      <label for="services-eCommerce">eCommerce and CMS</label>
                    </div>
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-animation"
                        value="Animation"
                      />
                      <label for="services-animation">Animation</label>
                    </div>
                    <div className="form-checkbox-input">
                      <input
                        type="checkbox"
                        name="services[]"
                        id="services-seo-digital-marketing"
                        value="Digital Marketing &amp; Content Solutions"
                      />
                      <label for="services-seo-digital-marketing">
                        Digital Marketing &amp; Content Solutions
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group enquiry-form-requirements">
                  <h4>
                    Share your requirements*
                    <span>(minimum 250 characters)</span>
                  </h4>
                  <p>(You can add links to your shareable material if any)</p>
                  <div className="form-textarea form-field">
                    <textarea
                      rows="2"
                      name="message"
                      onChange={handleStateChange}
                      value={mailerState.message}
                      data-error="The field is required."
                      required
                    ></textarea>
                    <div className="ksh-form-error help-block with-errors"></div>
                  </div>
                </div>
                <div className="enquiry-upload">
                  <div className="form-textarea form-field">
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={saveFile}
                      placeholder="Upload your file"
                    />
                    <label>Upload your file</label>
                  </div>
                </div>
                <div className="form-submit-btn">
                  <input
                    type="submit"
                    onClick={submitEmail}
                    name="submit"
                    value="Submit enquiry"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QueriesForm;
