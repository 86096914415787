import React from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import EcommerceBanner from "../../assets/images/Services/3d-rendering-shopping-cart-laptop.jpg";
import wocommerce from "../../assets/images/Services/add-cart-buy-now-online-commerce-graphic.jpg";
import Magneto from "../../assets/images/Services/laptop-blank-screen-hoppingt.jpg";
import OpenCart from "../../assets/images/Services/OpenCart.png";
import BigCommerce from "../../assets/images/Services/shopping-online-shopping.jpg";
import Shopify from "../../assets/images/Services/Shopify.png";
import { Link } from "react-router-dom";

const ecommerceandcms = () => {
  return (
    <>
      <Banner title="E-Commerce and CMS" />
      <section className="con-space-wr con-eccom-wr">
        <div className="center-wrapper">
          <div className="services-page-content-blk">
            <div className="con-img-title-blk">
              <div
                className="con-about-img wow fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={EcommerceBanner} alt="eCommerce" />
              </div>
              <div className="con-overlay-title common-heading con-head-blk">
                <h3>e-Commerce and CMS</h3>
              </div>
            </div>
            <div className="con-blockquote-blk">
              <blockquote>
                <p>
                  What makes E-commerce services so popular among all
                  businesses?
                </p>
              </blockquote>
            </div>
            <div
              className="con-page-content wow fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <p>
                <strong>
                  E-commerce services directly increase the chances of cost
                  reduction
                </strong>
                &nbsp;and other benefits that keep sellers interested in online
                marketing with the help of digital platforms. Many sellers must
                pay a lot of money to keep their physical stores open. If you
                own a physical store, you will be limited in the geographical
                area.
              </p>
              <p>
                Additionally, one of the major benefits of eCommerce that
                businesses who sell online can take advantage of is that their
                store hours are now 24/7 rather than the traditional given time
                hours that ordinary stores are open. Retailers can
                <strong>
                  &nbsp;increase their order volume in this way to increase
                  sales.
                </strong>
                &nbsp;Customers can buy goods and services whenever they want,
                whether it is late at night or early in the morning.
              </p>

              <p>
                Having an online business will allow you to accommodate your
                consumers' busy schedules and provide them with what they need
                at their choice.
              </p>

              <p>
                <strong>We at Conative</strong>&nbsp;serving the multiple
                services for enhancing the e-commerce usage.
              </p>
            </div>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-ecom-services-wr">
        <div className="center-wrapper">
          <div className="con-ecom-services-inner-wr">
            <div className="con-head-blk">
              <h3>WordPress</h3>
            </div>
            <div className="con-page-content">
              <p>
                We are building a customised e-Commerce website. In order to
                create an eCommerce website that is completely functioning and
                has a strong online presence, we also integrate the required
                plugins and functionalities.
                <br />
                To Leverage the best WordPress result:
                <br />
                At Conative, we offer web development services, with skillful
                craft digital solutions from conception through deployment, data
                migration, API integrations and plugin creation. For clients
                who want positive business outcomes, we create WordPress
                websites that are high-performing, responsive and easy to
                operate.
              </p>
              <p>
                Are you looking for the solutions related to wordpress, we are
                here to help you regarding:
              </p>
            </div>
            <div className="con-ecom-list-wr con-tab-list-wr">
              <ul>
                <li>WordPress plugins</li>
                <li>Web Development services</li>
                <li>Plugin Development</li>
                <li>Customised themes</li>
                <li>Installation & Configuration</li>
                <li>e-Commerce Solutions</li>
                <li>Blog Development services</li>
                <li>Responsive Design</li>
                <li>CMS Development</li>
              </ul>
            </div>
            <div className="con-page-content">
              <p>
                <strong>
                  We have done multiple WordPress projects and got positive
                  revert from our clients, for more information go through our
                  client reviews and testimonials.
                </strong>
              </p>
            </div>
          </div>
          <div className="con-left-right-wr con-woocom-wr clearfix">
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>Woo-Commerce</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Top-notch WooCommerce Development Company <br /> Conative IT
                  Solutions, one of the&nbsp;
                  <strong>top WooCommerce development</strong>&nbsp;company in
                  India, offers cutting-edge eCommerce business solutions,
                  WooCommerce development and plug-ins to enhance your online
                  store.
                </p>
                <p>
                  With our&nbsp;<strong> years of working experience</strong>
                  &nbsp;we come up with the results that WooCommerce is the most
                  widely used e-Commerce platform for online business
                  development. Providing you with a superb selection of features
                  and flexibility for your online shopping cart. Are you looking
                  for WooCommerce development services? Make your eCommerce
                  website according to user perspective with the help of
                  engaging web development platforms like Woo-Commerce. Above
                  all these, we have done a number of WordPress projects and
                  received positive feedback from our clients.
                </p>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={wocommerce} alt="Woo Commerce" />
            </div>
          </div>
          <div className="con-left-right-wr con-shopify-wr clearfix">
            <div className="con-left-wr left">
              <div className="con-head-blk">
                <h3>Shopify</h3>
              </div>
              <div className="con-page-content">
                <p>Want to build your digital store with Shopify?</p>
                <p>
                  Conative IT Solutions is a reliable Shopify web development
                  business based in India, known for its highly regarded for our
                  diligent team of Shopify developers, comprehensive Shopify
                  store development services and tested development
                  methodology.
                </p>
                <p>
                  We offer comprehensive Shopify installation, configuration
                  and integration solutions to help your business meet its
                  e-Commerce objectives within a given time limit. <br /> We are
                  using the&nbsp;
                  <strong>
                    top trending tools to build a stunning shopify website.
                  </strong>
                  &nbsp;Since we guarantee a feature-rich, effective and&nbsp;
                  <strong>high-quality e-commerce store.</strong>
                </p>
              </div>
              <div className="con-tab-list-wr">
                <ul>
                  <li>
                    <strong>Shopify Ecommerce Expansion</strong>
                  </li>
                  <li>
                    <strong>Custom Shopify Development</strong>
                  </li>
                  <li>
                    <strong>Shopify Integration and Migration</strong>
                  </li>
                  <li>
                    <strong>Shopify Theme Development</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="con-right-wr right">
              <img src={Shopify} alt="Shopify" />
            </div>
          </div>
          <div className="con-react-wr con-full-wr con-mangneto-wr">
            <div className="con-img-title-blk">
              <div
                className="con-about-img fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={Magneto} alt="Magneto" />
              </div>
              <div className="con-overlay-title common-heading con-head-blk">
                <h3>Magento </h3>
              </div>
            </div>
            <div className="con-page-content">
              <p>
                Why choose&nbsp;<strong>Conative Magento experts?</strong>
                &nbsp;
                <br /> Our e-commerce solutions provide a reliable,
                customizable and optimised website for your business. Also,the
                developers have experience building extended functionality to
                meet all of your website needs. <br /> Moreover, Conative IT
                Solutions, serve highly productive marketing, analytical and
                SEO strategies that increase traffic, engage <br /> customers in
                quickly finding your services online and increase conversions.
                <br /> Want to be on the top 10 website list who are offering
                Magento services? <br />
                <strong>
                  <Link to="/contact-us">Contact us today!</Link>
                </strong>
                <br />
                Our team is ready to assist you with the highly defined services
                related to the Magento ecommerce website that will give you zero
                bounce rate and convert the visitor into targeted leads.
              </p>
            </div>
          </div>
          <div className="con-left-right-wr con-woocom-wr con-open-cart clearfix">
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>OpenCart</h3>
              </div>
              <div className="con-page-content">
                <p>
                  The Conative team is ready to serve you the best out of the
                  best OpenCart development services that will&nbsp;
                  <strong>solve ample issues related to customization,</strong>
                  &nbsp;shipping and other e-commerce issues.
                </p>
                <p>
                  We have a team who is client centric while offering the
                  opencart service facilities for years and we are glad to share
                  that our customers make us the best web designing and web
                  development services provider.
                  <br />
                  Also we are offering multiple services like:
                </p>
              </div>
              <div className="con-tab-list-wr">
                <ul>
                  <li>Customization service</li>
                  <li>payment and shipping integration services</li>
                  <li>marketplaces development services</li>
                  <li>Drop Shipping services and many more</li>
                </ul>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={OpenCart} alt="OpenCart" />
            </div>
          </div>
          <div className="con-full-wr con-big-com-wr">
            <div className="con-big-com-blk con-head-blk con-page-content">
              <h3>Big Commerce</h3>
              <p>
                We at Conative have effective enterprise eCommerce stores that
                are created, improved and managed by our expert team. With a
                range of industry-leading tools, we fuel digital commerce for
                cutting-edge brands, producers and distributors around the
                world, enabling companies of all types to reach their full
                potential.
              </p>
              <p>
                We will help you drive more traffic to get targeted leads.
                <br /> By using intuitive shopping and marketing tools, it helps
                boost sales. <br /> Get support at every step of the way to
                solve issues related to big commerce.
                <br /> We have a fantastic team that leverages resources and
                works to meet clients' needs in a timely manner. The majority of
                our
                <br /> business comes from referrals and word of mouth and we
                have a&nbsp;<strong>friendly working environment. </strong>
                &nbsp;
                <br />
                Making our clients and partners happy and satisfied is our
                number one priority.
              </p>
            </div>
            <div className="con-full-img-wr">
              <img src={BigCommerce} alt="Big Commerce" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-work-service-wr">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      WebDesign & <br />
                      Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Digital Marketing & <br />
                      Content Solutions
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Mobile App <br /> Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/graphic-design-services"
                    className="circle-fill-btn"
                  >
                    <span>Designing</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ecommerceandcms;
