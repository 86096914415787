import React from "react";
import Contact from "./Contact";
import Contact_form from "./Contact_form";
function ContactLayout() {
  return (
    <div className="wrapper">
      <Contact />
    </div>
  );
}

export default ContactLayout;
