import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./Routes/AppRouter";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import FooterSecond from "./component/common/Footer_second";
import Loadingpage from "./component/common/Loadingpage";
import Header from "./component/common/Header";
import MyJQuery from "./component/MyJQuery";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./services/ScrollToTop";
import App from "./App";
 
const pathname = window.location.pathname;
ReactDOM.render(
	<React.StrictMode>
		<div id="rootdiv">
		<App />			
		</div>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();