import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Ecommerce() {
  return (
    <>
      <div className="page-banner-wr con-life-banner-wr">
        <div className="center-wrapper">
          <div className="banner-content">
            <h1 className="page-banner-heading">eCommerce</h1>
          </div>
        </div>
      </div>
      <section className="con-space-wr con-digital-page-wr">
        <div className="center-wrapper">
          <div className="services-page-content-blk">
            <div className="con-img-title-blk">
              <div
                className="con-about-img wow fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img
                  src="../assets/images/Services/Ecommoerce_header_Image.jpg"
                  alt="eCommerce"
                />
              </div>
            </div>
            <div
              className="con-page-content wow fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <p>
                Search Engine Optimization is a system of approaches, methods
                and ways applied to boo. the quantity of targeted traffic to a
                web page by acquiring a excessive rating position in the search
                outcomes site of a web index - as well as Google, Yahoo, MSN,
                Bing and other search engines It is an authentic procedure of
                elevating exposure of a website on different natural and organic
                Search Engine Result Page.
              </p>
              <p>
                The SEG services provided by our company gives precise worth to
                your online business and assists you to increase your website
                ranking. It is an ideal way to entice site visitors for your
                websites and maximize targeted viewers for webpages. Our
                solutions rectify your website's search engine listings. 5E0
                services support to make certain that a Internet site is
                obtainable to a web index and enhances the possibilities that
                the web site will be discovered and graded extremely by the
                search engine. A Website's ranking mainly depends on the
                keywords, which you are opt and put into practice for your web
                page.
              </p>
              <p>
                Conative IT Solutions is a Best DEO Company in Indore, which
                imparts the world class services for both types of business
                categories such as small and large enterprises. If you wants to
                get started your business enterprise or to boosting up your
                online business.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="con-ecomm-web-wr">
        <div
          className="white-ring-circle wow pulse"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="ring-wr">
          <div className="ring-1 ring"></div>
          <div className="ring-2 ring"></div>
        </div>
        <div className="center-wrapper">
          <div className="con-ecomm-web-content clearfix">
            <div className="con-services-left left">
              <div
                className="con-head-blk wow fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>eCommerce Web Solutions We Offer</h3>
              </div>
              <div
                className="con-card con-inquiry-card wow pulse"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/For_Inquiry_Icon.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    <span>For Enquiry call now</span>
                    <Link to="tel:+91-8269788173">+91-8269788173</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="con-services-right right">
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Woo_Commerce.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">wooCommerce</h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Big_Commerce.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    Big-Commerce Customization
                  </h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Os_Commerce.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    Open Cart Developement
                  </h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Os_Commerce.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    OS Commerce Customization
                  </h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Shopify.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">Shopify</h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Zencart_Customization.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    Zencart Customization
                  </h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Cs_Cart.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    CS Commerce Customization
                  </h4>
                </div>
              </div>
              <div
                className="con-card con-ecom-card wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <div className="con-card-icon">
                  <img
                    src="../assets/images/Services/Prestashop.svg"
                    alt="Inquiry Icon"
                  />
                </div>
                <div className="con-card-title">
                  <h4 className="contact-form-left-heading">
                    Prestashop Customization
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-work-service-wr">
        <div className="center-wrapper">
          <div className="con-work-content">
            <div className="con-head-blk">
              <h3>Recent Creative Works</h3>
            </div>
            <div className="con-card-caption-wr clearfix">
              <div className="trigger-hover con-work-card left">
                <div className="dcard">
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div
                    className="project-block wow pulse"
                    data-wow-duration="2s"
                    data-wow-dealy="2s"
                  >
                    <figure>
                      <div className="card-image">
                        <img
                          src="../assets/images/Services/Est_A_Pulvinar_Frin.jpg"
                          alt="Project"
                        />
                      </div>
                      <figcaption>
                        <h3>
                          <Link to="/"> Est a pulvinar frin</Link>
                        </h3>
                        <p>Web Development</p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="trigger-hover con-work-card left">
                <div className="dcard">
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div
                    className="project-block wow pulse"
                    data-wow-duration="2s"
                    data-wow-dealy="2s"
                  >
                    <figure>
                      <div className="card-image">
                        <img
                          src="../assets/images/Services/Est_A_Pulvinar_Frin_2.jpg"
                          alt="Project"
                        />
                      </div>
                      <figcaption>
                        <h3>
                          <Link to="/"> Est a pulvinar frin</Link>
                        </h3>
                        <p>Web Development</p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="trigger-hover con-work-card left">
                <div className="dcard">
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div
                    className="project-block wow pulse"
                    data-wow-duration="2s"
                    data-wow-dealy="2s"
                  >
                    <figure>
                      <div className="card-image">
                        <img
                          src="../assets/images/Services/Est_A_Pulvinar_Frin_3.jpg"
                          alt="Project"
                        />
                      </div>
                      <figcaption>
                        <h3>
                          <Link to="/"> Est a pulvinar frin</Link>
                        </h3>
                        <p>Web Development</p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="con-discover-btn">
              <Link to="/website-portfolio" className="site-btn site-btn2">
                Discover More Projects
                <i className="fas fa-arrow-up"></i>
              </Link>
            </div>
          </div>
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>Web Development</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/ecommerce-development-services" className="circle-fill-btn">
                    <span>eCommerce Development</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>Mobile App Development</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing-services"
                    className="circle-fill-btn"
                  >
                    <span>Digital Solutions</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ecommerce;
