import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import footer_logo from "../../assets/images/cits_logo_new.svg";
import GetQuote from "../home/GetQuote";
import CareerPopup from "../home/CareerPopup";
import VisibilitySensor from "react-visibility-sensor";
import spinner from "../../assets/gif/loading.gif";
import { Link, useLocation } from "react-router-dom";

function Footer_second({pathname}) {
  const location = useLocation();
  const [active, setActive] = useState(pathname);
  const [data, setData] = useState([]);
  const [socialslink, setSocial] = useState([]);

  useEffect(() => {
    // Perform actions when location.pathname changes
    
	setActive(location.pathname)
    // You can trigger a re-render or any other action here
  }, [location.pathname]);

	// function handleHomeRedirect() {
	// 	try {
	// 		if(window.location.pathname === "/"){
	// 			window.scrollTo({top:0, behavior:"smooth"})
	// 		}
	// 	} catch (error) {
	// 		
	// 	}
	// }

  const Offlinesocialslink = [
    {
      title:"Behance",
      image:"2023-1_Behance.svg"
    },
    {
      title:"Twitter",
      image:"2023-1_Twitter.svg"
    },
    {
      title:"Facebook",
      image:"2023-1_Facebook.svg"
    },
    {
      title:"LinkedIn",
      image:"2023-1_Linkedin.svg"
    },
    {
      title:"Instagram",
      image:"2023-1_instagram.svg"
    },
    {
      title:"Pinterest",
      image:"2023-1_Pintrest.svg"
    },{
      title:"Skype",
      image:"2022-11_Skype.svg"
    }
  ]

  useEffect(() => {
    fetch(backendurl + "option-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "social-media")
      .then((res) => res.json())
      .then((socialslink) => setSocial(socialslink));
  }, []);

  const [isVisible, setVisibility] = useState(false);
  const [iscolor, setColor] = useState("#000");

  const onChange = (visiblity) => {
    setVisibility(visiblity);
  };
  useEffect(() => {
    if (isVisible) {
      setVisibility(true);
    }
  }, [isVisible]);

  const colorChange = (color) => {
    setColor("#000000");
  };
  useEffect(() => {
    if (isVisible) {
      setColor("#f84525");
    }
  }, [isVisible]);
  return (
    <>
      <div>
        <footer className="footer-wr">
          <div className="center-wrapper">
       
              <div className="lets-talk-blk clearfix">
                <div className="lets-talk-description wow left">
                  <p>{data.query || "Want to know more about Conative's Life?" }</p>
                </div>
                <div className="lets-talk-btn wow fadeInDown right">
                  <Link to={data.button} className="hover-up">
                    Life @Conative
                  </Link>
                </div>
              </div>
            
            <div className="footer-top clearfix">
              <div className="footer-content-block footer-logo left">
                <a href="/">
                  <figure>
                    <img
                      src={data.query ? ("../assets/imgupload/" + data.footer_logo):('../assets/imgupload/2023-1_cits_logo_footer.svg')}
						// src="assets/imgupload/2023-1_cits_logo_footer.svg"
                      alt="Conative It Solutions Logo"
                    />
                  </figure>
                </a>
                <div className="footer-para wow">
                  <p>{data.description || "Conative IT Solutions is an established web development and design company that works with companies in all sectors of business. We combine the latest technologies, tools and frameworks with years of experience to deliver customized, reliable and efficient solutions."}</p>
                </div>
                <div className="footer-call-email">
                  <div className="footer-call">
                    <a href={data.query ? ("tel:" + data.alternatephone):("/")} className="hover-up">
                      {data.alternatephone || "+91-8269788173"}
                    </a>
                  </div>
                 
                    <div className="footer-mail">
                      <a href={data.query ? ("mailto:" + data.email) : ('/')} className="hover-up">
                        {data.email || "info@conative.in"}
                      </a>
                    </div>
             
        
                    <div className="footer-mail hr_mail">
                      <a
                        href={data.query ? ( "mailto:" + data.alternateemail) : ('/')}
                        className="hover-up"
                      >
                        {data.alternateemail || "hr@conative.in"}
                      </a>
                    </div>
              
                  <div className="footer-skype">
                    <a
                      href={data.query ? ("https://join.skype.com/invite/ubEWcG5vbx0x") : ("/")}
                      target="_blank"
                      className="hover-up"
                    >
                      conativeitsolutions
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-content-block footer-our-company left">
                <h4 className=" wow fadeInDown">Our Company</h4>
                <ul className="footer-menu">
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/about-us"):("/")} style={{color:active ==="/about-us" && "#ffffff"}} >About Us</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/careers"):("/")} style={{color:active ==="/careers" && "#ffffff"}}>Careers</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ( "/contact-us"):("/")} style={{color:active ==="/contact-us" && "#ffffff"}}>Contact Us</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/life-at-conative"):("/")} style={{color:active ==="/life-at-conative" && "#ffffff"}}>Our Culture</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/team"):("/")} style={{color:active ==="/team" && "#ffffff"}} >Our Team</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/testimonial"):("/")} style={{color:active ==="/testimonial" && "#ffffff"}}>Testimonials</a>
                  </li>
                </ul>
              </div>
              <div className="footer-content-block footer-our-services left">
                <h4 className=" wow fadeInDown">Services</h4>
                <ul className="footer-menu">
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/website-design-development-services"):("/")} style={{color:active ==="/website-design-development-services" && "#ffffff"}}>
                      Website Design & Development
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/mobile-app-development-services"):("/")} style={{color:active ==="/mobile-app-development-services" && "#ffffff"}} >
                      Mobile App Development
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/ecommerce-development-services"):("/")} style={{color:active ==="/ecommerce-development-services" && "#ffffff"}}>
                      E-Commerce and CMS
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/animation-services"):("/")} style={{color:active ==="/animation-services" && "#ffffff"}}>Animation</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-design-services"):("/")} style={{color:active ==="/graphic-design-services" && "#ffffff"}}>Designing</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ( "/digital-marketing-services"):("/")} style={{color:active ==="/digital-marketing-services" && "#ffffff"}}>
                      Digital Marketing & Content Solutions
                    </a>
                  </li>
                </ul>
              </div>
 
              <div className="footer-content-block footer-industries-serve left">
                <h4 className=" wow fadeInDown">Portfolio</h4>
                <ul className="footer-menu">

                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/website-portfolio"):("/")} style={{color:active ==="/website-portfolio" && "#ffffff"}}>
                      Website Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio"):("/")} style={{color:active ==="/graphic-portfolio" && "#ffffff"}}>Graphic Design</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/logo-design"):("/")} style={{color:active ==="/graphic-portfolio/logo-design" && "#ffffff"}}>Logo Design</a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/flyer-pamphlet-design"):("/")} style={{color:active ==="/graphic-portfolio/flyer-pamphlet-design" && "#ffffff"}}>
                      Flyer/Pamphlet Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/brochure-design"):("/")} style={{color:active ==="/graphic-portfolio/brochure-design" && "#ffffff"}}>
                      Brochure Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/business-card-design"):("/")} style={{color:active ==="/graphic-portfolio/business-card-design" && "#ffffff"}}>
                      Business Card Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ( "/graphic-portfolio/tshirt-design"):("/")} style={{color:active ==="/graphic-portfolio/tshirt-design" && "#ffffff"}}>
                      T-Shirt Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/brand-identity-design"):("/")} style={{color:active ==="/graphic-portfolio/brand-identity-design" && "#ffffff"}}>
                      Corporate Brand Identity Design
                    </a>
                  </li>
                  <li className=" wow fadeInDown">
                    <a href={data.query? ("/graphic-portfolio/social-media-design"):("/")} style={{color:active ==="/graphic-portfolio/social-media-design" && "#ffffff"}}>
                      Social Media Design
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-bottom clearfix">
              <div className="office-address india-address left">
                <div className="office-address-inner">
                  <div className="country-img-name">
                    <figure>
                      <img
                        src="../assets/images/india-mini-map.png"
                        alt="India Map"
                      />
                    </figure>
                    <div className="country-name">
                      <h4>India</h4>
                    </div>
                  </div>
                  <a
                    href={data.query ? ("https://goo.gl/maps/qnE3J1yNKZdVNouF8") : ("/")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.address || "Office No. 217-218, Onam Plaza Beside Industry House, New Palasia, Indore (M.P.) India"}
                  </a>
                </div>
              </div>
              <div className="office-address canada-address left">
                <div className="office-address-inner">
                  <div className="country-img-name">
                    <figure>
                      <img
                        src="../assets/images/Canada_flag.jpg"
                        alt="Canada Map"
                      />
                    </figure>
                    <div className="country-name">
                      <h4>Canada</h4>
                    </div>
                  </div>
                  {data.query ? (  <a
                    href="https://goo.gl/maps/hHpGUMWMjwZL7JsVA"
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{ __html: data.address_1 }}
                  ></a>) : (
                    <a href="/" >33 Mcilwraith Crescent Guelph,<br/> Ontario Canada</a>
                  )}
                
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-social-icon clearfix">
            <div className="center-wrapper">
              <div className="footer-social-icon right">

                  {socialslink?.length > 0 ? (
                      socialslink.map((social) => (
                        <div key={social.socialurl} className="social-icon-anchore">
                          <span className="social-icon-span"></span>
                          <a href={social.socialurl} target="_blank" rel="noreferrer">
                            <img src={"../assets/imgupload/" + social.image} alt={social.title} />
                          </a>
                        </div>
                      ))
                    ) : (
                      Offlinesocialslink.map((social,index) => (
                        <div key={index} className="social-icon-anchore">
                          <span className="social-icon-span"></span>
                          <a href="/" target="_blank" rel="noreferrer">
                            <img src={"../assets/imgupload/" + social.image} alt={social.title} />
                          </a>
                        </div>
                      ))
                    )}

                
              </div>
              <div className="footer-copyright left">
                {data.query ? (
                  <p dangerouslySetInnerHTML={{ __html: data.footer }}></p>
                ) : (
                  <p>Copyright <i class="far fa-copyright"></i> {new Date().getFullYear()} <a href="/">Conative IT Solutions </a> Pvt. Ltd.</p>
                )
            }
              </div>
            </div>
          </div>
        </footer>
        <GetQuote />
        <CareerPopup />
        <div className="back-to-top">
          <div className="back-to-top-inner">
            <h4>Scroll</h4>
          </div>
        </div>
        <div id="loading_spinner_wr">
          <div className="loading_spinner">
            <img src={spinner} alt="loader" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer_second;
