import React from "react";
import { Link } from "react-router-dom";

function Industriesserve() {
  return (
    <>
      <div className="page-banner-wr con-life-banner-wr">
        <div className="center-wrapper">
          <div className="banner-content">
            <h1 className="page-banner-heading">Industries We Serve</h1>
          </div>
        </div>
      </div>
      <section className="banner-img-wr">
        <div className="banner-img-blk">
          <img
            src="./assets/images/industries_we_serve/Header_Image.jpg"
            alt="Industries We Serve"
          />
        </div>
        <div className="mockup-banner-img">
          <img
            src="../assets/images/industries_we_serve/Banner_mockup.png"
            alt="Diet Banner"
          />
        </div>
        <div className="center-wrapper">
          <div className="banner-img-overlay">
            <div className="con-head-blk">
              <h2>Fitness Website Development</h2>
            </div>
            <div className="con-page-content">
              <p>For Personal Trainers, Fitness Athletes and Gyms</p>
            </div>
            <div className="banner-btn-blk">
              <Link to="/" className="common-round-btn common-round-btn2">
                View Project
              </Link>
              <div className="video-layers">
                <figure
                  className="video-btn"
                  data-target="youtube"
                  data-v-id="https://www.youtube.com/embed/YsdR0i_DQgI"
                >
                  <img
                    src="../assets/images/testimonials_play_btn.svg"
                    alt="Video Play site-btn"
                  />
                </figure>
                <div className="video-circle circle-1"></div>
                <div className="video-circle circle-2"></div>
                <div className="video-circle circle-3"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-industries-wr">
        <div className="center-wrapper">
          <div className="con-head-blk common-heading">
            <h3>
              <span>Add Dimension to Your Fitness </span>
              <span>Varieties of Fitness Websites Developed by Us </span>
            </h3>
          </div>
          <div className="con-page-content">
            <p>
              Our fitness app development so ution is designed to foster persona
              ,soll-being, amplify revenue generation and expand the clientele
              for fitness service providers through our forte in fitness
              industry. We are also your right technology partner if you're
              looking for a market-ready fitness app with your own custom
              features and branding. Our fully customizable, ready-to-go fitness
              app boasts myriad of features that gives you direct return of
              money. Here are some types of fitness apps that have been
              considered as an ace quality of us.
            </p>
          </div>
          <div className="con-icon-title-wr clearfix">
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Diet_&_Nutrition.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Diet Nutrition</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Personal_Trainers.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Personal Trainers</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Workout_&_Mealplaner.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Workout & Meal Planner</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Healthy_Recipe_Finder.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Healthy Recipe Finder</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Healthy_Lifestyle.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Healthy Lifestyle</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Vitamin_&_Suuplements.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Vitamins & Supplements</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Activity_Tracking.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Activity Tracking</h4>
              </div>
            </div>
            <div className="con-icon-title-blk triangle">
              <div className="con-icon-box">
                <img
                  src="../assets/images/industries_we_serve/Workout_&_Exercise_Guide.svg"
                  alt="Diet and Nutrition"
                />
              </div>
              <div className="con-head-blk">
                <h4>Workout & Exercise Guide</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company-branding-wr con-space-wr ">
        <div className="center-wrapper">
          <div className="testimonial-head">
            <h3>Branding</h3>
          </div>
          <div className="common-heading">
            <h3>
              <span>Brand Building</span>
              <span>Company Branding</span>
            </h3>
          </div>
          <div className="company-branding-blk">
            <div className="company-branding-img">
              <img
                src="../assets/images/industries_we_serve/Branding_Mockup_2.jpg"
                alt=""
              />
            </div>
            <div className="company-branding-img">
              <img
                src="../assets/images/industries_we_serve/Branding_Mockup_1.jpg"
                alt=""
              />
            </div>
            <div className="company-branding-img">
              <img
                src="../assets/images/industries_we_serve/Branding_Mockup_3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="con-technologies-wr">
        <div className="con-technologies-banner">
          <div className="center-wrapper">
            <div className="con-head-blk">
              <h3>Our Core Technologies </h3>
            </div>
          </div>
        </div>
        <div className="con-techn-content-wr">
          <div className="center-wrapper">
            <div className="design-sol-tabber-blk">
              <ul className="design-sol-tabber-link tabber">
                <li>
                  <Link to="/" data-tab="#coreTechTabOne">
                    Toolkit
                  </Link>
                </li>
                <li>
                  <Link to="/" data-tab="#coreTechTabTwo">
                    Programming Languages
                  </Link>
                </li>
                <li>
                  <Link to="/" data-tab="#coreTechTabThree">
                    SDK
                  </Link>
                </li>
              </ul>
              <div
                className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                id="coreTechTabOne"
              >
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/React_Native.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>React Native</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Java.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>JAVA</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Objective_c.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>Objective C</h4>
                  </div>
                </div>
              </div>
              <div
                className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                id="coreTechTabTwo"
              >
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Java.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>JAVA</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/React_Native.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>React Native</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Objective_c.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>Objective C</h4>
                  </div>
                </div>
              </div>
              <div
                className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                id="coreTechTabThree"
              >
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Objective_c.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>Objective C</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/Java.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>JAVA</h4>
                  </div>
                </div>
                <div className="con-icon-title-blk">
                  <div className="con-icon-box">
                    <img
                      src="../assets/images/industries_we_serve/React_Native.svg"
                      alt="Diet and Nutrition"
                    />
                  </div>
                  <div className="con-head-blk">
                    <h4>React Native</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="industries-enquiry-form-blk clearfix">
              <div className="industries-left-blk left">
                <div className="inquiry-form-title common-heading">
                  <h3>
                    <span>Ask us</span>
                    <span>
                      Want To Know The Cost Of Creating A Fitness Website?{" "}
                    </span>
                  </h3>
                </div>
                <div className="industries-form-blk">
                  <form action="" method="">
                    <div className="enquiry-form-name-email clearfix">
                      <div className="enquiry-form-name form-field left">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name *"
                          autoComplete="off"
                        />
                      </div>
                      <div className="enquiry-form-email form-field right">
                        <input
                          type="text"
                          name="email"
                          placeholder="Work Email *"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="enquiry-form-contact form-field">
                      <input
                        type="text"
                        name="contact"
                        placeholder="Contact No.*"
                        autoComplete="off"
                      />
                    </div>
                    <div className="enquiry-form-start-project">
                      <h4>How soon do you want to start the project?*</h4>
                      <div className="form-radio-btn">
                        <div className="form-radio-input">
                          <input type="radio" name="8-weeks" id="8-weeks" />
                          <label htmlFor="8-weeks">Within 8 Weeks</label>
                        </div>
                        <div className="form-radio-input">
                          <input
                            type="radio"
                            name="8-weeks"
                            id="more-than-8-weeks"
                          />
                          <label htmlFor="more-than-8-weeks">
                            More than 8 Weeks
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="enquiry-form-requirements">
                      <h4>
                        Share your requirements*
                        <span>(minimum 250 characters)</span>
                      </h4>
                      <p>
                        (You can add links to your shareable material if any)
                      </p>
                      <div className="form-textarea form-field">
                        <textarea rows="2"></textarea>
                      </div>
                    </div>
                    <div className="form-submit-btn">
                      <input
                        type="submit"
                        name="submit"
                        value="Submit enquiry"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="industries-right-blk right">
                <img
                  src="../assets/images/industries_we_serve/Fitness_guy_image.jpg"
                  alt="Fitness Website"
                />
              </div>
            </div>
            <div className="industries-slider-wr">
              <div className="industries-slider">
                <div className="industries-slide">
                  <div className="slide-inner-space">
                    <div className="con-icon-box">
                      <img
                        src="../assets/images/industries_we_serve/Real_Estate_Icon.svg"
                        alt="Diet and Nutrition"
                      />
                    </div>
                    <div className="con-head-blk">
                      <h4>Diet Nutrition</h4>
                    </div>
                  </div>
                </div>
                <div className="industries-slide">
                  <div className="slide-inner-space">
                    <div className="con-icon-box">
                      <img
                        src="../assets/images/industries_we_serve/Real_Estate_Icon.svg"
                        alt="Diet and Nutrition"
                      />
                    </div>
                    <div className="con-head-blk">
                      <h4>Personal Trainers</h4>
                    </div>
                  </div>
                </div>
                <div className="industries-slide">
                  <div className="slide-inner-space">
                    <div className="con-icon-box">
                      <img
                        src="../assets/images/industries_we_serve/Corporate_Icon.svg"
                        alt="Diet and Nutrition"
                      />
                    </div>
                    <div className="con-head-blk">
                      <h4>Workout &amp; Meal Planner</h4>
                    </div>
                  </div>
                </div>
                <div className="industries-slide">
                  <div className="slide-inner-space">
                    <div className="con-icon-box">
                      <img
                        src="../assets/images/industries_we_serve/Education.svg"
                        alt="Diet and Nutrition"
                      />
                    </div>
                    <div className="con-head-blk">
                      <h4>Healthy Recipe Finder</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="popup-bg-wr"></div>
      <div className="popup-content">
        <div className="close-btn">
          <Link to="/">X</Link>
        </div>
        <div className="popup-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/YsdR0i_DQgI?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
          <video
            className="video-block"
            controls="true"
            autoPlay
            muted=""
            src="https://www.youtube.com/embed/YsdR0i_DQgI?autoplay=1"
            type="video/mp4"
          ></video>
        </div>
      </div>
    </>
  );
}

export default Industriesserve;
