import React, { useEffect, useState } from "react";
import Banner from "../common/CommonBanner";
import backendurl from "../constants";
import { Link } from "react-router-dom";
const Sitemap = () => {
  const [careerpost, setCareerPostData] = useState([]);

  useEffect(() => {
    fetch(backendurl + "careerpost-show")
      .then((res) => res.json())
      .then((careerpost) => setCareerPostData(careerpost));
  }, []);
  return (
    <>
      <Banner title="Site Map" />
      <section className="sitemap-main-wrapper">
        <div className="ring-wr">
          <div className="ring-1 ring"></div>
          <div className="ring-2 ring"></div>
        </div>
        <div className="ring-wr ring-right-wr">
          <div className="ring-1 ring"></div>
          <div className="ring-2 ring"></div>
        </div>
        <div className="center-wrapper">
          <div className="sitemap-inner-wrapper">
            <div className="common-heading">
              <h2>Site Map</h2>
            </div>
            <div className="sitemap-links-wrapper conative-common-list">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                  <ul>
                    {careerpost?.map((careerpostItem) => (
                      <li>
                        <Link to={"/career/" + careerpostItem.slug}>
                          {careerpostItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/life-at-conative">Life @ Conative</Link>
                </li>
                <li>
                  <Link to="/team">Team</Link>
                </li>
                <li>
                  <Link to="/testimonial">Testimonials</Link>
                </li>
                <li>
                  <Link to="/website-design-development-services">
                    Web Design & Development
                  </Link>
                </li>
                <li>
                  <Link to="/mobile-app-development-services">
                    Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link to="/ecommerce-development-services">
                    E-Commerce and CMS
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services">Animations</Link>
                </li>
                <li>
                  <Link to="/graphic-design-services">Designing</Link>
                </li>
                <li>
                  <Link to="/digital-marketing-services">
                    Digital Marketing & Content Solutions
                  </Link>
                </li>
                <li>
                  <Link to="/website-portfolio">Portfolio</Link>
                  <ul>
                    <li>
                      <Link to="/website-portfolio">
                        Website Design
                      </Link>
                      <ul>
                        <li>
                          <Link to="/website-portfolio/wordpress">Wordpress</Link>
                        </li>
                        <li>
                          <Link to="/website-portfolio/shopify">Shopify</Link>
                        </li>
                        <li>
                          <Link to="/website-portfolio/laravel">Laravel</Link>
                        </li>
                        <li>
                          <Link to="/website-portfolio/codeigniter">
                            Codeigniter
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/graphic-portfolio">Graphic Design</Link>
                      <ul>
                        <li>
                          <Link to="/graphic-portfolio/logo-design">
                            Logo Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/flyer-pamphlet-design">
                            Flyer / Pamphlet Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/brochure-design">
                            Brochure Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/business-card-design">
                            Business Card Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/tshirt-design">
                            T-Shirt Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/brand-identity-design">
                            Corporate Brand Identity Design
                          </Link>
                        </li>
                        <li>
                          <Link to="/graphic-portfolio/social-media-design">
                            Social Media Design
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/sitemap">Site Map</Link>
                  <span className="site-map-note">You Are Here</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;
