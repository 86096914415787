import React from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import UI from "../../assets/images/Services/graphic-development.jpg";
import PrototypeWireframes from "../../assets/images/Services/Prototype_and_Wireframe.png";
import GraphicsDesign from "../../assets/images/Services/Graphic_Design.png";
import PrintMaterial from "../../assets/images/Services/Printer_Imgae.jpg";
import logoBranding from "../../assets/images/Services/digital-tablet-screen-smart-tech.png";
import { Link } from "react-router-dom";
import ProductBranding from "../../assets/images/Services/Product_Branding.png";

function designing() {
  return (
    <>
      <Banner title="Designing" />
      <section className="con-ui-design-wr con-space-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={UI} alt="User Interface Designing" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>User Interface Designing</h3>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              To achieve the specific goal of using an app or visiting a
              website, you browse through numerous screens and engage with a
              variety of features, so to make your every single move more
              interesting and engaging, we have a team who works for
              customer-centric and responsive UI Design.
            </p>
          </div>
          <div className="con-customers-wr con-tab-list-wr">
            <h4>While serving our customers we are mainly focused on:</h4>
            <ul>
              <li>
                The UI Design should include a variety of features, such as
                large and native space in the website structure.
              </li>
              <li>
                To make the website more legible and usable, our team is very
                picky while selecting the color and content.
              </li>
              <li>
                Selecting the appropriate icon that works best for the website.
              </li>
              <li>
                We also concentrate on the user interface so that the website
                will function properly across all platforms.
              </li>
              <li>
                Providing user-friendly, animation and responsive designs are
                the selected agendas for our happy customers.
              </li>
            </ul>
          </div>
          <div className="con-full-width-wr con-ue-wr">
            <div className="con-head-blk">
              <h3>User Experience Designing</h3>
            </div>
            <div className="con-page-content">
              <p>
                We work with a successful approach, applying best practices and
                techniques and having a complete understanding of all the most
                recent technological instruments. The UX team can design
                solutions that are simple to use and especially suited to meet
                the unique user demands and corporate objectives of our clients.
              </p>
              <p>
                At Conative we are offering a variety of design services,
                including UX research, interaction design, full usability
                audits and other design services, that make sure the end user
                is at the center of our design considerations.
              </p>
              <p>
                We work with dedication to prioritize your requirements and
                we'll make sure that all desired and vital features are covered
                and provided.
              </p>
            </div>
          </div>
          <div className="con-left-right-wr con-ux-wr clearfix">
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>Prototype and Wireframe</h3>
              </div>
              <div className="con-page-content">
                <p>
                  What wireframing and prototype services can do to your
                  business?
                </p>
                <p>
                  If you want to translate or convert your ideas and imagination
                  into a visual form, wireframing and prototypes are the best
                  services you can avail of. Based on the business requirements,
                  they assist in completing the application's layout structure,
                  interface components, navigational flow, user journey and
                  usability.
                </p>
                <p>
                  At Conative our team has worked for years to develop
                  experience in providing the most reliable web design and web
                  development services all over the world. Our team uses methods
                  for prototyping development that enable quick iteration cycles
                  and expedite the creation and testing of wireframes.
                </p>
                <p>
                  The Conative team has delivered ample successful projects by
                  providing wireframe and prototyping services. We can greatly
                  simplify and facilitate your workflow and help to get top
                  brands to compete.
                </p>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={PrototypeWireframes} alt="Prototype and Wireframe" />
            </div>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-graphic-design-wr">
        <div className="center-wrapper">
          <div className="con-graphic-design-inner-wr">
            <div className="full-img-blk">
              <img src={GraphicsDesign} alt="Graphic Design" />
            </div>
            <div className="con-head-blk">
              <h3>Graphic Design</h3>
            </div>
            <div className="con-page-content">
              <p>
                The most cutting-edge tools for reaching your target audience
                are graphics. Companies ought to use graphic design services to{" "}
                <strong>
                  boost their brand image and increase visibility,
                </strong>{" "}
                including excellent website designs, eye-catching flyers,
                noticeable logos and appealing flexes. noticeable, top-notch
                and creative graphics instantly engage and hold the customer's
                attention.
              </p>
              <p>
                At Conative we have a team that can help you grow and enhance
                your design business and customer-envisioned web services. We
                have completed a large number of design projects utilizing the
                most efficient method. We have enough experience in creating
                designs that work according to the customer requirements. With
                the use of attractive graphics, colors and amazing photos, our
                expert designers create{" "}
                <strong>stunning designs that leave viewers in</strong> awe.
              </p>
            </div>
            <div className="con-blockquote-blk">
              <blockquote>
                <p>
                  Under Graphic designing <strong>we are serving</strong> the
                  top most services includes:
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
      <section className="con-logo-wr">
        <div
          className="white-ring-circle wow pulse animated animated animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div className="con-left-right-wr clearfix">
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>Logo & Brand Identity</h3>
                <h4>What logo & brand can do?</h4>
              </div>
              <div className="con-page-content">
                <p>
                  A graphic logo is an effective way to inform your customers
                  about your company and the ongoing process from the viewer's
                  point of view depends upon the logo's structure.
                </p>
                <p>
                  <strong>
                    Company’s Logo is a great way to create a lasting first
                    impression
                  </strong>{" "}
                  that catches your clients' attention and piques their interest
                  since, as the saying goes, your logo is frequently what your
                  audience notices first and foremost.
                </p>
                <p>
                  We at Conative help you to assist that can accomplish this by
                  analyzing your competitors and their branding in order to
                  determine yourself and intensely position your brand.
                </p>
              </div>
            </div>
            <div className="con-left-wr left">
              <img src={logoBranding} alt="Logo & Brand Identity" />
            </div>
          </div>
          <div className="con-full-width-wr">
            <div className="con-head-blk">
              <h4>Why are we different from others?</h4>
            </div>
            <div className="con-page-content">
              <p>
                We've developed an approach over the years that results in
                professional, stand-out brand designs that inspire trust.
              </p>
              <p>
                We focus on digital tech brands to ensure that the identity
                works well online and is visually{" "}
                <strong>consistent across all mediums.</strong>{" "}
              </p>
              <p>
                At Conative we have a team who works for the{" "}
                <strong>success of your company.</strong> Also, having experts
                who get trained and certified to ensure they are highly
                productive. You can choose us to become a thriving brand all
                over the world.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="con-space-wr con-product-branding-wr">
        <div className="center-wrapper">
          <div className="product-braning-inner-wr">
            <div className="con-full-width-wr">
              <img src={ProductBranding} alt="Product Branding" />
            </div>
            <div className="con-product-content-wr">
              <div className="con-head-blk">
                <h3>Product Branding</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Without a distinct brand and appeal,{" "}
                  <strong>why should customers pick your brand</strong> over
                  another? This is especially true if you are a new brand,
                  offering a product in a market dominated by established
                  brands. How will you capture the{" "}
                  <strong>attention of consumers</strong> who have already
                  developed brand loyalty? Product branding is what will grab
                  the attention of consumers, tempt them to take a chance at
                  your product and then frame their response to it. What
                  engaged them about your product in the first place and how did
                  their initial experience of it resonate with those first
                  impressions?
                </p>
                <p>
                  <strong>
                    Conative IT Solutions works on the best market research
                    techniques
                  </strong>{" "}
                  to demonstrate the market's impact and the graph of product
                  and service branding. While keeping the Consumer insights and
                  Core ideology reports in mind, we develop brand positioning
                  strategies with the help of customer feedback and services you
                  deliver.
                </p>
              </div>
            </div>
          </div>
          <div className="con-anim-left-right-wr con-illustration-wr clearfix">
            <div className="con-anim-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Illustration</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Conative assists individuals and businesses who require
                  customized services at the{" "}
                  <strong> most reasonable prices</strong> and in the shortest
                  amount of time.
                </p>
                <p>
                  Our team follows <strong>quality standards</strong> and has a
                  smooth and trouble-free outsourcing process.
                </p>
                <p>
                  We create <strong>visually appealing designs</strong> that
                  grab the interest of your target audience and improve
                  readability. Our creative team has helped numerous clients all
                  over the world over the last several decades.
                </p>
              </div>
            </div>
            <div className="con-anim-right-wr right">
              <div className="con-motion-list-wr con-angular-list">
                <h5>
                  As part of our illustration services, we provide the
                  following:
                </h5>
                <div className="con-tab-list-wr">
                  <ul>
                    <li>Illustration Services & Animations</li>
                    <li>Brand Illustration Services</li>
                    <li>Image Illustration Services</li>
                    <li>Digital illustration</li>
                    <li>Sports Illustrations</li>
                    <li>Comic Illustration Services</li>
                    <li>Vector Illustration Services</li>
                    <li>Character Illustration Services</li>
                    <li>2D Infographics Services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="con-print_material">
            <div className="con-img-title-blk">
              <div
                className="con-about-img fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={PrintMaterial} alt="Conative It" />
              </div>
              <div className="con-overlay-title common-heading con-head-blk">
                <h3>Print Material</h3>
              </div>
            </div>
            <div className="con-print-content con-page-content con-head-blk">
              <p>
                We at Conative work on creating business cards and brochures for
                flyers, pamphlets etc. Print marketing materials are extremely
                beneficial for{" "}
                <strong>
                  increasing sales and generating leads for a business
                </strong>{" "}
                . To make a good first impression, reliable connections with
                targeted customers and convey a message to the audience will
                interact with our marketing and sales materials that lead to
                earn high profit.
              </p>
              <p>
                <strong>The demand for print material is quite high</strong>{" "}
                among today's marketers, but we at Conative are working with a
                strategy that is primarily focused on sales growth.
              </p>

              <h3>Why pick Conative as your solution?</h3>

              <p>
                After giving your precious time to our page, below are the
                reasons that will ensure you why you choose Conative for graphic
                designing services.
              </p>
              <p>
                We at Conative have{" "}
                <strong>qualified and experienced UI designers</strong>, who
                have worked on ample live projects and gotten positive responses
                from the customer's point of view.
              </p>
              <p>
                <strong>Being the best graphic design firm in India,</strong> we
                provide the expertise needed to develop distinctive and
                attention-grabbing graphic designs for flyers, advertising,
                emailers and many other types of materials.
              </p>
              <p>
                Looking for the best product branding services, then you are
                pointed to the right place to meet the Conative team and get
                services.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="con-work-service-wr con-web-work-wr">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      WebDesign & <br />
                      Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/digital-marketing-services" className="circle-fill-btn">
                    <span>
                      Digital Marketing & <br />
                      Content Solutions
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Mobile App <br /> Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ecommerce-development-services"
                    className="circle-fill-btn"
                  >
                    <span>eCommerce & CMS</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default designing;
