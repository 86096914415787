import React, { useState, useEffect } from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import AnimationsHeader from "../../assets/images/Services/Animation_Header_Image.jpg";
import ServicesIcon from "../../assets/images/Services/Video_Icon.svg";
import LogoAnimation from "../../assets/images/Services/Logo_Animations_image.jpg";
import MotionGraphics from "../../assets/images/Services/Motion_graphics.png";
import VideoForMobile from "../../assets/images/Services/Animation_video.png";
import ExplainerVideo from "../../assets/images/Services/Explainer_video_analysis.png";
import LiveStream from "../../assets/images/Services/live-digital-stream.jpg";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

const Animation = () => {
  const [isVisible, setVisibility] = useState(false);
  const onChange = (visiblity) => {
    
    setVisibility(visiblity);
  };
  useEffect(() => {
    if (isVisible) {
      setVisibility(true);
    }
  }, [isVisible]);
  return (
    <>
      <Banner title="Animations" />
      <section className="con-space-wr con-digital-page-wr">
        <div className="center-wrapper">
          <div className="services-page-content-blk">
            <div className="con-img-title-blk anim">
              <div
                className="con-about-img wow fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={AnimationsHeader} alt="Conative It" />
				<div className="con-overlay-title common-heading anim con-head-blk">
              		<h3>Animation</h3>
            	</div>
              </div>
            </div>
            <div
              className="con-page-content wow fin fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <p>
                Conative IT Solutions is known for&nbsp;
                <strong>
                  offering premium multimedia services at competitive prices,
                </strong>
                &nbsp;and the accessibility of technological infrastructure has
                facilitated the company's quick growth. Along with 2D and 3D
                animation, our creative team also develops storyboards,
                animatics, sketches, portraits and caricatures.
              </p>
              <p>
                If you are seeking top-notch animation design for your
                website,&nbsp;
                <strong> we can create appealing computer graphics</strong>
                &nbsp;that suit your requirements. We use the finest synthesis
                of art, sound and computer graphics design in our animations to
                effectively convey your message.
              </p>

              <p>
                We are also renowned for placing a significant emphasis on
                working ahead of schedule and completing assignments quickly,
                which increases productivity in your business. Our team at&nbsp;
                <strong>
                  Conative uses animation to provide a high-quality product
                </strong>
                &nbsp;that conveys your message with humor and elegance. This
                was done with the ideal blend of art, sound and computer
                graphics design.
              </p>
            </div>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-animations-wr">
        <div
          className="white-ring-circle wow pulse animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div
            className="con-blockquote-blk wow fadeInDown"
            data-wow-duration="2s"
            data-wow-dealy="2s"
          >
            <blockquote>
              <p>
                We are also known for completing the task before the
                deadline,&nbsp;
                <strong> below are the services we are offering</strong>&nbsp;
                at the best and most reasonable cost:
              </p>
            </blockquote>
          </div>
          <div className="con-anim-left-right-wr clearfix">
            <div className="con-anim-right-wr right">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Logo Animation</h3>
              </div>
              <div className="con-page-content wow fadeInDown">
                <p>
                  The logo is the first thing that grabs the attention of your
                  targeted customer, the more attractive the logo is the higher
                  the chances of remembering it from the customer's point of
                  view.
                </p>
                <p>
                  Also, a logo is a vital component that plays an essential role
                  in branding by showcasing the personality of your business or
                  product. A powerful logo may express your narrative or help
                  you connect with your target audience.
                </p>
                <p>
                  The secret to success is picking the right idea for your
                  company, but a straightforward, elementary logo design can
                  also grab the audience's attention.
                </p>
              </div>
            </div>
            <div className="con-anim-left-wr left">
              <figure className="video-img-blk">
                <img src={LogoAnimation} alt="Logo Animations" />
                <figcaption>
                  <span className="video-icon video-layers">
                    <Link to="javascript:;" data-v-id="assets/video/Main.mp4">
                      <img src={ServicesIcon} alt="Video Play site-btn" />
                      <div className="video-circle circle-1"></div>
                      <div className="video-circle circle-2"></div>
                      <div className="video-circle circle-3"></div>
                    </Link>
                  </span>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="con-animations-content-wr">
            <div className="con-logo-process-wr">
              <div
                className="con-head-wr wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h4>
                  Creating a logo is not an easy task but a long process that
                  includes:
                </h4>
              </div>
              <VisibilitySensor
                offset={{ top: 10 }}
                delayedCall
                onChange={onChange}
                intervalDelay={100}
              >
                <div className="con-logo-process-blk">
                  <span className="process-line-blk">
                    <span
                      className={
                        isVisible
                          ? "horizontal-line horizontal-line-1 active"
                          : "horizontal-line horizontal-line-1"
                      }
                    ></span>
                    <span
                      className={
                        isVisible
                          ? "horizontal-line horizontal-line-2 active"
                          : "horizontal-line horizontal-line-2"
                      }
                    ></span>
                    <span
                      className={
                        isVisible
                          ? "horizontal-line horizontal-line-3 active"
                          : "horizontal-line horizontal-line-3"
                      }
                    ></span>
                    <span
                      className={
                        isVisible
                          ? "vertical-line vertical-line-1 active"
                          : "vertical-line vertical-line-1"
                      }
                    ></span>
                    <span
                      className={
                        isVisible
                          ? "vertical-line vertical-line-2 active"
                          : "vertical-line vertical-line-2"
                      }
                    ></span>
                  </span>

                  <div className="logo-process-steps">
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process1"
                    >
                      Start With Your Story
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process2"
                    >
                      Brainstorm Words That Describe Your Brand
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process3"
                    >
                      Sketch Ideas Based on These Words.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process4"
                    >
                      Develop Your Logo's Layout on a Free Design Platform.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process5"
                    >
                      Refine Your Chosen Sketch.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process6"
                    >
                      Test the Top Sketches with Buyer Persona.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process7"
                    >
                      Pick Versatile Color Options.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process8"
                    >
                      Choose a Font.
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process9"
                    >
                      Review
                    </span>
                    <span
                      className={
                        isVisible
                          ? "con-logo-process active"
                          : "con-logo-process"
                      }
                      id="logo_process10"
                    >
                      Lastly, come up with the attractive design
                    </span>
                  </div>
                </div>
              </VisibilitySensor>
              <div className="con-page-content">
                <p>
                  If a graphic designer does their work properly, your logo can
                  serve as the cornerstone of a successful marketing campaign.
                </p>
              </div>
            </div>
            <div className="con-logo-solution-wr">
              <div
                className="con-head-wr wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h4>
                  Why do you want <span>Conative</span> to build Logo for you?
                </h4>
              </div>
              <div
                className="con-page-content wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <p>
                  Conative IT Solutions has a skilled and imaginative team of
                  logo designers in India who are well-versed in creating a logo
                  for your brand. The collaborative team is also aware of how
                  the company functions and grows. We will develop a brand that
                  has a recognisable face in this fiercely competitive
                  commercial environment.{" "}
                  <strong>We will create a stunning, distinctive</strong> and
                  suitable logo that will enhance the value of your brand.
                  Additionally, we offer services for brand registration and
                  trademark registration at Conative to protect your brand from
                  any unlawful use. We took the initiative to build your brand
                  in the right meaningful way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-motion-graphic-wr">
        <figure className="con-motion-img">
          <img src={MotionGraphics} alt="Motion Graphic" />
        </figure>
        <div
          className="white-ring-circle wow pulse animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div className="con-anim-left-right-wr clearfix">
            <div className="con-anim-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Motion Graphic</h3>
              </div>
              <div className="con-page-content">
                <p>
                  Motion graphics are segments of animation that give the
                  appearance of motion. For multimedia projects, graphic
                  designers typically integrate them with audio. Animation of
                  text is typically present in motion graphic videos. Motion
                  graphic designs make it easy to convey an idea of a scenario,
                  be it through graphs, bar charts, timelines or even abstract,
                  intangible concepts. Motion graphic videos often result from
                  explainer videos.
                </p>
                <p>
                  We at Conative IT Solutions work with designers who are{" "}
                  <strong>ready to beat the challenges</strong>. Also, provide
                  the clients with more than just services instead, we deliver
                  them a distinct experience that not only has a positive impact
                  on their businesses but also leaves them completely happy. Our
                  team of experts has a wealth of expertise in producing brand
                  animation videos for clients from a variety of industries.
                  These include healthcare, banking, education, NGO,
                  transportation, hotels, tourism, etc.
                </p>
                <p>
                  We work according to{" "}
                  <strong>the industry's best practices </strong> and methods to
                  produce original and incredibly powerful animated motion
                  graphics. Our cutting-edge infrastructure and equipment
                  significantly improve the caliber of our work.
                </p>
              </div>
            </div>
            <div className="con-anim-right-wr right">
              <div className="con-motion-list-wr">
                <h5>Our motion Graphic services include:</h5>
                <div className="con-tab-list-wr">
                  <ul>
                    <li>Explainer Videos</li>
                    <li>UI Animations</li>
                    <li>Logos</li>
                    <li>Short videos</li>
                    <li>Presentation & Infographics</li>
                    <li>Animated Typography</li>
                    <li>Advanced Animated Titles</li>
                    <li>Animated Graphic Loops</li>
                    <li>Product Animations</li>
                    <li>Animated Ads</li>
                    <li>Template Editing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="con-video-wr con-space-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="con-img-title-blk ">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={VideoForMobile} alt="Video Fro Mobile" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Videos for Mobile</h3>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              At Conative IT Solutions came up with an idea to serve those
              audiences who are{" "}
              <strong>looking out for top-notch solutions</strong> related to
              the video creation and download services.
            </p>
            <p>
              Our team members created such{" "}
              <strong>eye-catching mobile videos</strong> and have backgrounds
              in a variety of fields, including film production, commercial
              video, visual effects and mobile advertising.
            </p>
            <p>
              We focus on branding and make sure that every video content we
              create adheres to the same concept and logo. Video content can be
              a terrific option for businesses wishing to launch ongoing
              marketing efforts.
            </p>
            <p>
              If you want a quality mobile video that can deliver your message
              and that is social media-ready and shareable, then can directly{" "}
              <strong>
                contact us for effective and efficient mobile video services.
              </strong>{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="explainer-video-wr">
        <div className="center-wrapper">
          <div className="con-anim-left-right-wr clearfix">
            <div
              className="con-anim-right-wr wow fadeInRight right"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <div className="con-head-blk">
                <h3>Explainer Video</h3>
              </div>
              <div className="con-page-content">
                <p>
                  A short video that highlights a company's product, service or
                  business idea in an effective and appealing way. These videos
                  are typically used for marketing or sales objectives.
                  Explainer videos are typically hosted by businesses on their
                  landing pages or displayed on the home page of their websites.
                  Some even use Facebook or other social media platforms to
                  promote their goods or services through these videos. We work
                  on these aspects in order to give the{" "}
                  <strong>best services to our clients</strong> and get the
                  expected result in return.
                </p>
                <p>
                  We typically start from scratch and finish strong, offering
                  concept, scriptwriting, design, animation, music and sound
                  everything a professional animated video needs. We can also
                  work with you if you have a clear idea of what you want.{" "}
                  <strong> We adore working with companies or agencies</strong>{" "}
                  who have a screenplay or other pre-existing materials.
                </p>
                <p>
                  Are you unsure about how to share your ideas in a creative way
                  to the world?
                </p>
                <p>
                  We can assist with posting your message on social media or in
                  search. All of our programs are inbuilt to give you the
                  strategic services that{" "}
                  <strong>take your business to the top</strong>.
                </p>
              </div>
            </div>
            <div
              className="con-anim-left-wr wow fadeInLeft left"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={ExplainerVideo} className="Explainer Video Analysis" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-video-wr mobile-app-wr">
        <div
          className="white-ring-circle wow pulse animated"
          data-wow-duration="2s"
          data-wow-dealy="3s"
          data-wow-iteration="infinite"
        ></div>
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img src={LiveStream} alt="Mobile and Web App Video" />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Mobile and Web app Video</h3>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              At Conative IT Solutions,{" "}
              <strong>we offer easy-to-use video-playing services</strong> for
              every application and software. Our professional team creates
              videos that are accessible in mobile applications and other
              website-based software.
            </p>
            <p>
              From our years of experience, we have learned that in an{" "}
              <strong> era of creativity and high resolution</strong>, we care
              about our customers' choices. We know that communicating a message
              is entirely different from creating an attractive and
              call-to-action video.
            </p>
          </div>
        </div>
      </section>
      <section className="con-space-wr con-gif-section-wr">
        <div className="con-gifs-wr">
          <div className="center-wrapper">
            <div className="con-inner-gifs-wr">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Gifs</h3>
              </div>
              <div
                className="con-page-content wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <p>
                  We have only a few seconds to{" "}
                  <strong>attract visitors</strong> to your website before they
                  leave. However, you must capture them as soon as they visit
                  your website. By using GIFs as your site's backdrop and logo,
                  you may attract visitors and keep them on your site for
                  longer.
                </p>
                <p>
                  <strong>We at Conative create amazing gifs</strong> related to
                  the websites, landing pages and other web services. You can
                  interact with your audience by using animated GIFs. Your
                  message will be quickly spread and conversions will increase.
                </p>
                <p>
                  We worked with many clients for over{" "}
                  <strong> 9+ years delivering the highest quality</strong>{" "}
                  products to our customers, also we used successful
                  methodology, strategies and tools that form the basis of
                  processes are the Conative team's primary interests. Our team
                  will present you with a customized offer with some exciting
                  pricing options after going through your requirements and
                  discussion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="gifs-list-wr">
          <div className="center-wrapper">
            <div className="con-motion-list-wr">
              <h5>Although we have created the Gif’s for:</h5>
              <div className="con-tab-list-wr for-res">
                <li>Website User Interface</li>
                <li>Blog websites</li>
                <li>Social media platforms</li>
                <li>Brand building</li>
              </div>
              <div className="con-blockquote-blk">
                <blockquote>
                  <p>
                    If you are looking for the above services related to Gifs,
                    what are you waiting for contact Conative for ultimate gif
                    designs in your services.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-other-service-wr con-animation-other-services">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/website-design-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      WebDesign & <br />
                      Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Digital Marketing & <br />
                      Content Solutions
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Mobile App <br /> Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ecommerce-development-services"
                    className="circle-fill-btn"
                  >
                    <span>eCommerce & CMS</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/graphic-design-services"
                    className="circle-fill-btn"
                  >
                    <span>Designing</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Animation;
