import React from "react";
import axios from "axios";
import backendurl from "../constants";
import { Link } from "react-router-dom";

class Contact_form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      contactid: [],
      response: {},
      form: [],
      socialslink: [],
      options: [],
      msgVisible: false,
    };

    this.getContact = this.getContact.bind(this);
  }

  componentDidMount() {
    const apiUrl = backendurl + "contact-show";

    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            contactid: result,
            form: result.form,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    const apiUrlsocial = backendurl + "social-media";

    fetch(apiUrlsocial)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            socialslink: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    const apiUrloption = backendurl + "option-show";

    fetch(apiUrloption)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            options: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    // 
  }

  getContact(e) {
    // alert("hello");
    e.preventDefault();
    const { error } = this.state;

    var yourname = document.getElementById(`name`).value;
    var subject = document.getElementById(`contact`).value;
    var youremail = document.getElementById(`email`).value;
    var yourmessage = document.getElementById(`message`).value;
    var weeks = document.querySelector(
      'input[type=radio][name="weeks"]:checked'
    ).value;
    var services = document.querySelector(
      "input[type=checkbox][name=services]:checked"
    ).value;

    // alert(weeks);
    // alert(services);

    const data = {
      name: yourname,
      subject: subject,
      email: youremail,
      message: yourmessage,
      timeinweek: weeks,
      services: services,
    };

    // 

    let apiUrl = backendurl + "createContact";

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      myHeaders,
    };

    axios
      .post(apiUrl, options)
      .then((res) => res.json())
      .then(
        (result) => {
          result.json();
        },
        (error) => {
          this.setState({ error });
        }
      );
    e.target.reset();
    this.setState({ msgVisible: true });
  }

  render() {
    const { error, contactid, form, socialslink, options } = this.state;

    const contact = form?.map((item) => item.type);

    // const linkarr = [
    //   "https://www.behance.net",
    //   "https://www.dribble.com",
    //   "https://www.facebook.com",
    //   "https://www.linkedin.com",
    //   "https://www.instagram.com",
    //   "https://www.youtube.com",
    //   "https://www.skype.com",
    // ];

    // 

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <>
          <div className="page-banner-wr">
            <div className="center-wrapper">
              <div className="banner-content">
                <h1 className="page-banner-heading">Contact Us</h1>
              </div>
            </div>
          </div>
          {/* {socialslink.map((item, index) => item.title)} */}

          <section className="your-project-wr">
            <div className="center-wrapper">
              <div className="your-project-heading">
                <h4 className="common-heading wow fadeIn">
                  <span>Let's</span>
                  <span>Talk about your project.</span>
                </h4>
              </div>
              <div className="your-project-sub-heading wow fadeIn">
                <h4>
                  Feel free to ask me any question or let’s do to talk about our
                  future collaboration.
                </h4>
              </div>
              <div className="contact-page-form-wr">
                <div className="contact-page-form-con clearfix">
                  <div className="conatct-form-detail left">
                    <div className="conatct-form-detail-inner">
                      <div className="call-us-on con-combo-heading-blk">
                        <h4 className="contact-form-left-heading">
                          <span>Call us on</span>
                          <Link to="tel:+91-8269788173">
                            +91-{options.alternatephone}
                          </Link>
                        </h4>
                      </div>
                      <div className="mail-us con-combo-heading-blk">
                        <h4 className="contact-form-left-heading">
                          <span>Mail us</span>
                          <Link to="mailto: hr@conative.in">
                            {options.alternateemail}
                          </Link>
                        </h4>
                      </div>
                      <div className="follow-us con-combo-heading-blk">
                        <h4 className="contact-form-left-heading">
                          <span>Follow us</span>
                        </h4>
                      </div>
                      <div className="social-icon-form-left">
                        <ul className="contact-social-icon-list">
                          {socialslink?.map((item, index) => (
                            <li>
                              {item.socialurl ===
                                "https://www.behance.net/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-behance"></i>
                                </Link>
                              )}
                              {item.socialurl ===
                                "https://www.dribble.com/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-dribbble"></i>
                                </Link>
                              )}
                              {item.socialurl ===
                                "https://www.facebook.com/conativeitsolutions" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-facebook-f"></i>
                                </Link>
                              )}

                              {item.socialurl ===
                                "https://www.linkedin.com/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-linkedin-in"></i>
                                </Link>
                              )}

                              {item.socialurl ===
                                "https://www.instagram.com/accounts/login/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-instagram"></i>
                                </Link>
                              )}

                              {item.socialurl ===
                                "https://www.youtube.com/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-youtube"></i>
                                </Link>
                              )}

                              {item.socialurl ===
                                "https://www.skype.com/en/" && (
                                <Link
                                  to={item.socialurl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-skype"></i>
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="grow-with-us">
                        <h3>Grow with us</h3>
                        <p>
                          Be a part of our in-house team and play your part in
                          uplifting the corporate ecosystem by making it easier
                          for brands and talents to connect with each other.
                        </p>
                      </div>
                      <div className="conatct-form-detail-btn">
                        <Link to="/">Join us!</Link>
                      </div>
                    </div>
                  </div>
                  <div className="contact-page-form-in right">
                    <div className="contact-page-form-content">
                      <div className="contact-page-form-inner">
                        <div className="form-top-heading">
                          <div className="form-heading">
                            <h4>Say hello!</h4>
                          </div>
                        </div>
                        <div className="contact-page-form-con">
                          <form
                            action="#"
                            method="POST"
                            onSubmit={this.getContact}
                            id="contactformreset"
                          >
                            <div className="enquiry-form-name-email clearfix">
                              <div className="enquiry-form-name form-field left">
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Name *"
                                  id="name"
                                  autoComplete="off"
                                />
                              </div>
                              <div className="enquiry-form-email form-field right">
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Work Email *"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="enquiry-form-contact form-field">
                              <input
                                type="text"
                                name="contact"
                                id="contact"
                                placeholder="Contact No.*"
                                autoComplete="off"
                              />
                            </div>
                            <div className="enquiry-form-start-project">
                              <h4>
                                How soon do you want to start the project?*
                              </h4>
                              <div className="form-radio-btn">
                                <div className="form-radio-input">
                                  <input
                                    type="radio"
                                    name="weeks"
                                    id="8-weeks"
                                    value="8-weeks"
                                  />
                                  <label htmlFor="8-weeks">
                                    Within 8 Weeks
                                  </label>
                                </div>
                                <div className="form-radio-input">
                                  <input
                                    type="radio"
                                    name="weeks"
                                    id="more-than-8-weeks"
                                    value="more-than-8-weeks"
                                  />
                                  <label htmlFor="more-than-8-weeks">
                                    More than 8 Weeks
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="enquiry-form-services-looking-for">
                              <h4>Which services are you looking for?*</h4>
                              <div className="form-checkbox">
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="app-development"
                                    id="app-development"
                                  />
                                  <label htmlFor="app-development">
                                    Mobile App Development
                                  </label>
                                </div>
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="web-development"
                                    id="web-development"
                                  />
                                  <label htmlFor="web-development">
                                    Web Development
                                  </label>
                                </div>
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="ecommerce-development"
                                    id="ecommerce-development"
                                  />
                                  <label htmlFor="ecommerce-development">
                                    eCommerce Development
                                  </label>
                                </div>
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="ux-and-graphics-design"
                                    id="ux-and-graphics-design"
                                  />
                                  <label htmlFor="ux-and-graphics-design">
                                    UX and Graphics Design
                                  </label>
                                </div>
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="animation"
                                    id="animation"
                                  />
                                  <label htmlFor="animation">Animation</label>
                                </div>
                                <div className="form-checkbox-input">
                                  <input
                                    type="checkbox"
                                    name="services"
                                    value="digital-solutions"
                                    id="digital-solutions"
                                  />
                                  <label htmlFor="digital-solutions">
                                    Digital Solutions
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="enquiry-form-requirements">
                              <h4>
                                Share your requirements*
                                <span>(minimum 250 characters)</span>
                              </h4>
                              <p>
                                (You can add links to your shareable material if
                                any)
                              </p>
                              <div className="form-textarea form-field">
                                <textarea rows="2" id="message"></textarea>
                              </div>
                            </div>
                            <div className="form-submit-btn">
                              <input
                                type="submit"
                                name="submit"
                                value="Submit enquiry"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="map-image-wr">
            <div className="center-wrapper">
              <div className="map-wr">
                <figure className="map-image-blk">
                  <img
                    src="../assets/images/conact-page-map.svg"
                    alt="conact-page-map"
                  />
                </figure>
                <div className="detailTab">
                  <ul className="map-dots">
                    <li className="map-tabing" data-tab="loc-1">
                      <div className="location-bubble">
                        <figure className="location-icon">
                          <img
                            src="../assets/images/India_location.png"
                            alt="India Location"
                          />
                        </figure>
                        <div className="location-address">
                          <p>{options.address}</p>
                        </div>
                      </div>
                    </li>
                    <li className="map-tabing" data-tab="loc-2">
                      <div className="location-bubble">
                        <figure className="location-icon">
                          <img
                            src="../assets/images/India_location.png"
                            alt="India Location"
                          />
                        </figure>
                        <div className="location-address">
                          <p>{options.address_1}</p>
                        </div>
                      </div>
                    </li>
                    <li className="map-tabing" data-tab="loc-3">
                      <div className="location-bubble">
                        <figure className="location-icon">
                          <img
                            src="../assets/images/India_location.png"
                            alt="India Location"
                          />
                        </figure>
                        <div className="location-address">
                          <p>{options.address_2}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

export default Contact_form;
