import React from "react";
import Banner from "../common/CommonBanner";
import QueryForm from "./QueriesForm";
import Web_developemnt_header_image from "../../assets/images/Services/Web_developemnt_header_image.jpg";
import Full_Stack from "../../assets/images/Services/Full_Stack_Image.png";
import Front_End from "../../assets/images/Services/Front_End_Image.jpg";
import Html_Css from "../../assets/images/Services/Html_Css_Image.png";
import Javascript from "../../assets/images/Services/Javascript_Image.png";
import Backend from "../../assets/images/Services/Backend_Image.png";
import Angular_Web from "../../assets/images/Services/Angular_Web_Image.png";
import Node_Js from "../../assets/images/Services/Node_Js_Image.jpg";
import Php_Development from "../../assets/images/Services/Php_Development_Image.png";
import Codeignitor from "../../assets/images/Services/Codeignitor_Image.jpg";
import Why_Consider_Conative from "../../assets/images/Services/Why_Consider_Conative_Image.png";
import Laravel from "../../assets/images/Services/Laravel_Image.png";
import React_Js from "../../assets/images/Services/React_Js_Image.jpg";
import { Link } from "react-router-dom";
const webdesignanddevelopment = () => {
  return (
    <>
      <Banner title="Web Design & Development" />
      <section className="con-web-app-wr con-space-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="con-img-title-blk">
            <div
              className="con-about-img fadeIn"
              data-wow-duration="2s"
              data-wow-dealy="2s"
            >
              <img
                src={Web_developemnt_header_image}
                alt="Conative Web App Development"
              />
            </div>
            <div className="con-overlay-title common-heading con-head-blk">
              <h3>Web Design & Development</h3>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              <strong>We deliver incredible software solutions</strong>&nbsp;
              for development across multiple sectors. Our team put all efforts
              to transform your imagination into a successful project while
              taking your consideration into our mind.
            </p>
            <p>
              In order to expand your business online, are you looking for the
              top website design company with comprehensive website development
              services that mix functionality, the most recent SEO strategies, &
              design? If so, you've landed at the appropriate web page.
            </p>
            <p>
              <strong>Conative achieved</strong>&nbsp;years of experience
              creating 100% responsive, custom WordPress, Woo Commerce and
              Shopify websites that help businesses succeed. The purpose of
              these websites is not to serve as virtual business cards. We
              precisely create secure and user-friendly websites. While web
              applications are designed with the end user and workflow by
              keeping high standards in mind.
            </p>
            <div className="con-blockquote-blk">
              <blockquote>
                <p>Custom Web Development</p>
              </blockquote>
            </div>
          </div>
          <div className="con-page-content">
            <p>
              We develop custom web designs and development for our customers
              using front-end and back-end technologies to make them
              distinctive, accessible, <strong>mobile-friendly</strong> and
              ideally suited to their business.
            </p>
            <p>
              Our web design and development services will get you more leads by
              choosing a customised web application development business for
              your latest offering.
            </p>
            <p>
              Conative IT Solutions has the&nbsp;
              <strong>perfect solution</strong>&nbsp;for you if you're looking
              for the top mobile apps and web design services.
            </p>
            <p>
              A dedicated team at Conative IT Solutions possesses a superb
              combination of technical, professional and functional knowledge,
              a team that is focused on relevant outcomes and a wealth of
              experience.
            </p>
            <p>
              We always create a&nbsp;
              <strong>long-lasting commercial solution</strong>&nbsp;that
              satisfies your requirements.
            </p>
          </div>
        </div>
      </section>
      <QueryForm />
      <section className="con-space-wr con-full-stack-wr">
        <div className="center-wrapper">
          <div className="con-left-right-wr clearfix">
            <div className="con-right-wr right right">
              <img src={Full_Stack} alt="FullStack Development" />
            </div>
            <div className="con-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>FullStack</h3>
              </div>
              <div className="con-page-content">
                <p>
                  We advanced with full-stack services for managing all the
                  stages an app or site goes through. We worked according to the
                  testing and debugging part that helps the user website to work
                  according to the devices. The applications and APIs run on the
                  server are handled by our full-stack developers. Our goal is
                  to build seamless websites that meet the customer
                  requirements.
                </p>
                <p>
                  At Conative we serve our customers with high user-friendly and
                  responsive websites. Our full-stack developer is adaptable
                  enough to transition between various technologies. To fulfil
                  our customer demands on time our Full-stack web development
                  team takes less time than any other development type to meet
                  customer needs on time.
                </p>
              </div>
            </div>
          </div>
          <div className="con-full-wr ">
            <div className="con-head-blk con-page-content">
              <h3>Front End</h3>
              <p>
                In frontend app development we work on various technologies and
                libraries: We use HTML and CSS for structuring the content on
                the page, add style and design to make the web page attractive
                from user perspective.
              </p>
              <p>
                While making the front-end-based app with a deep understanding
                of accessibility, we build the website that is accessible for
                all users on the web world.
              </p>
              <p>
                Our Frontend experts work on Preprocessors, libraries and CSS
                frameworks to make your job much easier with the help of the
                JavaScript libraries and frameworks React Vue and Angular.
              </p>
            </div>
            <div className="con-full-image-blk">
              <img src={Front_End} alt="Conative Front End Design" />
            </div>
          </div>
        </div>
      </section>
      <section className="con-web-develop-design-wr con-page-content">
        <div className="center-wrapper">
          <div className="con-left-right-wr con-htm-css-wr clearfix">
            <div className="con-left-wr left">
              <img src={Html_Css} alt="HTML/CSS" />
            </div>
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>HTML/CSS</h3>
                <p>
                  Conative is known for serving the worldwide market for the
                  past&nbsp;<strong>successful years</strong>.&nbsp;We have an
                  experienced team in HTML/CSS who build websites using HTML5
                  and CSS3 to deliver applications that are responsive and meet
                  the client's requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="con-html-css-wr">
            <h4>
              Looking for a skilled and reputable CSS designer or HTML
              developer?
            </h4>
            <div className="con-blockquote-blk">
              <blockquote>
                <p>
                  Get in touch with the&nbsp;
                  <strong>experts at Conative! </strong>&nbsp;
                  <br />
                  Our team will assist you to stand out from the competition, by
                  serving multiple fields such as E-commerce websites, landing
                  pages, PSD to HTML conversion, Bootstrap development and much
                  more.
                </p>
              </blockquote>
            </div>
          </div>
          <div className="con-left-right-wr con-javascript-wr clearfix">
            <div className="con-left-wr left">
              <img src={Javascript} alt="Javascript" />
            </div>
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>Javascript</h3>
                <p>
                  At Conative, we provide top web and mobile solutions that keep
                  the target audience engaged. In order to keep you ahead of
                  customer and business demands, we work on trending
                  technologies to design web and mobile applications.
                </p>
                <p>
                  With the use of JavaScript which is a text-based programming
                  language, we create a responsive website to use both on the
                  client-side and server-side that allow you to make web pages
                  attractive and interactive.
                </p>
              </div>
            </div>
          </div>
          <div className="con-full-wr con-head-blk con-jquery-wr">
            <h3>Jquery</h3>
            <p>
              At Conative we use&nbsp;
              <strong>top coding languages like JavaScript</strong>{" "}
              &nbsp;without any hassle; it is incredibly popular and used by our
              developers to create websites that can be interacted with and
              viewed on the webpage and in mobile applications.
            </p>
            <p>
              It doesn't matter whether you navigate through HTML documents or
              choose DOM elements to change web pages, a number of browsers are
              compatible with the basic API architecture of JQuery, which
              simplify things.
            </p>
          </div>
          <div className="con-left-right-wr con-backend-wr clearfix">
            <div className="con-left-wr left">
              <img src={Backend} alt="back-end" />
            </div>
            <div className="con-right-wr right">
              <div className="con-head-blk">
                <h3>BackEnd</h3>
                <p>
                  We at Conative IT Solutions work with&nbsp;
                  <strong>strong backend logic</strong>&nbsp;to build each site
                  and make it look good from the user's perspective, while
                  working for years in backend development our logic-based code
                  make the website work effectively.
                </p>
                <p>
                  We mainly work for&nbsp;
                  <strong>customer satisfaction</strong>&nbsp;while performing
                  each task in the backend. We have a high level of logic for
                  creating the backend of the website and are responsible for
                  the functionality as well.
                </p>
                <p>
                  Our backend strategy is fully based on a server and
                  client-based data model and make it interact with database and
                  APIs.
                </p>
              </div>
            </div>
          </div>
          <div className="con-anim-left-right-wr con-angular-wr con-page-content clearfix">
            <div className="con-anim-left-wr left">
              <div
                className="con-head-blk wow fadeInDown"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <h3>Angular Web Development</h3>
              </div>
              <div className="con-page-content">
                <p>
                  &nbsp;<strong>Our skilled professionals</strong>&nbsp;ensure
                  that the customer's projects are implemented with the most
                  cutting-edge features and designs.
                </p>
                <p>
                  Getting in the list of Angular development service providers,
                  we not only work to fulfil the requirements but also give
                  &nbsp;<strong>our best to take your business</strong>&nbsp;to
                  hold onto its position in the competitive market.
                </p>
                <p>
                  Our wide Angular development services, that are known for the
                  best web design and web development,&nbsp;
                  <strong>can bring forth the best</strong>.
                </p>
                <p>
                  Conative Angular web development services should be your first
                  choice if you're seeking a&nbsp;
                  <strong>highly qualified professional</strong>&nbsp;in this
                  advanced technical world.
                </p>
              </div>
            </div>
            <div className="con-anim-right-wr right">
              <div className="con-motion-list-wr con-angular-list">
                <h5>Our services include</h5>
                <div className="con-tab-list-wr">
                  <ul>
                    <li>Single Page Application using AngularJS</li>
                    <li>AngularJS App Maintenance</li>
                    <li>Dynamic Web Application</li>
                    <li>User Interactive Development</li>
                    <li>Widgets Development</li>
                    <li>Custom Development</li>
                  </ul>
                </div>
              </div>
              <figure className="con-angular-img">
                <img src={Angular_Web} alt="Angular Web Development" />
              </figure>
            </div>
          </div>
          <div className="con-left-right-wr con-node-wr clearfix">
            <div className="con-left-wr left">
              <img src={Node_Js} alt="Javascript" />
            </div>
            <div className="con-right-wr right for-mob-web">
              <div className="con-head-blk">
                <h3>Node JS Development</h3>
                <p>
                  Node.JS is the leading server environment to develop agile and
                  scalable network applications Android apps and iOS apps.
                </p>
                <p>
                  Not getting a professional team for handling your stuff for
                  Node.JS app development or Node web development?
                </p>
                <p>
                  We at Conative IT Solutions offer the services provided by our
                  expert team who are continuously working on:
                </p>
              </div>
              <div className="con-tab-list-wr">
                <ul>
                  <li>
                    <strong>Node.JS Consulting Services</strong>
                  </li>
                  <li>
                    <strong>Node.JS Mobile Development</strong>
                  </li>
                  <li>
                    <strong>Node.JS API Development</strong>
                  </li>
                  <li>
                    <strong>Node.JS WebApp Development</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="con-left-right-wr con-php-wr clearfix for-dev">
            <div className="con-right-wr right">
              <img src={Php_Development} alt="Php Development" />
            </div>
            <div className="con-left-wr left">
              <div className="con-head-blk">
                <h3>Php Development</h3>
                <p>
                  PHP offers one of the most&nbsp;
                  <strong>comprehensive feature</strong>&nbsp;sets. With our
                  expertise at Conative, we create a wide range of interactive
                  web products that gain customer trust.
                </p>
                <p>
                  We provide services to both&nbsp;<strong>small</strong>
                  &nbsp;and&nbsp;<strong>large</strong>&nbsp;enterprises.
                </p>
                <p>
                  We at&nbsp;<strong>Conative IT Solutions</strong>&nbsp;offer
                  you&nbsp;
                  <strong>reliable, accessible and cost-effective</strong>
                  &nbsp;PHP programming services, having the experienced team in
                  PHP-based frameworks,
                </p>
              </div>
            </div>
          </div>
          <div className="con-full-wr con-php-list-wr ">
            <h4>We are ready to serve:</h4>
            <ul>
              <li>Custom PHP Application Development</li>
              <li>PHP CMS Development</li>
              <li>PHP and Flash/Flex Integration</li>
              <li>Custom PHP Programming</li>
            </ul>
          </div>
          <div className="con-code-wr con-full-wr">
            <div
              className="white-ring-circle  wow pulse animated animated animated"
              data-wow-duration="2s"
              data-wow-dealy="3s"
              data-wow-iteration="infinite"
            ></div>
            <div className="con-img-title-blk">
              <div
                className="con-about-img fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={Codeignitor} alt="Condeignitor" />
              </div>
              <div className="con-overlay-title common-heading for-codeIgniter con-head-blk">
                <h3>CodeIgniter</h3>
              </div>
            </div>
            <div className="con-page-content class">
              <p>
                For developers who require a straightforward and elegant toolkit
                to build fully functional web apps, CodeIgniter is an effective
                PHP framework with a relatively small footprint.
              </p>
            </div>
            <div className="con-left-right-wr con-why-code-wr clearfix">
              <div className="con-left-wr con-blockquote-blk left">
                <blockquote>
                  <p>Why consider Conative for CodeIgniter services?</p>
                </blockquote>
                <figure>
                  <img src={Why_Consider_Conative} alt="Condeignitor" />
                </figure>
              </div>
              <div className="con-right-wr right">
                <p>
                  While working with us, you&nbsp;
                  <strong>don't need to be concerned</strong>&nbsp;about the
                  stability of the operation and condition of your website after
                  the delivery of the projects.
                </p>
                <p>
                  From our end the working of CodeIgniter App Development
                  process is not complete until&nbsp;
                  <strong>
                    all problems have been identified by our testing team,
                  </strong>{" "}
                  &nbsp;if any or all remain. We only deliver fully responsive
                  results to our customers.
                </p>
              </div>
            </div>
          </div>
          <div className="con-left-right-wr con-larvel-wr clearfix">
            <div className="con-right-wr right">
              <img src={Laravel} alt="Laravel" />
            </div>
            <div className="con-left-wr left">
              <div className="con-head-blk">
                <h3>Laravel</h3>
                <p>
                  The most widely used and fastest-growing open-source PHP
                  framework Laravel, since it includes cutting-edge capabilities
                  and can enhance applications that are extremely scalable.
                </p>
                <p>
                  At Conative, our team is known for serving the best Laravel
                  web development services, for us security is always our prior
                  concern.
                </p>
                <p>
                  Our team is well-known for dealing with the top Laravel
                  development company and providing a wide range of website
                  development services and getting the happy and satisfied
                  clients in return.
                </p>
              </div>
            </div>
          </div>
          <div className="con-react-wr con-full-wr">
            <div className="con-img-title-blk">
              <div
                className="con-about-img fadeIn"
                data-wow-duration="2s"
                data-wow-dealy="2s"
              >
                <img src={React_Js} alt="React Js" />
              </div>
              <div className="con-overlay-title common-heading con-head-blk">
                <h3>React Js</h3>
              </div>
            </div>
            <div className="con-page-content">
              <p>
                At Conative the team of ReactJS developers&nbsp;
                <strong>creates flexible applications</strong>&nbsp;that give
                clients a competitive edge by utilising the foundational
                components of this cutting-edge frontend framework and using
                strategic methods to identify key elements of client products
                and make necessary adjustments from their perspective on time.
              </p>
              <p>
                <strong>Conative IT Solutions</strong>&nbsp;aims to focus on the
                vital components, toolkits and React API libraries that are
                required to build the smoothest interactive and lightweight
                applications for the users.
              </p>
            </div>
            <div className="con-react-features clearfix">
              <div className="con-react-head-wr left">
                <h4>
                  Our team made the first move to meet their demands while
                  offering the services below:
                </h4>
              </div>
              <div className="con-react-list-wr con-tab-list-wr right">
                <ul>
                  <li className="extra-styling">App Modernization</li>
                  <li>Migration to React</li>
                  <li>Technical Modification</li>
                  <li>UI/UX Development</li>
                  <li>Custom React.JS</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="con-work-service-wr con-web-work-wr">
        <div className="center-wrapper">
          <div className="con-services-content">
            <div className="con-head-blk">
              <h3>Other Services</h3>
            </div>
            <div className="con-other-services-blk">
              <ul className="other-services-list">
                <li>
                  <Link
                    to="/ecommerce-development-services"
                    className="circle-fill-btn"
                  >
                    <span>eCommerce & CMS</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Digital Marketing & <br />
                      Content Solutions
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mobile-app-development-services"
                    className="circle-fill-btn"
                  >
                    <span>
                      Mobile App <br /> Development
                    </span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/animation-services" className="circle-fill-btn">
                    <span>Animation</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/graphic-design-services"
                    className="circle-fill-btn"
                  >
                    <span>Designing</span>
                    <img src="../assets/images/service_read_more.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default webdesignanddevelopment;
