import React, { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import backendurl from "../constants";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function AwradsCertification() {
	const [data, setData] = useState([]);
	const [item, setItem] = useState([]);
	const hardcodedCertificationArr = [
		{
			name:"Conative IT Solutions on Freelancer",
			image:"2023-1_freelancer.png"
		},
		{
			name:"Conative IT Solutions on AppFutura",
			image:"2022-11_app-futura.png"
		},
		{
			name:"Conative IT Solutions on Upwork",
			image:"2023-1_upwork.png"
		},
		{
			name:"Conative IT Solutions on Clutch.co",
			image:"2022-11_clutch.png"
		}
	]

	useEffect(() => {
		fetch(backendurl + "awardscertification")
			.then((response) => response.json())
			.then((data) => { setData(data) });

		fetch(backendurl + "awardsitem")
			.then((res) => res.json())
			.then((item) => { setItem(item) });
	}, []);

	return (
		<>
			{item ? (
				<section className="certification-wr" scroll-color="white">
					<div className="center-wrapper">
						
							<div className="certification-heading">
								<h4 className="common-heading">
									{data.title && <span>{data.title || "Achievements"}</span>}
									{data.name && <span>{data.name || "Certificates and Awards"}</span>}
								</h4>
							</div>
						<div className="certification-slider-con">
							<div className="scroll-line certification-line"></div>
							<div className="certification-slider-inner">
								<Swiper
									modules={[Pagination]}
									slidesPerView={4}
									slidesPerGroupSkip={4}
									spaceBetween={50}
									// loopedSlidesLimit={false}
									pagination={{ clickable: true }}
									breakpoints={{
										320: {
											slidesPerView: 1,
											spaceBetween: 30,
										},
										649: {
											slidesPerView: 2,
											spaceBetween: 30,
										},

										1024: {
											slidesPerView: 4,
											spaceBetween: 50,
										},
									}}
								>
										{item?.length > 0 ? (
										  <div className="certification-slider">
										    {item.map((post) => (
										      <div key={post?._id}>
										        <SwiperSlide>
										          <div className="certification-slider-content">
										            <div className="certification-slider-content-inner">
										              <img
										                src={"./assets/imgupload/" + post.image}
										                alt="good-firm-imagegood"
										              />
										              <div className="certification-slider-heading">
										                <h4>{post.name}</h4>
										              </div>
										            </div>
										          </div>
										        </SwiperSlide>
										      </div>
										    ))}
										  </div>
										) : (
										  <div className="certification-slider">
										     {hardcodedCertificationArr.map((post,index) => (
										      <div key={index}>
										        <SwiperSlide>
										          <div className="certification-slider-content">
										            <div className="certification-slider-content-inner">
										              <img
										                src={"./assets/imgupload/" + post.image}
										                alt="good-firm-imagegood"
										              />
										              <div className="certification-slider-heading">
										                <h4>{post.name}</h4>
										              </div>
										            </div>
										          </div>
										        </SwiperSlide>
										      </div>
										    ))}
										  </div>
										)}

								</Swiper>
							</div>
						</div>
					</div>
				</section>
			) : (
				<span></span> // "" Removed 
			)}
		</>
	);
}

export default AwradsCertification;
