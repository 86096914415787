import axios from "axios";
import backendurl from "../component/constants";

class DataService {
	/**
	 * 
	 * @param none
	 * FOR ABOUT US PAGE
	 * 
	 */ 
	getAboutUsContent(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "aboutus-content", config)
	}
	getTechSlideItem(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "techSlideItem-show", config);
	}
	getTourSlideItem(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "tourSlideItem-show", config);
	}
	getChooseSlideShow(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "chooseSlide-show", config);
	}
	
	/**
	 * 
	 * @param none
	 * FOR LANDING PAGE 
	 * 
	 */

	getHomeData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "home-show", config);
	}
	getDigitalData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "digital-show", config);
	}
	getLegacySlideItemData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "legacySlideItem-show", config);
	}
	getStartPjtShow(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "startpjt-show", config);
	}
	getGallerySlideItem(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "gallerySlideItem-show", config);
	}
	getExpertiseData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "expertise-show", config);
	}
	getExpertiseItemData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "expertiseitem-show", config);
	}
	getExpertiseListData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "expertiseitem-show-list", config);
	}
	getIndustriesData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "industries-show", config);
	}
	getIndustryItemData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "industriesitem-show", config);
	}
	getOurProjectsData(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "ourprojects-show", config);
	}
	getOurProjectsItemData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "ourprojectsitem-show-limit", config);
	}
	getClientProjectData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "cp-show", config);
	}
	getClientProjectItemData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "cpi-show", config);
	}
	getClientTestimonialData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "testimonial-show", config);
	}
	getAwardsCertificationData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "awardscertification", config);
	}
	getAwardsCertificationItemData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "awardsitem", config);
	}
	
	/**
	 * 
	 * LIFE AT CONATIVE 
	 * 
	 */

	getLifeContent() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "life-content-show", config);
	}
	getLifeItem(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "lifeitem-show", config);
	}

	/**
	 * 
	 * CAREER PAGE CONATIVE
	 * 
	 */	

	getCareerContent(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "career-content", config)
	}
	
	getCareerPostBySlug(slug){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "careerpost-single/" + slug, config);
	}

	getCareerPosts() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "careerpost-show", config);
	}

	handleSendCareerMail(data){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.post(backendurl + "sendmail", data, config);
	}

	/**
	 * 
	 * FOOTER DATA
	 * 
	 */

	getOptions(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "option-show", config);
	}
	getSocialMedia(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "social-media", config);
	}

	/**
	 * 
	 * CONTACT US PAGE
	 * 
	 */

	getContactContent() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "contactus-content-show", config);
	}

	/**
	 * 
	 * GRAPHICS DESIGN PAGE
	 * 
	 */

	getGraphicDesignPost(slug){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "graphicsDesignPost-single/"+slug, config);
	}
	getGraphicsDesignPostData(slug) {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "graphicsDesignPost-single/"+slug, config);
	}
	getAllGraphicsDesignData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "graphicsDesignPost-show", config);
	}
	getGraphicDesignContent() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "graphic-design-content-show", config);
	}

	/**
	 * 
	 * INDUSTRIES WE SERVE
	 * 
	 */

	getIndustriesPostData(slug) {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "industries-single/"+slug, config);
	}

	/**
	 * 
	 * PORTFOLIO PAGE
	 * 
	 */
	getWebsiteDesignContent(){
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "website-design-content-show", config);
	}
	getWebDevelopmentPostData() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "web-development-post-show", config);
	}
	getWebDevelopmentPostDataBySlug(slug) {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "web-development-post-single/"+slug, config);
	}

	/**
	 * 
	 * OUR TEAM PAGE
	 * 
	 */

	getTeamContent() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "team-content-show", config);
	}

	getTeamItems() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "teamitem-show", config);
	}

	/**
	 * 
	 * TESTIMONIAL PAGE 
	 * 
	 */

	getTestimonialContent() {
		const config = { 
			headers:{
				'Content-Type':'application/json'
			}
		}
		return axios.get(backendurl + "testimonial-content-show", config);
	}
}

const conativeDataService = new DataService();

export default conativeDataService;