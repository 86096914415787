import React, { useEffect, useState } from "react";
import backendurl from "../constants";
// import axios from 'axios';
import Meta from "../Meta/Meta";
import { Link } from 'react-router-dom';
import $ from 'jquery'
import ConfettiN from "../common/loaders/ConfettiSmall";
import jQuery from 'jquery'



function Home(props) {
	const [data, setData] = useState([]);
	const handleGetQuote = (e) => {
		e.preventDefault();
		try {
			$(".start-a-project-btn").on("click", function (e) {
				e.preventDefault();
				$(".enquiry-form-content").fadeIn();
				$(".popup-bg-wr").fadeIn();
				$("body").css("overflow-y", "hidden");
			  });
		} catch (error) {
			
		}
	}
	const handleCarrerPage = (e) => {
		e.preventDefault();
		try {
			$("#build-career-button").on("click", function (e) {
				e.preventDefault();
				$(".build-career-popup-wrapper").fadeIn();
				$(".popup-bg-wr").fadeIn();
				$("body").css("overflow-y", "hidden");
			  });
		} catch (error) {
			
		}
	}
	useEffect(() => {
		fetch(backendurl + "home-show")
			.then((response) => response.json())
			.then((data) => setData(data));
	}, []);

	useEffect(() => {
		document.body.classList.add("home");
	}, []);


	var result = "";
	var motivation = data.name;
	if (motivation !== undefined) {
		result = motivation.split(",");
	}

	
	if (data) {
		return (
			<>
				<Meta
					title={data.meta_title}
					desc={data.meta_desc}
					keywords={data.meta_keywords}
				/>
					
				<section className="banner-wr aniversary" id="homepage" scroll-color="white">
				<div style={{width:"24%",position:"absolute",display:"flex",zIndex:"100",top:"-100px",left:"-50px"}}>
					
			</div>	
					<div className="center-wrapper">
					
						<div className="banner-content-area clearfix">
							<div className="banner-content-blk left">
								<h1>
									{data.backname ? (
										<span
											className="large-head wow fadeInDown"
											data-wow-duration="1s"
										>
											{data.backname}
										</span>
									) : (
										<span
										className="large-head wow fadeInDown"
										data-wow-duration="1s"
									>
										Conative
									</span>
									)}
									{data.title ? (
										<span className="sub-head wow fadeInDown">
											{data.title}
										</span>
									) : (
										<span className="sub-head wow fadeInDown">
											We Believe In
										</span>
									)}
								</h1>
								{data.name ? (
									<span dangerouslySetInnerHTML={{ __html: data.name }}></span>
								) : (
									<span><ul class="dynamic-txts"><li><span>Innovation</span></li><li><span>Passion</span></li><li><span>Dedication</span></li></ul></span>
								)}
								{data.content ? (
									<div
										className="banner-content wow fadeInDown"
										data-wow-duration="2s"
									>
										<p>{data.content}</p>
									</div>
								) : (
									<div
									className="banner-content wow fadeInDown"
									data-wow-duration="2s"
								>
									<p>We offer the best web development services and are committed to providing quick solutions to our customer's problems.</p>
								</div>
								)}
									<div className="start-project-build-team-btn">
										<div className="round-btn-con">
											{data.btnprojecturl ? (
												<a
													href
													onClick={(e)=>{handleGetQuote(e)}}
													className="start-a-project-btn common-round-btn"
												>
													<span>{data.btnproject}</span>
												</a>
											) : (
												<a
													href
													className="start-a-project-btn common-round-btn"
												>
												<span>Start a project</span>
												</a>
											)}
											{data.btnteamurl ? (
												<a
													onClick={handleCarrerPage}
													className="common-round-btn common-round-btn2"
													id="build-career-button"
												>
													<span>{data.btnteam}</span>
												</a>
											) : (
												<a
												className="common-round-btn common-round-btn2"
												id="build-career-button"
											>
												<span>Grow Career With Us</span>
											</a>
											)}
										</div>
									</div>
								
							</div>
							<div className="banner-image-blk right ">
								<video
									muted
									autoPlay={true}
									playisinline="true"
									loop={true}
									src="../assets/video/Banner_Video.mp4?autoplay=1"
								></video>
								{/* <figure>
								<img src="./assets/images/aniversaryNum.png" alt="conative 10th aniversary num image"></img>
								<figcaption className="animate-char-wr" ><p className="animate-character wave">
									<span>A</span>
									<span>N</span>
									<span>N</span>
									<span>I</span>
									<span>V</span>
									<span>E</span>
									<span>R</span>
									<span>S</span>
									<span>A</span>
									<span>R</span>
									<span>Y</span>
									</p></figcaption>
								</figure>
								<figure className="aniversary-sparkle-collection-wr top-right-sparkle banner-jquery-1">
								<img src="./assets/images/topRightSparkle.png" alt="conative 10th aniversary Sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr  bottom-left-sparkle banner-jquery-2">
								<img src="./assets/images/bottomLeftSparkle.png" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr exclaim banner-jquery-3">
								<img src="./assets/images/ecxlaimImg.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr bottom-sparkle banner-jquery-4">
								<img src="./assets/images/bottomSparkle.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr ribbon banner-jquery-5">
								<img src="./assets/images/celebRibbon.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr big-star banner-jquery-6">
								<img src="./assets/images/bigStar.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure> */}
								{/* for animation */}
								{/* <figure className="aniversary-sparkle-collection-wr big-star for-anim-top-left">
								<img src="./assets/images/bigStar.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr big-star for-anim-bottom-left">
								<img src="./assets/images/bigStar.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr big-star for-anim-top-right">
								<img src="./assets/images/bigStar.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure>
								<figure className="aniversary-sparkle-collection-wr big-star for-anim-bottom-right">
								<img src="./assets/images/bigStar.svg" alt="conative 10th aniversary sparkle image"></img>
								</figure> */}
								

							</div>
						</div>
					</div>
					{/* <div style={{width:"80px",position:"absolute",bottom:"0"}}> */}
						{/* <div className="anTen" style={{display:"flex",alignItems:"center"}}>
						<img src="/assets/images/anOne.svg"/>
						<img src="/assets/images/anZero.svg"/>
						<span style={{fontSize:"40px",lineHeight:"45px",color:"#0000001a",padding:"28px"}}>Yrs of togatherness</span>
						</div> */}
				{/* <h2 className="aniversary">Celebrating  10+ Yrs</h2> */}
			{/* </div> */}
			
				</section>
			</>
		);
	}
}

export default Home;
