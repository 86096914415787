import React from "react";

const common_banner = (props) => {
  
  return (
    <>
      <div className="page-banner-wr con-life-banner-wr">
        <div className="center-wrapper">
          <div className="banner-content">
            <h1 className="page-banner-heading">{props.title}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default common_banner;
