import React, { useState } from "react";
import backendurl from "../constants";
import swal from "sweetalert";
import axios from "axios";

const GetQuote = () => {
	const [mailerState, setMailerState] = useState({
		name: "",
		email: "",
		contact: "",
		message: "",
	});
	const [file, setFile] = useState();
	const [fileName, setFileName] = useState("");

	const saveFile = (e) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
	};

	const handleStateChange = (e) => {
		setMailerState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const handleGetQuote = (e) => {
		e.preventDefault();
		try {
			document.getElementById("get-quote-form").style.display = 'block'
			document.getElementsByClassName('popup-bg-wr').style.display = 'block'
		} catch (error) {
			
		}
	}
	
	const submitEmail = async (e) => {
		e.preventDefault();
		
		var spinner = document.getElementById("loading_spinner_wr");
		spinner.style.display = "block";

		var filename = "";
		var filepath = "";

		const formData = new FormData();
		formData.append("file", file);
		formData.append("fileName", fileName);
		try {
			const file_res = await axios.post(backendurl + "filesUpload", formData);
			
			document.getElementById("getintouch_upload_file_path").value =
				file_res["data"]["filepath"];
			document.getElementById("getintouch_upload_file_name").value =
				file_res["data"]["file"];
			filename = file_res["data"]["file"];
			filepath = file_res["data"]["filepath"];
		} catch (ex) {
			// 
		}

		const { name, email, contact, message } = mailerState;


		var checkboxes = document.getElementsByName("services[]");
		var services = "";
		for (var i = 0, n = checkboxes.length; i < n; i++) {
			if (checkboxes[i].checked) {
				services += "," + checkboxes[i].value;
			}
		}
		if (services) services = services.substring(1);

		document.getElementById("allservices").value = services;

		var subject = document.getElementById("getintouch_subject").value;
		
		// 
		if (
			name == "" ||
			email == "" ||
			contact == "" ||
			services == "" ||
			subject == "" ||
			message == ""
		) {
			swal("", "Please fill all the required* fields!", "info");
		} else {
			const response = await fetch(backendurl + "sendmail", {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({
					name,
					email,
					contact,
					message,
					services,
					filename,
					filepath,
					subject,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					setMailerState({
						name: "",
						email: "",
						contact: "",
						message: "",
					});
					document.getElementById("getintouch_form").reset();
					spinner.style.display = "none";
					var res_message = data["message"];
					if (data["code"] == "success") {
						swal({
							text: `${res_message}`,
							icon: "success",
						}).then((value) => {
							window.location.reload();
						});
					} else {
						swal({
							text: `${res_message}`,
							icon: "info",
						}).then((value) => {
							window.location.reload();
						});
					}
				});
		}
	};

	return (
		<>
			<div className="popup-bg-wr"></div>
			<div className="get-quote">
				<div className="get-quote-inner">
					<a href="#" onClick={handleGetQuote}>
						<span>
							<img src="../assets/images/get-quote.svg" alt="get-quote" />
						</span>
						<span>Get Project With Us</span>
					</a>
				</div>
			</div>
			<div id="get-quote-form" className="enquiry-form-wr get-quote-form fd">
				<div className="center-wrapper">
					<div className="enquiry-form-content">
						<div className="enquiry-form-inner">
							<div className="form-top-heading">
								<figure>
									<img
										src="../assets/images/form-heading-icon.png"
										alt="form-heading-icon"
									/>
								</figure>
								<div className="form-heading">
									<h4>Ready to Serve you first!</h4>
								</div>
							</div>
							<div className="enquiry-form-con">
								<form id="getintouch_form" data-toggle="validator">
									<input
										type="hidden"
										name="subject"
										id="getintouch_subject"
										value="Get Project With Us"
									/>
									<input
										type="hidden"
										name="upload_file_path"
										id="getintouch_upload_file_path"
									/>
									<input
										type="hidden"
										name="upload_file_name"
										id="getintouch_upload_file_name"
									/>
									<input name="allservices" id="allservices" type="hidden" />
									<div className="enquiry-form-name-email clearfix">
										<div className="form-group enquiry-form-name form-field left">
											<input
												type="text"
												name="name"
												placeholder="Full Name *"
												autoComplete="off"
												onChange={handleStateChange}
												value={mailerState.name}
												pattern="\b\w+\b(?:.*?\b\w+\b){1}"
												data-error="The field is required."
												required
											/>
											<div className="ksh-form-error help-block with-errors"></div>
										</div>
										<div className="form-group enquiry-form-email form-field right">
											<input
												type="text"
												name="email"
												placeholder="Work Email *"
												autoComplete="off"
												onChange={handleStateChange}
												value={mailerState.email}
												pattern="\S+@\S+\.\S+"
												data-error="The field is required."
												required
											/>
											<div className="ksh-form-error help-block with-errors"></div>
										</div>
									</div>
									<div className="form-group enquiry-form-contact form-field">
										<input
											id="getintouch_phone"
											type="text"
											name="contact"
											placeholder="Contact No.*"
											autoComplete="off"
											onChange={handleStateChange}
											value={mailerState.contact}
											pattern="^\d{3}-\d{3}-\d{4}$"
											maxLength="12"
											data-error="The field is required."
											required
										/>
										<div className="ksh-form-error help-block with-errors"></div>
									</div>
									<div className="enquiry-form-services-looking-for">
										<h4>Which services are you looking for?*</h4>
										<div className="form-checkbox">
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-web-development"
													value="Web Design & Development"
												/>
												<label htmlFor="quote-web-development">
													Web Design & Development
												</label>
											</div>
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-creative-design"
													value="Graphic Design"
												/>
												<label htmlFor="quote-creative-design">
													Graphic Design
												</label>
											</div>
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-mobile-app-developemnt"
													value="Mobile App Development"
												/>
												<label htmlFor="quote-mobile-app-developemnt">
													Mobile App Development
												</label>
											</div>
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-eCommerce"
													value="eCommerce and CMS"
												/>
												<label htmlFor="quote-eCommerce">
													eCommerce and CMS
												</label>
											</div>
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-animation"
													value="Animation"
												/>
												<label htmlFor="quote-animation">Animation</label>
											</div>
											<div className="form-checkbox-input">
												<input
													type="checkbox"
													name="services[]"
													id="quote-seo-digital-marketing"
													value="Digital Marketing & Content Solutions"
												/>
												<label htmlFor="quote-seo-digital-marketing" className="twiceLabel">
													Digital Marketing & Content Solutions
												</label>
											</div>
										</div>
									</div>
									<div className="enquiry-form-requirements">
										<h4>
											Share your requirements*
											<span>(minimum 250 characters)</span>
										</h4>
										<p>(You can add links to your shareable material if any)</p>
										<div className="form-group form-textarea form-field">
											<textarea
												id="message"
												name="message"
												rows="2"
												onChange={handleStateChange}
												value={mailerState.message}
												data-error="The field is required."
												minLength={250}
												required
											></textarea>
											<div className="ksh-form-error help-block with-errors"></div>
										</div>
									</div>
									<div className="enquiry-form-upload-field form-field">
										<h4>Upload your document</h4>
										<div className="form-upload-field">
											<input
												type="file"
												name="uploadUserFile"
												accept="application/pdf"
												onChange={saveFile}
											/>
											<span>Drag &amp; Drop your file</span>
										</div>
									</div>
									<div className="form-submit-btn">
										<input
											type="submit"
											name="submit"
											value="Submit enquiry"
											onClick={submitEmail}
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="form-cross-btn">
							<p>&#10005;</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GetQuote;
