import React, { useEffect, useState } from 'react'
import backendurl from '../../constants';

function YoutubeCarouselLatestFooter() {
	const [socialslink, setSocial] = useState([]);

	useEffect(() => {
    fetch(backendurl + "social-media")
      .then((res) => res.json())
      .then((socialslink) => setSocial(socialslink));
  }, []);

	const Offlinesocialslink = [
    {
      title:"Behance",
      image:"2023-1_Behance.svg"
    },
    {
      title:"Twitter",
      image:"2023-1_Twitter.svg"
    },
    {
      title:"Facebook",
      image:"2023-1_Facebook.svg"
    },
    {
      title:"LinkedIn",
      image:"2023-1_Linkedin.svg"
    },
    {
      title:"Instagram",
      image:"2023-1_instagram.svg"
    },
    {
      title:"Pinterest",
      image:"2023-1_Pintrest.svg"
    },{
      title:"Skype",
      image:"2022-11_Skype.svg"
    }
  ]
	return (
		<>
		<div>
			<footer className="footer-wr" style={{paddingBlock:"25px"}}>
				<div className="copyright-social-icon clearfix">
					<div className="center-wrapper"  style={{display:"flex", alignItems:"center", flexWrap:"wrap", flexDirection:"row-reverse", justifyContent:"space-between"}}>
						<div className="footer-social-icon right">

								{socialslink?.length > 0 ? (
										socialslink.map((social) => (
											<div key={social.socialurl} className="social-icon-anchore">
												<span className="social-icon-span"></span>
												<a href={social.socialurl} target="_blank" rel="noreferrer">
													<img src={"../assets/imgupload/" + social.image} alt={social.title} />
												</a>
											</div>
										))
									) : (
										Offlinesocialslink.map((social,index) => (
											<div key={index} className="social-icon-anchore">
												<span className="social-icon-span"></span>
												<a href="/" target="_blank" rel="noreferrer">
													<img src={"../assets/imgupload/" + social.image} alt={social.title} />
												</a>
											</div>
										))
									)}

							
						</div>
						<div className="footer-copyright left" style={{marginTop:"0px"}}>
								<p>Copyright <i class="far fa-copyright"></i> {new Date().getFullYear()} <a href="/">Conative IT Solutions </a> Pvt. Ltd. All Rights Reserved.</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	</>
	)
}

export default YoutubeCarouselLatestFooter