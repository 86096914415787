import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import { useNavigate, useNavigation } from "react-router-dom";
import "./header-submenu.css";
import About_us from "../../assets/images/menu-icons/About_us.svg";
import Career from "../../assets/images/menu-icons/Career.svg";
import Contact_Us from "../../assets/images/menu-icons/Contact_Us.svg";
import Our_Culture from "../../assets/images/menu-icons/Our_Culture.svg";
import Testimonial from "../../assets/images/menu-icons/Testimonial.svg";
import Our_Team from "../../assets/images/menu-icons/Our_Team.svg";
import { Link } from "react-router-dom";  

const Companysubmenu = (props)=> {
  const [socialslink, setSocial] = useState([]);
  const [filler, setFiller] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    
      fetch(backendurl + "option-show")
        .then((response) => response.json())
        .then((data) => {setFiller(data)});
      fetch(backendurl + "social-media")
        .then((res) => res.json())
        .then((socialslink) => setSocial(socialslink));
    
    }, []);
    
    const handleLinkClick = (targetUrl) => {
      try {
        props.close_menu();
  
        if (window.location.pathname !== targetUrl) {
          navigate(targetUrl);
        }
  
      } catch (error) {
        
      }
    };

  return (
    <>
      <div className="compnay-submenu-wr">
        <ul className="submenu">
          <li>
      
                <Link to={socialslink?.length > 0 ? "/about-us" : "/" } 
                onClick={()=> handleLinkClick("/about-us")}
                className="menu-text">
                <span className="submenu-image">
                  <img src={About_us} alt="About Us" />
                </span>
                <span className="submenu-text-wr">
                  <span className="submenu-text">About Us</span>
                  <span className="header-submenu-content">
                    <p>The more you know the more you grow.</p>
                    <Link to={socialslink?.length > 0 ? "/about-us" : "/" }
                    onClick={()=> handleLinkClick("/about-us")}>Know More</Link>
                  </span>
                </span>
              </Link>
      
    
          </li>
          <li>
            
          <Link to={socialslink?.length > 0 ? "/careers" : "/" }   onClick={()=> handleLinkClick("/careers")} className="menu-text">
              <span className="submenu-image">
                <img src={Career} alt="Careers" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Careers </span>
                <span className="header-submenu-content">
                  <p>Let's take you up high.</p>
                  <Link to={socialslink?.length > 0 ? "/careers" : "/" } onClick={()=> handleLinkClick("/careers")}>Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
          <a href={socialslink?.length > 0 ? "/contact-us" : "/" } onClick={()=> handleLinkClick("/contact-us")} className="menu-text">
              <span className="submenu-image">
                <img src={Contact_Us} alt="Contact Us" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Contact Us</span>
                <span className="header-submenu-content">
                  <p>Just one click away.</p>
                  <a href={socialslink?.length > 0 ? "/contact-us" : "/" } onClick={()=> handleLinkClick("/contact-us")} >Click Now</a>
                </span>
              </span>
            </a>
          </li>
          <li>
          <Link to={socialslink?.length > 0 ? "/life-at-conative" : "/" } onClick={()=> handleLinkClick("/life-at-conative")} className="menu-text">
              <span className="submenu-image">
                <img src={Our_Culture} alt="Our Culture" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Our Culture</span>
                <span className="header-submenu-content">
                  <p>Let’s match the vibes to shine.</p>
                  <Link to={socialslink?.length > 0 ? "/life-at-conative" : "/" } onClick={()=> handleLinkClick("/life-at-conative")} >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
          <Link to={socialslink?.length > 0 ? "/testimonial" : "/" } onClick={()=> handleLinkClick("/testimonial")}  className="menu-text">
              <span className="submenu-image">
                <img src={Testimonial} alt="Testimonials" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Testimonials</span>
                <span className="header-submenu-content">
                  <p>Let our work speak for us.</p>
                  <Link to={socialslink?.length > 0 ? "/testimonial" : "/" }onClick={()=> handleLinkClick("/testimonial")} > Know More</Link>
                </span>
              </span>
            </Link>
          </li>
          <li>
          <Link to={socialslink?.length > 0 ? "/team" : "/" } onClick={()=> handleLinkClick("/team")} className="menu-text">
              <span className="submenu-image">
                <img src={Our_Team} alt="Our Team" />
              </span>
              <span className="submenu-text-wr">
                <span className="submenu-text">Our Team</span>
                <span className="header-submenu-content">
                  <p>Let’s achieve success together.</p>
                  <Link to={socialslink?.length > 0 ? "/team" : "/" } onClick={()=> handleLinkClick("/team")} >Know More</Link>
                </span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Companysubmenu;
