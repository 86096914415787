import React, { useEffect, useState, useContext,useRef } from "react";
// import { createPortal } from 'react-dom';
import Home from "./Home";
import DigitalLegacy from "./DigitalLegacy";
import ProjectSection from "./ProjectSection";
import ExpertiseSecond from "./Expertise_second";
import OurProjects from "./OurProjects";
import IndustriesSection from "./IndustriesSection";
import ClientDetailsSection from "./ClientDetailsSection";
import AwradsCertification from "./AwradsCertification";
import ChristmasLoader from "../common/loaders/christmasLoader";
import $ from 'jquery'
import SantaImage from "../common/santaAnimation";
import { EventContext } from "../../App"; 
import Loadingpage from "../common/Loadingpage";

const HomeLayout = () => {



  useEffect(() => {
    $("body").css("overflow", "hidden");
      setTimeout(function () {
        $("#loadingdiv").fadeOut();
        $("body").css("overflowY", "visible");
        $("#root").fadeIn();
      }, 2700);
  }, []);

  return (
    <>
  
      <div className="christmasLoadingDiv" id="loadingdiv" style={{background:`#000`}}>
        {/* <ChristmasLoader/> */}
        <Loadingpage />
      </div>
      <div className="wrapper">
	        <Home />
        	<DigitalLegacy />
	        <ProjectSection />
	        <ExpertiseSecond />
	        <IndustriesSection />
	        <OurProjects />
	        <ClientDetailsSection />
        	<AwradsCertification />
      </div>
    </>
  );
};

export default HomeLayout;
