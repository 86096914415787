import React, { useEffect, useState } from "react";
import Analytics_Counter from "../common/Analytics_Counter";
import backendurl from "../constants";
import { Navigation, Scrollbar, A11y } from "swiper";
import ReactPlayer from "react-player";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "react-modal-video/scss/modal-video.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function ClientDetailsSection() {
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  // const [img, setImg] = useState([]);
  const [slider, setSlider] = useState([]);
  
  const hardCodedClientArr = [
    {
      content:"We greatly appreciate all your hard work and dedication, I am truly enjoying working with you all for the past couple of years and also we are looking for a much more successful journey together.",
      name:"Ali",
      designation:""
    },
    {
      content:`I have been working with Conative for more than 6 years so just want to tell you that I appreciate all the services I am getting from your team.
      Your work has been a very important part of my business.
      
      Thank you for the great work done I will be counting on you during my next project
      “Cheers to the Conative team”`,
      name:"Alex",
    },
    {
      content:"We greatly appreciate all of your hard work and dedication. I have truly enjoyed working with you over the past several years and look forward to many more successful years together.",
      name:"Josh",
    },
    {
      content: `I would like to thank Jitendra, Vivek and the Conative team who are working on all my projects.
      The conative team always comes up with the best solution and that is the most important part for any company to grow.
      
      I appreciate your hard work and wish your team great success in the future.
      
      Let's keep working the way we are working!`,
      name:"Pankaj",
    },
    {
      content:`Just to say how proud I am to be associated in partnership with such a fine firm, Conative IT Solutions.

      What Conative IT Solutions and the team has done is unbelievable. It is what I call magnificent and the work they have done is so satisfying.
      
      Special thanks to Jitendra and the entire Conative team for building my business NTI in the way I wanted to be.`,
      name:"Rich",
    },
  ]

  useEffect(() => {
    fetch(backendurl + "cp-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "cpi-show")
      .then((res) => res.json())
      .then((item) => setItem(item));

    fetch(backendurl + "testimonial-show")
      .then((resslider) => resslider.json())
      .then((slider) => setSlider(slider));
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [videourl, setVideoUrl] = useState("");
  const open_modal = (val) => {
    var video_url = "/assets/imgupload/" + val;
    setVideoUrl(video_url);
    setOpen(true);
  };
  return (
    <>
      <section className="view-more-wr" scroll-color="black">
        <div className="center-wrapper">
          <div className="view-more-blk wow fadeInDown">
            <h4>
              <Link to={slider?.length > 0 ? "/website-portfolio" : "/"} >
                <span data-content="View More">View More</span>
              </Link>
            </h4>
          </div>
        </div>
      </section>
      <section
        className="our-client-wr"
        id="our-client-wr"
        scroll-color="white"
      >
        <div className="center-wrapper">
          <Analytics_Counter />
       
            <div className="testimonial-area">
              <div className="testimonial-head-blk">
                <div className="client-say-block">
                  <div className="quote-icon-img wow fadeInLeft">
                    <img
                      src="./assets/images/testimonials_quotes_icon.svg"
                      alt="Quote Icon Imagedata"
                    />
                  </div>
                  {data.name ? (
                    <div className="client-say-content wow fadeInRight">
                      <p dangerouslySetInnerHTML={{ __html: data.name }}></p>
                    </div>
                  ) : (
                    <div className="client-say-content wow fadeInRight">
                    <p>"Our Client's <strong>SATISFACTION</strong> is Conative <strong>SUCCESS</strong>."</p>
                  </div>
                  )}
                </div>
                <div className="testimonial-head">
                  <h3>Testimonials</h3>
                </div>
              </div>

              <div className="testimonial-widget-area">
                <div className="testimonial-slider">
                  <Swiper
                    modules={[Navigation, Scrollbar, A11y]}
                    slidesPerView={1}
                    navigation={{ clickable: true }}
                    autoHeight={true}
                    scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => 
                    onSlideChange={() => {}}
                    className="testimonial-slider"
                  >
                    { slider?.length > 0 ? [...slider]?.reverse()?.map((post, index) => {
                    
                        return post.show_homepage === "true" ? (
                        <SwiperSlide key={post._id}>
                          <div
                            className="testimonial-slide"
                            data-index={index}
                            key={post._id}
                          >
                            <div className="testimonial-content-area clearfix">
                              <div className="testimonial-media-blk left">
                                <div className="video-layers">
                                  <figure
                                    className="video-btn"
                                    data-target="youtube"
                                    data-v-id={
                                      "./assets/imgupload/" + post.video
                                    }
                                    onClick={() => open_modal(post.video)}
                                  >
                                    <img
                                      src="./assets/images/testimonials_play_btn.svg"
                                      alt="Video Play Btn"
                                    />
                                    <figcaption>
                                      <a href="#">Play Video</a>
                                    </figcaption>
                                  </figure>
                                  <div className="video-circle circle-1"></div>
                                  <div className="video-circle circle-2"></div>
                                  <div className="video-circle circle-3"></div>
                                </div>
                                <div className="testimonial-img-blk">
                                  <figure className="testimonial-client-img">
                                    <img
                                      src="../assets/imgupload/2022-12_Testimonial_Image.jpg"
                                      alt="Testimonials Imagedata"
                                    />
                                  </figure>
                                  <div className="box-shadow-box"></div>
                                </div>
                              </div>
                              <div className="testimonial-review-blk right">
                                <div
                                  string-length={post.content.length}
                                  className={
                                    post.content.length > 450
                                      ? "overflow_content"
                                      : ""
                                  }
                                  dangerouslySetInnerHTML={{__html: post.content}}
                                >
                                </div>
                                <div className="round-bg-btn testimonial-caption">
                                  <span data-hover="{post.name}">
                                    {post.name}
                                  </span>
                                  <p>{post.designation}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ) : (
                        ""
                      );
                     
                    })
                    : 
                    hardCodedClientArr?.reverse()?.map((post, index) => 
                    
                      (<SwiperSlide key={post._id}>
                        <div
                          className="testimonial-slide"
                          data-index={index}
                          key={index}
                        >
                          <div className="testimonial-content-area clearfix">
                            <div className="testimonial-media-blk left">
                              <div className="video-layers">
                                <figure
                                  className="video-btn"
                                  data-target="youtube"
                                >
                                  <img
                                    src="./assets/images/testimonials_play_btn.svg"
                                    alt="Video Play Btn"
                                  />
                                  <figcaption>
                                    <a href="#">Play Video</a>
                                  </figcaption>
                                </figure>
                                <div className="video-circle circle-1"></div>
                                <div className="video-circle circle-2"></div>
                                <div className="video-circle circle-3"></div>
                              </div>
                              <div className="testimonial-img-blk">
                                <figure className="testimonial-client-img">
                                  <img
                                    src="../assets/imgupload/2022-12_Testimonial_Image.jpg"
                                    alt="Testimonials Imagedata"
                                  />
                                </figure>
                                <div className="box-shadow-box"></div>
                              </div>
                            </div>
                            <div className="testimonial-review-blk right">
                              <div
                                string-length={post.content.length}
                                className={
                                  post.content.length > 450
                                    ? "overflow_content"
                                    : ""
                                }
                              >
                                <p>{post.content}</p>
                              </div>
                              <div className="round-bg-btn testimonial-caption">
                                <span data-hover="{post.name}">
                                  {post.name}
                                </span>
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>)
                   
                  )
                  }

                  
                  </Swiper>
                </div>
              </div>
              <div className="check-all-testimonial-btn">
                <Link to={slider?.length > 0 ? "/testimonial" : "/"}>View All Feedback</Link>
              </div>
            </div>
       
        </div>
        <Modal
          open={isOpen}
          onClose={() => setOpen(false)}
          center
          classNames={{
            overlay: "testimonial-custom-overlay",
            modal: "testimonial-custom-modal",
          }}
        >
          <ReactPlayer
            url={videourl}
            volume={null}
            muted={false}
            autoPlay={1}
            loop={true}
            controls={true}
            playsinline
            playing={true}
          />
        </Modal>
      </section>
    </>
  );
}

export default ClientDetailsSection;
