import React, { useEffect, useState } from "react";
import COMMON_BANNER from "../common/CommonBanner";
import backendurl from "../constants";
import Meta from "../Meta/Meta";
import { Link } from "react-router-dom";

const GraphicDesign = () => {
  const [data, setData] = useState([]);
  const [alldesigns, setAllDesigns] = useState([]);

  useEffect(() => {
    fetch(backendurl + "graphic-design-content-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "graphicsDesignPost-show")
      .then((response) => response.json())
      .then((alldesigns) => setAllDesigns(alldesigns));
  }, []);
  
  return (
    <>
      <Meta
        title={data?.meta_title}
        desc={data?.meta_desc}
        keywords={data?.meta_keywords}
      />
      <COMMON_BANNER title={data?.title} />
      
        <main>
          <section className="cits-inner-head-content-wr">
            <div className="center-wrapper">
              <div
                className="cits-inner-head-content cits-portfolio-wrapper"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></div>
            </div>
          </section>
          <section className="con-post-wr con-space-wr design-devlop-wr">
            <div className="center-wrapper">
              <div className="con-post-content">
                <div className="post-tabs-blk">
                  <div className="grpahic-sub-cat-wr" id="subcat">
                    <ul className="grpahic-sub-category">
                      {[...alldesigns]?.reverse()?.map((item) => (
                        <li>
                          <a
                            href={"/graphic-portfolio/" + item.slug}
                            data-tab="#logoTab"
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="post-tabs-wr" >
                  {[...alldesigns]?.reverse()?.map((item) => (
                    <div
                      className="post-list-blk wow pulse"
                      data-wow-duration="2s"
                      data-wow-dealy="2s"
                    >
                      <a href={"/graphic-portfolio/" + item.slug}>
                        <div className="list-banner-blk">
                          <img
                            src={"../assets/imgupload/" + item.thumbnail}
                            alt={item.title}
                          />
                        </div>
                        <div className="post-list-head">
                          <div className="post-list-title">
                            <h4>{item.title}</h4>
                          </div>
                          <div className="site-btn site-btn1 show-more">
                            Show More
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      
    </>
  );
};

export default GraphicDesign;
