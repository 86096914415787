import React, { useEffect, useState } from "react";
import Banner from "../common/CommonBanner";
import backendurl from "../constants";
import { useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Meta from "../Meta/Meta";
import CareerPage from "../career/Career";

function Career_details() {
  const [data, setData] = useState(null);

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    contact: "",
    city: "",
    candidate_type: "",
    expected_ctc: "",
    current_company: "",
    current_ctc: "",
  });

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const search = useLocation();
  const [slug, setSlug] = useState("");

  useEffect(()=>{
    const slg = window.location.pathname?.split("/")?.pop();
    setSlug(slg);
  },[window.location.pathname])

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    var spinner = document.getElementById("loading_spinner_wr");
    // spinner.style.display = "block";

    var filename = "";
    var filepath = "";

    var valid = true;

    var career_position = document.getElementById("career_position").value;
    var experience = document.getElementById("experience").value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const file_res = await axios.post(backendurl + "filesUpload", formData);
      
      document.getElementById("upload_file_path").value =
        file_res["data"]["filepath"];
      document.getElementById("upload_file_name").value =
        file_res["data"]["file"];
      filename = file_res["data"]["file"];
      filepath = file_res["data"]["filepath"];
    } catch (ex) {
      
    }

    const {
      name,
      email,
      contact,
      city,
      candidate_type,
      expected_ctc,
      current_company,
      current_ctc,
    } = mailerState;

    var subject = document.getElementById("subject").value;
    var sent_to = document.getElementById("sent_to").value;

    // 

    if (
      name === "" ||
      email === "" ||
      contact === "" ||
      candidate_type === "" ||
      city === ""
    ) {
      swal("", "Please fill all the required* fields!", "info");
      valid = false;
    } else {
      if (candidate_type === "Fresher") {
        if (expected_ctc === "") {
          valid = false;
          swal("", "Please fill all the required* fields!!", "info");
        }
      } else {
        valid = true;
      }
      if (candidate_type === "Experienced") {
        if (current_company === "" || current_ctc === "" || experience === "") {
          valid = false;
          swal("", "Please fill all the required* fields!!!!!", "info");
        }
      }
      
      if (valid === true) {
        spinner.style.display = "block";
        const response = await fetch(backendurl + "sendmail", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            contact,
            city,
            candidate_type,
            expected_ctc,
            current_company,
            current_ctc,
            experience,
            career_position,
            filename,
            filepath,
            subject,
            sent_to,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setMailerState({
              name: "",
              email: "",
              contact: "",
              city: "",
              candidate_type: "",
              career_position: "",
              expected_ctc: "",
              current_company: "",
              current_ctc: "",
              experience: "",
            });
            document.getElementById("apply_now").reset();
            spinner.style.display = "none";
            // 
            var res_message = data["message"];
            if (data["code"] == "success") {
              swal({
                text: `${res_message}`,
                icon: "success",
              }).then((value) => {
                window.location.reload();
              });
            } else {
              swal({
                text: `${res_message}`,
                icon: "info",
              }).then((value) => {
                window.location.reload();
              });
            }
          });
      }
    }
  };

  async function fetchSlugData() {
    try {
      const response = await axios.get(backendurl + "careerpost-single/" + slug);
      setData(response?.data);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    
    if(slug && slug !== "" && slug?.split(" ")?.join()?.length !== 0 && slug !== "career" && slug !== "careers"){
      fetchSlugData();
    } else{
        setData(null)
    }
  }, [slug]);

  return (
    <>
      <Meta
        title={data?.meta_title}
        desc={data?.meta_desc}
        keywords={data?.meta_keywords}
      />
      {/* <Banner title="Career" /> */}
      {data ? (
        <section className="career-business-development-wr">
          <div className="center-wrapper">
            <div className="career-business-development-inner">
              <div className="career-business-development-content clearfix">
                <div className="career-business-development-blk left">
                  <h2>{data?.title}</h2>
                  {data?.content ? (
                    <div className="careers-content-inner">
                      <div
                        className="page-medium-heading conative-common-list"
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="career-apply-now-form right">
                  <div className="career-apply-now-form-wr">
                    <div className="career-apply-form-heading">
                      <h3 className="career-form-heading">Apply Now</h3>
                    </div>
                    <form id="apply_now" data-toggle="validator">
                      <input
                        type="hidden"
                        name="subject"
                        id="subject"
                        value="Career Application"
                      />
                      <input
                        type="hidden"
                        name="upload_file_path"
                        id="upload_file_path"
                      />
                      <input
                        type="hidden"
                        name="upload_file_name"
                        id="upload_file_name"
                      />
                      <input
                        type="hidden"
                        name="sent_to"
                        id="sent_to"
                        value="hr@conative.in"
                      />
                      <div className="form-group career-apply-form-name form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.name}
                          name="name"
                          placeholder="Full Name *"
                          autoComplete="off"
                          data-error="The field is required."
                          pattern="\b\w+\b(?:.*?\b\w+\b){1}"
                          required
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="form-group career-apply-form-email form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.email}
                          name="email"
                          placeholder="Email *"
                          autoComplete="off"
                          pattern="\S+@\S+\.\S+"
                          data-error="The field is required."
                          required
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="form-group career-apply-form-phone form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.contact}
                          name="contact"
                          id="fig-phone"
                          placeholder="Phone *"
                          autoComplete="off"
                          pattern="^\d{3}-\d{3}-\d{4}$"
                          maxlength="12"
                          data-error="The field is required."
                          required
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="form-group career-apply-form-current-city form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.city}
                          name="city"
                          placeholder="Current City *"
                          autoComplete="off"
                          data-error="The field is required."
                          required
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="career-apply-form-radio-btn form-field form-radio-btn">
                        <div className="form-radio-input fresher-blk">
                          <input
                            type="radio"
                            name="candidate_type"
                            value="Fresher"
                            id="fresher"
                            onChange={handleStateChange}
                          />
                          <label htmlFor="fresher">Fresher</label>
                        </div>
                        <div className="form-radio-input experience-blk">
                          <input
                            type="radio"
                            name="candidate_type"
                            value="Experienced"
                            id="experienced"
                            onChange={handleStateChange}
                          />
                          <label htmlFor="experienced">Experience</label>
                        </div>
                      </div>
                      {/* <!-- fresher's form field start--> */}
                      <div className="form-group career-fresher-ctc-blk form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.expected_ctc}
                          name="expected_ctc"
                          placeholder="Expected CTC *"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          autoComplete="off"
                          data-error="Please match the format (e.g: 11.00)."
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      {/* <!-- experience's form field end--> */}
                      <div className="form-group career-apply-form-current-company form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.current_company}
                          name="current_company"
                          placeholder="Current Company *"
                          autoComplete="off"
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="form-group career-apply-form-current-ctc form-field">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={mailerState.current_ctc}
                          name="current_ctc"
                          placeholder="Current CTC *"
                          autoComplete="off"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          data-error="Please match the format (e.g: 11.00)."
                        />
                        <div className="ksh-form-error help-block with-errors"></div>
                      </div>
                      <div className="form-group career-apply-form-experience">
                        <select
                          name="experience"
                          id="experience"
                          className="career-apply-form-select"
                        >
                          <option value="">Experience</option>
                          <option value="3 Months">3 Months</option>
                          <option value="6 Months">6 Months</option>
                          <option value="1 Year">1 Year</option>
                          <option value="2 Years">2 Years</option>
                          <option value="3 Years">3 Years</option>
                          <option value="4 Years">4 Years</option>
                          <option value="5 Years">5 Years</option>
                          <option value="More than 5 years">
                            More than 5 years
                          </option>
                        </select>
                      </div>
                      {/* <!-- fresher's form field start--> */}
                      <div className="career-fresher-position form-field">
                        <input
                          type="text"
                          id="career_position"
                          name="career_position"
                          value={data?.title}
                          readOnly
                        />
                      </div>
                      {/* <!-- fresher's form field end--> */}
                      <div className="career-apply-form-upload-resume">
                        <label className="career-file-con">
                          <input
                            type="file"
                            name="upload-resume"
                            accept="application/pdf"
                            onChange={saveFile}
                          />
                          <span className="upload-file-value">
                            Upload Resume
                          </span>
                        </label>
                      </div>
                      <div className="career-apply-form-btn form-submit-btn input">
                        <input
                          type="submit"
                          name="Submit"
                          value="Submit"
                          onClick={submitEmail}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <CareerPage />
      )}
    </>
  );
}

export default Career_details;
