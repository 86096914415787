import React from "react";
import { Helmet } from "react-helmet";
const Meta = (props) => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.desc} />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.desc} />
        <meta name="keywords" content={props.keywords} />
        <meta property="og:title" content={props.title} />
        <meta property="og:site_name" content="Conative IT Solutions" />
        <meta property="og:url" content="https://conativeitsolutions.com" />
        <meta property="og:description" content={props.desc} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://conativeitsolutions.com/assets/imgupload/2022-11_Logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="conativeitsolutions.com" />
        <meta
          property="twitter:url"
          content="https://conativeitsolutions.com/"
        />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.desc} />
        <meta
          name="twitter:image"
          content="https://conativeitsolutions.com/assets/imgupload/2022-11_Logo.png"
        />
      </Helmet>
    </>
  );
};

export default Meta;
