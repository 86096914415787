import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function IndustriesSection() {
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetch(backendurl + "industries-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "industriesitem-show")
      .then((res) => res.json())
      .then((item) => setItem(item));
  }, []);

  const sliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>

        <section className="industries-wr" scroll-color="black" id="industries_section">
          <div className="center-wrapper">
            <div className="industries-heading">
              <h4 className="common-heading">
                {data.title ? <span>{data.title}</span> : <span>Why Conative?</span> }
                {data.name ? <span>{data.name}</span> : <span>Industries We Serve</span>}
              </h4>
            </div>
            <div className="industries-boxes clearfix">
              {windowWidth <= 767 ? (
                <Slider {...sliderSettings}>
                  {item?.map((post) => (
                    <div className="industries-box left" key={post._id}>
                      <figure>
                        <img src={"/assets/imgupload/" + post.image} alt="fitness-icon" />
                      </figure>
                      <div className="industries-box-heading">
                        <h4>
                          <Link>
                            <span data-hover={post.name}>{post.name}</span>
                          </Link>
                        </h4>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <>
                {item?.length > 0 ? (
                  item.map((post) => (
                    <div className="industries-box left" key={post._id}>
                      <figure>
                        <img src={"/assets/imgupload/" + post.image} alt="fitness-icon" />
                      </figure>
                      <div className="industries-box-heading">
                        <h4>
                            <span data-hover={post.name}>{post.name}</span>
                        </h4>
                      </div>
                    </div>
                  ))
                ) : (
                 <> <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_fitness-icon.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Health &amp; Fitness">Health &amp; Fitness</span></Link></h4></div></div>
                 
                 <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_business.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Business Startups">Business Startups</span></Link></h4></div></div>
                  
                    <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_hotel.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Hotel &amp; Tourism">Hotel &amp; Tourism</span></Link></h4></div></div>
                 
                 <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_manufacturing.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Manufacturing">Manufacturing</span></Link></h4></div></div>
                  
                  <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_real-estate.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Real Estate &amp; Infrastructure">Real Estate &amp; Infrastructure</span></Link></h4></div>
                 
                 </div><div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_legal.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Legal">Legal</span></Link></h4></div></div><div className="industries-box 
                 
                 left">
                  <figure><img src="/assets/imgupload/2022-11_ngo.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="NGO">NGO</span></Link></h4></div></div><div className="industries-box 
                 
                 left">
                  <figure><img src="/assets/imgupload/2022-11_education.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Education">Education</span></Link></h4></div></div><div className="industries-box 
                 
                 left">
                  <figure><img src="/assets/imgupload/2022-11_investing.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Investing &amp; Trading">Investing &amp; Trading</span></Link></h4></div></div>
                 
                 <div className="industries-box left">
                  <figure><img src="/assets/imgupload/2022-11_event-management.svg" alt="fitness-icon"/></figure>
                  <div className="industries-box-heading"><h4><Link><span data-hover="Event Management">Event Management</span></Link></h4></div></div></>
                )}
                </>
              )}


            </div>
          </div>
        </section>
    
    </>
  );
}

export default IndustriesSection;
