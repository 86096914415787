import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';


function YoutubeCarouselLatestHeader() {
	const [data, setData] = useState(null);
	const [pathname, setPathname] = useState("")
	
	useEffect(()=>{
		setPathname(window.location.pathname);
	},[window.location.pathname]);


	return (
			<div style={{width:"100%", backgroundColor:"#fbfbfb"}}>	
			<div className="center-wrapper-header">
			<header>
				<div className="header-area clearfix" style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>

					{data?.image ? (
						<div className="logo-block left">
							<Link to="/apps" className="non-sticky">
							<div className="header-confetti-left"> </div>
								<img
									src={"../assets/imgupload/Play_Logo.png"}
									alt="Conative It Solutions Logo"
									width={100}
								/>
							<div className="header-confetti-right" > </div>

							</Link>
							<Link to="/apps" className="sticky">
								<img src="../assets/imgupload/Play_Logo.png" alt="Conative It Solutions Logo" />
							</Link>
						</div>
					) : (
						<div className="logo-block  left">
						<Link to="/apps" className="non-sticky" style={{width:"100%"}}>
							
							<img
								src={"../assets/imgupload/Play_Logo.png"}
								alt="Conative It Solutions Logo"
								width={80}
							/>
						</Link>
						<Link href="/apps" className="sticky">
							<img src={"../assets/imgupload/Play_Logo.png"} alt="Conative It Solutions Logo" />
						</Link>
					</div>
					)}

		
					<div className="header-contact-content right">
						<div className="contact-blk">
							
							{data?.email ? (
								<Link
									to={"mailto:" + data?.email}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/header_mail_icon.svg"
										alt="header mail icon"
									/>
									<span data-content={data?.email}>{data?.email}</span>
								</Link>
							) : (
								<Link
									to={"mailto:" + "info@conative.in"}
									className="circle-hover-effect-email"
								>
									<div className="hover-circle-email"></div>
									<img
										src="../assets/images/header_mail_icon.svg"
										alt="header mail icon"
									/>
									<span>
									info@conative.in
									</span>
								</Link>
							)}
							{data?.phone ? (
								<Link
									to={"tel:" + data?.phone}
									className="circle-hover-effect-call"
								>
									<div className="hover-circle-call"></div>
									<img
										src="../assets/images/header_whatsapp_icon.svg"
										alt="header mail icon"
									/>
									<span data-content={data?.phone}>{data?.phone}</span>
								</Link>
							) : (
								<Link
								to={"tel:" + "+91 82697 88173"}
								className="circle-hover-effect-call"
							>
								<div className="hover-circle-call"></div>
								<img
									src="../assets/images/header_whatsapp_icon.svg"
									alt="header mail icon"
								/>
								<span>+91-82697 88173</span>	
							</Link>
							)}
						</div>
						
						<div className="our-work-blk">
						{data?.phone ? 
								pathname && pathname.includes("youtube-latest-carousel-privacy-policy") ? (
									<Link
										to="/apps"
										className="site-btn site-btn1"
									>
										<span>Home Page</span> <i className="fas fa-arrow-up"></i>
									</Link>
								) : (
									<Link
										to="/apps/youtube-latest-carousel-privacy-policy"
										className="site-btn site-btn1"
									>
										<span>Privacy Policy</span> <i className="fas fa-arrow-up"></i>
									</Link>
								)
							:
							pathname && pathname.includes("youtube-latest-carousel-privacy-policy") ? (
								<Link
									to="/apps"
									className="site-btn site-btn1"
								>
									<span>Home Page</span> <i className="fas fa-arrow-up"></i>
								</Link>
							) : (
								<Link
									to="/apps/youtube-latest-carousel-privacy-policy"
									className="site-btn site-btn1"
								>
									<span>Privacy Policy</span> <i className="fas fa-arrow-up"></i>
								</Link>
							)
							}
						</div>
					</div>
				</div>
			</header>
			</div>
		</div>
	);
}

export default YoutubeCarouselLatestHeader;
