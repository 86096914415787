import React, { useEffect, useState } from "react";
import COMMON_BANNER from "../common/CommonBanner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import backendurl from "../constants";
import { Link, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
const IndustriesWeServe = () => {
  const [data, setData] = useState([]);
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    contact: "",
    start_project: "",
    message: "",
  });

  const search = useLocation();
  const slug = search["pathname"].split("/").pop();

  useEffect(() => {
    fetch(backendurl + "industries-single/" + slug)
      .then((response) => response.json())
      .then((data) => setData(data));
  }, [slug]);

  // if (data == "") {
  //   return <Redirect to="/website-design-portfolio" />;
  // }

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    const { name, email, contact, start_project, message } = mailerState;

    // var start_project = document.getElementsByName("start_project");

    

    const response = await fetch(backendurl + "sendmail", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        contact,
        message,
        start_project,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setMailerState({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
        document.getElementById("industries_form").reset();
      });
  };

  return (
    <>
      <COMMON_BANNER title={data.title} />
      {data ? (
        <main>
          {data.banner_title != "" && data.banner_desc != "" ? (
            <section className="banner-img-wr">
              {data.banner_main_image_name != "" ? (
                <div className="banner-img-blk">
                  <img
                    src={"../assets/imgupload/" + data.banner_main_image_name}
                    alt={data.title}
                  />
                </div>
              ) : (
                ""
              )}
              {data.banner_second_image_name != "" ? (
                <div className="mockup-banner-img">
                  <img
                    src={"../assets/imgupload/" + data.banner_second_image_name}
                    alt="Diet Banner"
                  />
                </div>
              ) : (
                ""
              )}
              <div className="center-wrapper">
                <div className="banner-img-overlay">
                  {data.banner_title !== "" ? (
                    <div className="con-head-blk">
                      <h2>{data.banner_title}</h2>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.banner_desc !== "" ? (
                    <div className="con-page-content">
                      {/* <p>{data.banner_desc}</p> */}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="banner-btn-blk">
                    <Link
                      to={data.view_project_url}
                      className="common-round-btn common-round-btn2"
                    >
                      View Project
                    </Link>
                    <div className="video-layers">
                      <figure
                        className="video-btn"
                        data-target="youtube"
                        data-v-id="https://www.youtube.com/embed/YsdR0i_DQgI"
                      >
                        <img
                          src="../assets/images/testimonials_play_btn.svg"
                          alt="Video Play site-btn"
                        />
                      </figure>
                      <div className="video-circle circle-1"></div>
                      <div className="video-circle circle-2"></div>
                      <div className="video-circle circle-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {data.content != "" ? (
            <section className="con-industries-wr">
              <div className="center-wrapper">
                <div
                  className="con-head-blk common-heading"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
                <div className="con-icon-title-wr clearfix">
                  {data.dimension_title_one !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_one_name
                          }
                          alt={data.dimension_title_one}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_one}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_two !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_two_name
                          }
                          alt={data.dimension_title_two}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_two}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_three !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_three_name
                          }
                          alt={data.dimension_title_three}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_three}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_four !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_four_name
                          }
                          alt={data.dimension_title_four}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_four}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_five !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_five_name
                          }
                          alt={data.dimension_title_five}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_five}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_six !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_six_name
                          }
                          alt={data.dimension_title_six}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_six}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_seven !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_seven_name
                          }
                          alt={data.dimension_title_seven}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_seven}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.dimension_title_eight !== "" ? (
                    <div className="con-icon-title-blk triangle">
                      <div className="con-icon-box">
                        <img
                          src={
                            "../assets/imgupload/" +
                            data.dimension_image_eight_name
                          }
                          alt={data.dimension_title_eight}
                        />
                      </div>
                      <div className="con-head-blk">
                        <h4>{data.dimension_title_eight}</h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {data.brand_image_one_name !== "" ||
          data.brand_image_two_name !== "" ||
          data.brand_image_three_name !== "" ? (
            <section className="company-branding-wr con-space-wr ">
              <div className="center-wrapper">
                <div className="common-heading">
                  <h3>
                    {data.brand_title !== "" ? (
                      <span>{data.brand_title}</span>
                    ) : (
                      ""
                    )}
                    {data.brand_heading !== "" ? (
                      <span>{data.brand_heading}</span>
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
                <div className="company-branding-blk">
                  <div className="testimonial-head">
                    <h3>Branding</h3>
                  </div>
                  {data.brand_image_one_name !== "" ? (
                    <div className="company-branding-img">
                      <img
                        src={"../assets/imgupload/" + data.brand_image_one_name}
                        alt=""
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          <section className="con-technologies-wr">
            <div
              className="con-technologies-banner"
              style={{
                background: `url(${
                  "../assets/imgupload/" + data.our_technology_image_name
                }) no-repeat
                center/cover`,
              }}
            >
              <div className="center-wrapper">
                <div className="con-head-blk">
                  <h3>{data.our_technology_title}</h3>
                </div>
              </div>
            </div>
            <div className="con-techn-content-wr">
              <div className="design-sol-tabber-blk">
                <div className="center-wrapper">
                  <ul className="design-sol-tabber-link tabber">
                    <li>
                      <Link to="/" data-tab="#coreTechTabOne">
                        Toolkit
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/" data-tab="#coreTechTabTwo">
                        Programming Languages
                      </Link>
                    </li>
                    <li>
                      <Link to="/" data-tab="#coreTechTabThree">
                        SDK
                      </Link>
                    </li> */}
                  </ul>
                  <div
                    className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                    id="coreTechTabOne"
                  >
                    {data.toolkit_image_one_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.toolkit_image_one_name
                            }
                            alt={data.toolkit_title_one}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.toolkit_title_one}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.toolkit_image_two_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.toolkit_image_two_name
                            }
                            alt={data.toolkit_title_two}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.toolkit_title_two}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.toolkit_image_three_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.toolkit_image_three_name
                            }
                            alt={data.toolkit_title_three}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.toolkit_title_three}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div
                    className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                    id="coreTechTabTwo"
                  >
                    {data.language_image_one_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.language_image_one_name
                            }
                            alt={data.language_title_one}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.language_title_one}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.language_image_two_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.language_image_two_name
                            }
                            alt={data.language_title_two}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.language_title_two}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.language_image_three_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" +
                              data.language_image_three_name
                            }
                            alt={data.language_title_three}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.language_title_three}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="design-sol-tabs con-tect-tab clearfix tabs tab-card"
                    id="coreTechTabThree"
                  >
                    {data.sdk_image_one_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" + data.sdk_image_one_name
                            }
                            alt={data.sdk_title_one}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.sdk_title_one}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.sdk_image_two_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" + data.sdk_image_two_name
                            }
                            alt={data.sdk_title_two}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.sdk_title_two}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {data.sdk_image_three_name !== "" ? (
                      <div className="con-icon-title-blk">
                        <div className="con-icon-box">
                          <img
                            src={
                              "../assets/imgupload/" + data.sdk_image_three_name
                            }
                            alt={data.sdk_title_three}
                          />
                        </div>
                        <div className="con-head-blk">
                          <h4>{data.sdk_title_three}</h4>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
              </div>
              <div className="industries-enquiry-form-blk clearfix">
                <div className="center-wrapper">
                  <div className="industries-left-blk left">
                    <div className="inquiry-form-title common-heading">
                      <h3>
                        {data.ask_us_title !== "" ? (
                          <span>{data.ask_us_title}</span>
                        ) : (
                          ""
                        )}
                        {data.ask_us_heading !== "" ? (
                          <span>{data.ask_us_heading}</span>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                    <div className="industries-form-blk">
                      <form id="industries_form">
                        <div className="enquiry-form-name-email clearfix">
                          <div className="enquiry-form-name form-field left">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name *"
                              autoComplete="off"
                              onChange={handleStateChange}
                              value={mailerState.name}
                            />
                          </div>
                          <div className="enquiry-form-email form-field right">
                            <input
                              type="text"
                              name="email"
                              placeholder="Work Email *"
                              autoComplete="off"
                              onChange={handleStateChange}
                              value={mailerState.email}
                            />
                          </div>
                        </div>
                        <div className="enquiry-form-contact form-field">
                          <input
                            type="text"
                            name="contact"
                            placeholder="Contact No.*"
                            autoComplete="off"
                            onChange={handleStateChange}
                            value={mailerState.contact}
                          />
                        </div>
                        {/* <div className="enquiry-form-start-project">
                          <h4>How soon do you want to start the project?*</h4>
                          <div className="form-radio-btn">
                            <div className="form-radio-input">
                              <input
                                type="radio"
                                name="start_project"
                                value="Within 8 Weeks"
                                id="within-8-weeks"
                                onChange={handleStateChange}
                              />
                              <label htmlFor="within-8-weeks">
                                Within 8 Weeks
                              </label>
                            </div>
                            <div className="form-radio-input">
                              <input
                                type="radio"
                                name="start_project"
                                value="More than 8 Weeks"
                                id="more-than-8-weeks"
                                onChange={handleStateChange}
                              />
                              <label htmlFor="more-than-8-weeks">
                                More than 8 Weeks
                              </label>
                            </div>
                          </div>
                        </div> */}
                        <div className="enquiry-form-requirements">
                          <h4>
                            Share your requirements*
                            <span>(minimum 250 characters)</span>
                          </h4>
                          <p>
                            (You can add links to your shareable material if
                            any)
                          </p>
                          <div className="form-textarea form-field">
                            <textarea
                              rows="2"
                              name="message"
                              onChange={handleStateChange}
                              value={mailerState.message}
                            ></textarea>
                          </div>
                        </div>
                        <div className="enquiry-form-upload-field form-field">
                          <div className="form-upload-field">
                            <input
                              type="file"
                              name="uploadDocument"
                              id="upload_indusrties_document"
                            />
                            <span>Drag &amp; Drop your file</span>
                          </div>
                        </div>
                        <div className="form-submit-btn">
                          <input
                            name="submit"
                            value="Submit enquiry"
                            onClick={submitEmail}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="industries-right-blk right">
                    <div className="background-text-wr">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: data.ask_us_bg_text,
                        }}
                      ></h3>
                    </div>
                    {data.ask_us_image_name ? (
                      <div className="industries-right-img">
                        <img
                          src={"../assets/imgupload/" + data.ask_us_image_name}
                          alt="Fitness Website"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="industries-slider-wr">
                <div className="center-wrapper">
                  <div className="inquiry-form-title common-heading">
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: data.industries_title,
                      }}
                    ></h3>
                  </div>
                  <div className="industries-slider">
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={4}
                      // slidesPerGroupSkip={4}
                      spaceBetween={50}
                      loop={true}
                      // loopedSlidesLimit={false}
                      navigation={{ clickable: true }}
                      className="industries-slider-inner"
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        767: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },

                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                      }}
                    >
                      <SwiperSlide>
                        {data.industries_image_one_name !== "" ? (
                          <div className="industries-slide">
                            <div className="slide-inner-space">
                              <div className="con-icon-box">
                                <img
                                  src={
                                    "../assets/imgupload/" +
                                    data.industries_image_one_name
                                  }
                                  alt={data.industries_title_one}
                                />
                              </div>
                              <div className="con-head-blk">
                                <h4>{data.industries_title_one}</h4>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                      <SwiperSlide>
                        {data.industries_image_two_name !== "" ? (
                          <div className="industries-slide">
                            <div className="slide-inner-space">
                              <div className="con-icon-box">
                                <img
                                  src={
                                    "../assets/imgupload/" +
                                    data.industries_image_two_name
                                  }
                                  alt={data.industries_title_two}
                                />
                              </div>
                              <div className="con-head-blk">
                                <h4>{data.industries_title_two}</h4>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                      <SwiperSlide>
                        {data.industries_image_three_name !== "" ? (
                          <div className="industries-slide">
                            <div className="slide-inner-space">
                              <div className="con-icon-box">
                                <img
                                  src={
                                    "../assets/imgupload/" +
                                    data.industries_image_three_name
                                  }
                                  alt={data.industries_title_three}
                                />
                              </div>
                              <div className="con-head-blk">
                                <h4>{data.industries_title_three}</h4>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                      <SwiperSlide>
                        {data.industries_image_four_name !== "" ? (
                          <div className="industries-slide">
                            <div className="slide-inner-space">
                              <div className="con-icon-box">
                                <img
                                  src={
                                    "../assets/imgupload/" +
                                    data.industries_image_four_name
                                  }
                                  alt={data.industries_title_four}
                                />
                              </div>
                              <div className="con-head-blk">
                                <h4>{data.industries_title_four}</h4>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        ""
      )}
    </>
  );
};

export default IndustriesWeServe;
