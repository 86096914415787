import React, { useEffect, useState } from "react";
import Banner from "../common/CommonBanner";
import backendurl from "../constants";
import { useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const CareerPopup = () => {
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    contact: "",
    city: "",
    candidate_type: "",
    expected_ctc: "",
    current_company: "",
    current_ctc: "",
  });

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    var spinner = document.getElementById("loading_spinner_wr");
    // spinner.style.display = "block";

    var filename = "";
    var filepath = "";

    var valid = true;

    var career_position = document.getElementById("career_position").value;
    var experience = document.getElementById("experience").value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const file_res = await axios.post(backendurl + "filesUpload", formData);
      
      document.getElementById("upload_file_path").value =
        file_res["data"]["filepath"];
      document.getElementById("upload_file_name").value =
        file_res["data"]["file"];
      filename = file_res["data"]["file"];
      filepath = file_res["data"]["filepath"];
    } catch (ex) {
      
    }

    const {
      name,
      email,
      contact,
      city,
      candidate_type,
      expected_ctc,
      current_company,
      current_ctc,
    } = mailerState;

    var subject = document.getElementById("subject").value;
    var sent_to = document.getElementById("sent_to").value;

    // 

    if (
      name === "" ||
      email === "" ||
      contact === "" ||
      candidate_type === "" ||
      city === ""
    ) {
      swal("", "Please fill all the required* fields!", "info");
      valid = false;
    } else {
      if (candidate_type === "Fresher") {
        if (expected_ctc === "") {
          valid = false;
          swal("", "Please fill all the required* fields!!", "info");
        }
      } else {
        valid = true;
      }
      if (candidate_type === "Experienced") {
        if (current_company === "" || current_ctc === "" || experience === "") {
          valid = false;
          swal("", "Please fill all the required* fields!!!!!", "info");
        }
      }
      
      if (valid === true) {
        spinner.style.display = "block";
        const response = await fetch(backendurl + "sendmail", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            contact,
            city,
            candidate_type,
            expected_ctc,
            current_company,
            current_ctc,
            experience,
            career_position,
            filename,
            filepath,
            subject,
            sent_to,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setMailerState({
              name: "",
              email: "",
              contact: "",
              city: "",
              candidate_type: "",
              career_position: "",
              expected_ctc: "",
              current_company: "",
              current_ctc: "",
              experience: "",
            });
            document.getElementById("career_apply_now").reset();
            spinner.style.display = "none";
            // 
            var res_message = data["message"];
            if (data["code"] == "success") {
              swal({
                text: `${res_message}`,
                icon: "success",
              }).then((value) => {
                window.location.reload();
              });
            } else {
              swal({
                text: `${res_message}`,
                icon: "info",
              }).then((value) => {
                window.location.reload();
              });
            }
          });
      }
    }
  };

  return (
    <>
      <div className="build-career-popup-wrapper">
        <div className="form-cross-btn">
          <p>✕</p>
        </div>
        <div className="form-top-heading">
          <figure>
            <img
              src="../assets/images/build_career_icon.svg"
              alt="form-heading-icon"
            />
          </figure>
          <div className="form-heading">
            <h4>Build Your Career</h4>
          </div>
        </div>
        <div className="career-popup-form">
          <form id="career_apply_now" data-toggle="validator">
            <input
              type="hidden"
              name="subject"
              id="subject"
              value="Career Application"
            />
            <input
              type="hidden"
              name="upload_file_path"
              id="upload_file_path"
            />
            <input
              type="hidden"
              name="upload_file_name"
              id="upload_file_name"
            />
            <input
              type="hidden"
              name="sent_to"
              id="sent_to"
              value="hr@conative.in"
            />
            <div className="form-group clearfix">
              <div className="form-field half-form-field left">
                <input
                  type="text"
                  name="name"
                  onChange={handleStateChange}
                  value={mailerState.name}
                  placeholder="Full Name *"
                  autoComplete="off"
                  data-error="The field is required."
                  pattern="\b\w+\b(?:.*?\b\w+\b){1}"
                  required
                />
                <div className="ksh-form-error help-block with-errors"></div>
              </div>
              <div className="form-field  half-form-field right">
                <input
                  type="text"
                  name="email"
                  onChange={handleStateChange}
                  value={mailerState.email}
                  placeholder="Email *"
                  autoComplete="off"
                  pattern="\S+@\S+\.\S+"
                  data-error="The field is required."
                  required
                />
                <div className="ksh-form-error help-block with-errors"></div>
              </div>
            </div>
            <div className="form-group clearfix">
              <div className="form-field  half-form-field left">
                <input
                  type="text"
                  name="contact"
                  id="fig-phone"
                  onChange={handleStateChange}
                  value={mailerState.contact}
                  placeholder="Phone *"
                  autoComplete="off"
                  pattern="^\d{3}-\d{3}-\d{4}$"
                  maxLength="12"
                  data-error="The field is required."
                  required
                />
                <div className="ksh-form-error help-block with-errors"></div>
              </div>
              <div className="form-field  half-form-field right">
                <input
                  type="text"
                  name="city"
                  onChange={handleStateChange}
                  value={mailerState.city}
                  placeholder="Current City *"
                  autoComplete="off"
                  data-error="The field is required."
                  required
                />
                <div className="ksh-form-error help-block with-errors"></div>
              </div>
            </div>
            <div className="career-apply-form-radio-btn form-field form-radio-btn clearfix">
              <div className="form-radio-input fresher-blk half-form-field left">
                <input
                  type="radio"
                  name="candidate_type"
                  value="Fresher"
                  onChange={handleStateChange}
                  id="fresher"
                />
                <label htmlFor="fresher">Fresher</label>
              </div>
              <div className="form-radio-input experience-blk half-form-field right">
                <input
                  type="radio"
                  name="candidate_type"
                  value="Experienced"
                  id="experienced"
                  onChange={handleStateChange}
                />
                <label htmlFor="experienced">Experience</label>
              </div>
            </div>
            {/* <!-- fresher's form field start--> */}
            <div className="form-group career-fresher-ctc-blk form-field">
              <input
                type="text"
                name="expected_ctc"
                onChange={handleStateChange}
                value={mailerState.expected_ctc}
                placeholder="Expected CTC *"
                pattern="[0-9]+([\.,][0-9]+)?"
                autoComplete="off"
                data-error="Please match the format (e.g: 11.00)."
              />
              <div className="ksh-form-error help-block with-errors"></div>
            </div>
            {/* <!-- experience's form field end--> */}
            <div className="form-group career-apply-form-current-company form-field">
              <input
                type="text"
                name="current_company"
                onChange={handleStateChange}
                value={mailerState.current_company}
                placeholder="Current Company *"
                autoComplete="off"
              />
              <div className="ksh-form-error help-block with-errors"></div>
            </div>
            <div className="form-group career-apply-form-current-ctc form-field">
              <input
                type="text"
                name="current_ctc"
                onChange={handleStateChange}
                value={mailerState.current_ctc}
                placeholder="Current CTC *"
                autoComplete="off"
                pattern="[0-9]+([\.,][0-9]+)?"
                data-error="Please match the format (e.g: 11.00)."
              />
              <div className="ksh-form-error help-block with-errors"></div>
            </div>
            <div className="form-field career-select-field career-experience-select-field">
              <select
                name="experience"
                id="experience"
                className="career-apply-form-select"
              >
                <option value="">Experience</option>
                <option value="3 Months">3 Months</option>
                <option value="6 Months">6 Months</option>
                <option value="1 Year">1 Year</option>
                <option value="2 Years">2 Years</option>
                <option value="3 Years">3 Years</option>
                <option value="4 Years">4 Years</option>
                <option value="5 Years">5 Years</option>
                <option value="More than 5 years">More than 5 years</option>
              </select>
            </div>
            {/* <!-- fresher's form field start--> */}
            <div className="form-field form-field career-select-field">
              <select
                name="career_position"
                id="career_position"
                className="position-select"
              >
                <option value="">Web Developer</option>
                <option value="Graphic Designer">Graphic Designer</option>
                <option value="Business Development Executive">
                  Business Development Executive
                </option>
                <option value="Digital Marketing & Content Solutions">
                  Digital Marketing & Content Solutions
                </option>
                <option value="Project Manager">Project Manager</option>
                <option value="Human Resource">Human Resource</option>
                <option value="Web Designer">Web Designer</option>
                <option value="Motion Graphics Designer">
                  Motion Graphics Designer
                </option>
                <option value="UI/UX Designer">UI/UX Designer</option>
              </select>
            </div>
            {/* <!-- fresher's form field end--> */}
            <div className="career-apply-form-upload-resume form-upload-field">
              <label className="career-file-con">
                <input
                  type="file"
                  name="upload-resume"
                  accept="application/pdf"
                  onChange={saveFile}
                />
                <span className="upload-file-value">Upload Resume</span>
              </label>
            </div>
            <div className="form-submit-btn">
              <input
                type="submit"
                name="Submit"
                value="Submit"
                onClick={submitEmail}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CareerPopup;
