import React, { useEffect, useState } from "react";
import COMMON_BANNER from "../common/CommonBanner";
import backendurl from "../constants";
import Meta from "../Meta/Meta";
import { Link, useLocation } from "react-router-dom";


const Portfolio = () => {
	const [data, setData] = useState([]);
	const location = useLocation();
	const [webDevelopment, setWebDevelopmentData] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [slug, setSlug] = useState("");


	useEffect(() => {
		setSlug(location.pathname.split('/').pop());
		
	}, [window.location.pathname])

	useEffect(() => {
		fetch(backendurl + "website-design-content-show")
			.then((response) => response.json())
			.then((data) => setData(data));

		fetch(backendurl + "web-development-post-show")
			.then((res) => res.json())
			.then((data) => setWebDevelopmentData(data?.reverse()));
	}, [slug]);

	useEffect(() => {
		if (slug === 'website-portfolio') {
			setSelectedData(prev => ({ data: data, webDevelopment: webDevelopment }));
		} else {
			let selectedWebDev = webDevelopment.filter(item => (item.slug === slug))[0];
			setSelectedData(prev => ({
				data: {
					meta_title: selectedWebDev?.meta_title,
					meta_desc: selectedWebDev?.meta_desc,
					meta_keywords: selectedWebDev?.meta_keywords,
					title: selectedWebDev?.title,
					content: selectedWebDev?.post_content
				}, webDevelopment: [selectedWebDev]
			}))
		}
	}, [location.pathname, data?.length, webDevelopment?.length, slug])

	const offlineWebDevImgArr = [
		{ item: "2023-3_Axiom_Homepage.png" },
		{ item: "2023-3_Black_Corner_Homepage.png" },
		{ item: "2023-3_Corporate_Website_Accord_Portfolio.png" },
		{ item: "2023-3_Frizzy_Pint_Homepage.png"},
		{ item: "2023-3_Eon_Solutions_Homepage.png"},
		{ item: "2023-3_Edific_Builder_Homepage.png"},
		{ item: "2023-3_Metro_Taxi_Homepage_1.png"},
		{ item: "2023-3_Medwin_Care.png"},
		{ item: "2023-3_Mocha_Homepage.png"},
		{ item: "2023-3_Minime_Homepage.png"},
		{ item: "2023-3_Western_Wood_Homepage.png"},
		{ item: "2023-3_Platinum_Properties_HomePage.png"},
		{ item: "2023-3_Moon_Walk_Homepage.png"},
		{ item: "2023-5_Fav_Holidays_Web.png"},
		{ item: "2023-5_screencapture-viraanshagritech-2023-05-11-14_06_59.png"}

	]

	

	return (
		<>
			<Meta
				title={selectedData?.data?.meta_title}
				desc={selectedData?.data?.meta_desc}
				keywords={selectedData?.data?.meta_keywords}
			/>
			<COMMON_BANNER title={selectedData?.data?.title || "Website Design & Development"} />
			{webDevelopment ? (
				<main>
					<section className="cits-inner-head-content-wr">
						<div className="center-wrapper">
							{selectedData?.data?.content ? (

								<div
									className="cits-inner-head-content cits-portfolio-wrapper"
									dangerouslySetInnerHTML={{ __html: selectedData?.data?.content }}
								></div>
							) : (
								<p><strong>Website design and development</strong> aim to create a functional and visually appealing website. It involves a number of stages, including <strong>planning, designing, coding and testing.</strong> Design encompasses layout, color schemes, graphics and user experience (UX) considerations. The design is brought to life with the help of programming languages such as <strong>HTML, CSS and JavaScript.</strong> It also includes implementing features such as navigation menus, forms and interactive elements. Our goal at <Link to="https://conativeitsolutions.com/"><span style={{ color: "#f84525" }}>Conative IT Solutions</span></Link> is to design an appealing, user-friendly, responsive and search engine-optimized website that accomplishes a goal. Our well-designed and developed website helps businesses establish their online presence, attract visitors, engage users and achieve their goals, whether it's generating leads, driving sales, or sharing information.</p>

							)}
						</div>
					</section>
					<section className="con-post-wr con-space-wr design-devlop-wr">
						<div className="center-wrapper">
							<div className="con-post-content">
								<div className="post-tabs-blk">
									<div className="grpahic-sub-cat-wr" id="subcat">
										<ul className="grpahic-sub-category">
											{webDevelopment?.length > 0 && webDevelopment?.map((item) => {
												return (
													<li key={item.slug}>
														<Link
															className={item?.slug === slug ? 'active' : ''}
															to={"/website-portfolio/" + item.slug}
															data-tab="#logoTab"
														>
															{item.title}
														</Link>
													</li>
												);
											})
											}
										</ul>
									</div>
								</div>
								<div className="post-tabs-wr tabs clearfix" id="gridTab">
									{(webDevelopment?.length > 0 && selectedData?.webDevelopment?.length > 0) ? selectedData?.webDevelopment?.map((webDevelopmentItem, index) => {
										return (
											<>
												{
													webDevelopmentItem?.images?.map(item => (
														<div
															key={item}
															className="post-grid-blk wow pulse"
															data-wow-duration="2s"
															data-wow-dealy="2s"
														>
															<a>
																<img
																	src={"/assets/imgupload/" + item}
																	alt=""
																/>
															</a>
														</div>
													))
												}
											</>
										);
									})
										:
										<>
											{
												offlineWebDevImgArr?.map((item, index) => (
													<div
														key={index}
														className="post-grid-blk wow pulse"	
														data-wow-duration="2s"
														data-wow-dealy="2s"
													>
														<Link to>
															<img
																src={"/assets/imgupload/" + item?.item}
																alt=""
															/>
														</Link>
													</div>
												))
											}
										</>
									}
								</div>
							</div>
						</div>
					</section>
				</main>
			) : (
				<span></span>
			)}
		</>

	);
};

export default Portfolio;
