import React, { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Analytics_Counter from "../common/Analytics_Counter";
import Common_Banner from "../common/CommonBanner";
import backendurl from "../constants";
import { Navigation } from "swiper";
import "react-modal-video/scss/modal-video.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Meta from "../Meta/Meta";
import conativeDataService from "../../services/dataService"
import { Link } from "react-router-dom";
import $ from 'jquery';

const About_us = () => {
	const navigate = useNavigate()
	const [data, setData] = useState(null);
	const [techSlide, setTechSlideData] = useState(null);
	const [tourSlide, setTourSlideData] = useState(null);
	const [chooseSlide, setChooseSlideData] = useState(null);
	const [isInitialSlide, setIsInitialSlide] = useState(false)
	const targetRef = React.createRef();

	async function fetchData() {
		try {
			const response = await conativeDataService.getAboutUsContent()
			setData(response?.data?.result);
			const res2 = await conativeDataService.getTechSlideItem();
			setTechSlideData(res2?.data);
			const res3 = await conativeDataService.getTourSlideItem();
			setTourSlideData(res3?.data);
			const res4 = await conativeDataService.getChooseSlideShow();
			setChooseSlideData(res4?.data);
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchData();
		const queryParams = new URLSearchParams(window.location.search);
		const dragSection = queryParams.get('dragSection');
		if (dragSection === 'true') {
			setIsInitialSlide(true);
		} else {
			setIsInitialSlide(false);
		}
		// fetch(backendurl + "techSlideItem-show")
		// 	.then((res) => res.json())
		// 	.then((techSlide) => setTechSlideData(techSlide));

		// fetch(backendurl + "tourSlideItem-show")
		// 	.then((res) => res.json())
		// 	.then((tourSlide) => setTourSlideData(tourSlide.reverse()));

		// fetch(backendurl + "chooseSlide-show")
		// 	.then((res) => res.json())
		// 	.then((chooseSlide) => setChooseSlideData(chooseSlide));
	}, []);


	useLayoutEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const dragSection = queryParams.get('dragSection');

		if (dragSection === 'true') {
			if (targetRef.current) {
				targetRef.current.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [techSlide, tourSlide, chooseSlide]);

	return (
		<>
			<Meta
				title={data?.meta_title}
				desc={data?.meta_desc}
				keywords={data?.meta_keywords}
			/>
			<Common_Banner title="About Us" />
			<section className="con-about-wr con-space-wr">
				<div className="center-wrapper">
					<div className="con-img-title-blk">
						<div
							className="con-about-img fadeIn"
							data-wow-duration="2s"
							data-wow-dealy="2s"
						>
							<img
								src={"../assets/imgupload/" + data?.image}
								alt="Conative It"
							/>
						</div>
						<div className="con-overlay-title common-heading">
							<h2>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									{data?.title}
								</span>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									{data?.heading}
								</span>
							</h2>
						</div>
					</div>
					<div
						className="con-page-content"
						dangerouslySetInnerHTML={{
							__html: data?.content,
						}}
					></div>
				</div>
			</section>


			<section className="con-vison-wr">
				<div className="center-wrapper">
					<div className="ring-wr">
						<div className="ring-1 ring"></div>
						<div className="ring-2 ring"></div>
					</div>
					<div className="ring-wr ring-right-wr">
						<div className="ring-1 ring"></div>
						<div className="ring-2 ring"></div>
					</div>
					<div
						className="con-vison-content common-heading wow fadeInDown"
						data-wow-duration="2s"
						data-wow-dealy="2s"
					>
						<h3>
							<span>{data?.vision_heading}</span>
							{data?.vision_content}
						</h3>
					</div>
				</div>
			</section>

			<section className="con-space-wr servises-mission-wr">
				<div className="center-wrapper">
					<Analytics_Counter />
					<div className="con-inquiry-wr">
						<div className="con-contact-card con-card">
							<h3
								className="wow pulse"
								data-wow-duration="2s"
								data-wow-dealy="2s"
							>
								Looking For Services
							</h3>
						</div>
						<div className="con-card con-inquiry-card">
							<div className="con-card-icon">
								<img
									src="../assets/images/For_Inquiry_Icon.svg"
									alt="Inquiry Icon"
								/>
							</div>
							<div className="con-card-title">
								<h4 className="contact-form-left-heading">
									<span>For Enquiry call now</span>
									<Link to="tel:+91-8269788173">+91-8269788173</Link>
								</h4>
							</div>
						</div>
					</div>
					<div
						className="con-vison-content con-misson-content common-heading wow fadeIn"
						data-wow-duration="2s"
						data-wow-dealy="2s"
					>
						<h3>
							<span>{data?.mission_heading}</span>
							{data?.mission_content}
						</h3>
					</div>
				</div>
			</section>

			<section className="con-choose-wr">
				<div className="center-wrapper">
					<div className="con-head-blk">
						<h2>{data?.choose_heading}</h2>
						<p>{data?.choose_desc}</p>
					</div>
					<div className="con-cards-wr clearfix">
						{chooseSlide?.map((chooseSlideItem) => (
							<div
								className="con-card-blk left wow pulse"
								data-wow-duration="2s"
								data-wow-dealy="2s"
							>
								<div className="con-card-title">
									<h3>{chooseSlideItem.choose_title}</h3>
								</div>
								<div className="con-card-content">
									<p>{chooseSlideItem.choose_content}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			<section className="con-journey-wr" ref={targetRef} id="con-journey-slider-wr-drag"
			>
				<div className="center-wrapper">
					<div className="common-heading con-journey-head">
						<h3>
							<span>{data?.tour_title}</span>
							<span>{data?.tour_heading}</span>
						</h3>
					</div>
					<div className="con-journey-slider-wr"  >
						<div className="con-journey-slider">
							<Swiper
								modules={[Navigation]}
								initialSlide={isInitialSlide ? 8 : 1}
								slidesPerView={3}
								// slidesPerGroupSkip={3}
								spaceBetween={42}
								centeredSlides={true}
								// loopedSlidesLimit={false}
								// loop={true}
								navigation={{ clickable: true }}
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 30,
										centeredSlides: false,
									},
									767: {
										slidesPerView: 2,
										spaceBetween: 30,
										centeredSlides: false,
									},
									1024: {
										slidesPerView: 3,
										spaceBetween: 50,
										centeredSlides: true,
									},
								}}
							>
								{tourSlide?.map((tourSlideItem, index) => {
									return (
										<SwiperSlide>
											<div className="con-journey-slide">
												<div className="con-year-tag">
													<h4>{"#" + tourSlideItem.tour_item_year}</h4>
													<p
														dangerouslySetInnerHTML={{
															__html: tourSlideItem.tour_item_title,
														}}
													></p>
												</div>
												<div className="con-slide-overlay">
													<div className="con-slide-img">
														<div className="overlay-element top-left"></div>
														<div className="overlay-element top-right"></div>
														<div className="overlay-element bottom-left"></div>
														<div className="overlay-element bottom-right"></div>
														<figure>
															<img
																src={
																	"../assets/imgupload/" + tourSlideItem.image
																}
																alt="Anniversary Celebration"
															/>
															<figcaption>
																<a href={`/life-at-conative?anniversary=${encodeURIComponent(tourSlideItem.tour_item_title)}`}>
																	<h5>{"#" + tourSlideItem.tour_item_year}</h5>
																</a>
															</figcaption>
														</figure>
														<div className="slide-title">
															<h3
																dangerouslySetInnerHTML={{
																	__html: tourSlideItem.tour_item_title,
																}}
															></h3>
														</div>
													</div>
													<div className="con-slide-caption">
														<div className="slide-description">
															<p>{tourSlideItem.tour_item_content}</p>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</div>
				</div>
			</section>

			<section className="con-skills-wr">
				<div className="center-wrapper">
					<div className="con-skill-content clearfix">
						<div className="con-skill-left left">
							<div className="con-skill-title common-heading">
								<h3>
									<span>{data?.technologies_title}</span>
									<span>{data?.technologies_heading}</span>
								</h3>
							</div>
						</div>
						<div className="con-skill-right right">
							{techSlide?.map((techSlideItem) => (
								<div className="con-skill-blk">
									<img
										src={"../assets/imgupload/" + techSlideItem.image}
										alt={techSlideItem.image}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default About_us;
