import React from "react";

export const offlineSocialLink = [
    {
      socialurl:"https://www.behance.net/conativeitsolutions",
      description: `<svg xmlns="http://www.w3.org/2000/svg" width="25.073" height="15.909" viewBox="0 0 25.073 15.909">
      <defs>
        <style>
        .cls-1 {
          fill: #fff;
        }
        </style>
      </defs>
      <g id="Behance" transform="translate(3 -12.332)">
        <path id="Path_1187" data-name="Path 1187" class="cls-1" d="M52.947,17.088a6.935,6.935,0,0,0-.538-2.147,4.437,4.437,0,0,0-2.923-2.616,7.186,7.186,0,0,0-3.367-.138,4.955,4.955,0,0,0-3.552,2.4,6.837,6.837,0,0,0-.685,5.719,4.747,4.747,0,0,0,2.975,3.222,8.072,8.072,0,0,0,4.977.163,4.115,4.115,0,0,0,2.337-1.512,3.633,3.633,0,0,0,.677-2.321h-2.72a1.563,1.563,0,0,1-1.011,1.38,3.7,3.7,0,0,1-2.768.039,2.345,2.345,0,0,1-1.409-2.456H52.7c.121,0,.247.019.3-.134v-1.47a.6.6,0,0,1-.046-.134m-8.06-.369a2.686,2.686,0,0,1,2.939-2.362,2.234,2.234,0,0,1,2.12,2.362Z" transform="translate(-30.92 4.232)"></path>
        <path id="Path_1188" data-name="Path 1188" class="cls-1" d="M11.631,7.52a2.793,2.793,0,0,0-1.309-.414c.011-.019.014-.031.02-.033.023-.007.048-.009.071-.016a2.65,2.65,0,0,0,1.646-1.18,3.539,3.539,0,0,0,.415-2.1,3.9,3.9,0,0,0-.8-2.245A4.012,4.012,0,0,0,8.439.023C5.688-.016,2.936.007.185.006A1.768,1.768,0,0,0,0,.025V15.558c.089,0,.169.011.249.011q3.87,0,7.74,0a6.313,6.313,0,0,0,2.044-.32,4,4,0,0,0,2.755-2.656,5.8,5.8,0,0,0-.051-3.561,2.769,2.769,0,0,0-1.107-1.51M3.573,2.771h.3c1.307,0,2.613.022,3.919-.008A1.217,1.217,0,0,1,9.126,3.847,3.406,3.406,0,0,1,9.049,5.4a1.022,1.022,0,0,1-1,.822c-1.484.022-2.969.008-4.473.008Zm6.018,8.837a1.463,1.463,0,0,1-1.318,1.138,4.224,4.224,0,0,1-.681.048c-1.329,0-2.659,0-4.018,0V8.636H7.826a1.7,1.7,0,0,1,1.855,1.693,4.452,4.452,0,0,1-.09,1.281" transform="translate(-3 12.332)"></path>
        <rect id="Rectangle_692" data-name="Rectangle 692" class="cls-1" width="6.395" height="1.904" transform="translate(13.277 13.254)"></rect>
      </g>
      </svg>`
    },
    {
      socialurl:"https://twitter.com/CITSINDORE",
      description: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23" height="23" viewBox="0 0 23 23">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_133" data-name="Rectangle 133" width="23" height="23" transform="translate(13645 6512)" fill="#fff"></rect>
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectangle_132" data-name="Rectangle 132" width="23" height="21.539"></rect>
        </clipPath>
      </defs>
      <g id="black" transform="translate(-13645 -6512)" clip-path="url(#clip-path)">
        <g id="Group_259" data-name="Group 259" transform="translate(13645 6512.73)">
          <g id="Group_258" data-name="Group 258" clip-path="url(#clip-path-2)">
            <path id="Path_1617" data-name="Path 1617" d="M0,21.533l8.938-9.657L.076.026C.173.017.236.006.3.006Q3.5,0,6.692,0a.383.383,0,0,1,.349.172q2.744,3.682,5.5,7.356c.056.075.114.148.229.3a.966.966,0,0,1,.107-.2Q16.305,3.915,19.735.207A.55.55,0,0,1,20.2,0c.485.016.971,0,1.457.006.075,0,.149.008.275.015l-8.3,8.968L23,21.519c-.113.006-.187.013-.261.013q-3.172,0-6.345,0a.4.4,0,0,1-.37-.18q-2.983-4-5.976-8c-.065-.087-.132-.173-.209-.274-.441.475-.872.938-1.3,1.4Q5.378,17.9,2.222,21.319a.6.6,0,0,1-.5.219C1.171,21.522.624,21.533,0,21.533M3.034,1.484c.071.1.112.165.157.225l2.84,3.8Q11.411,12.7,16.788,19.894a.342.342,0,0,0,.312.153c.908-.006,1.817,0,2.725,0,.057,0,.114-.011.2-.02l-1.268-1.7q-4.037-5.4-8.074-10.792-2.2-2.943-4.4-5.885a.393.393,0,0,0-.255-.159c-.978-.011-1.957-.007-2.99-.007" transform="translate(0 0)"></path>
          </g>
        </g>
      </g>
    </svg>`
    },{
      socialurl:"https://www.facebook.com/conativeitsolutions",
      description:`<svg xmlns="http://www.w3.org/2000/svg" width="10.621" height="22.867" viewBox="0 0 10.621 22.867">
      <path data-name="Path 1016" d="M7 22.854c-.039 0-.073.006-.107.006-1.39.015-4.487.007-4.621-.013a.64.64 0 0 1-.01-.072V11.47c-.035-.04-.073-.029-.106-.03H.01c0-.031-.008-.056-.01-.081s0-.061 0-.091V7.419A.469.469 0 0 1 .08 7.4h2.159a.22.22 0 0 0 .019-.134V4.721a5.614 5.614 0 0 1 .415-2.106 4.12 4.12 0 0 1 .987-1.449A4.055 4.055 0 0 1 5.323.231 5.271 5.271 0 0 1 6.559.019C6.8.007 7.031 0 7.267 0q1.58 0 3.16.016c.043 0 .086 0 .135.005 0 .031.008.056.01.081s0 .061 0 .091V3.912a.3.3 0 0 1-.146.016H8.05A1.228 1.228 0 0 0 7.625 4a.852.852 0 0 0-.522.5 1.421 1.421 0 0 0-.094.538v2.347a.574.574 0 0 0 .08.017H10.459a.645.645 0 0 1 .161.009v.118c-.019.2-.039.391-.06.586l-.067.638-.1.938-.087.846-.085.834a.5.5 0 0 1-.014.057c-.035 0-.069.008-.1.009H7.023a.735.735 0 0 0-.014.083v11.144a.656.656 0 0 1-.009.19" transform="translate(.001)" style="fill:#fff"></path>
  </svg>`
    },{
      socialurl:"https://in.linkedin.com/company/conative-it-solutions-pvt-ltd",
      description:`<svg xmlns="http://www.w3.org/2000/svg" width="22.918" height="22.866" viewBox="0 0 22.918 22.866">
      <path data-name="Path 1018" d="M24.263 28.21v-1.087c0-2.2.007-4.409 0-6.613a9.324 9.324 0 0 0-.111-1.617c-.268-1.472-.976-2.108-2.3-2.137a2.445 2.445 0 0 0-2.723 2.073 10.238 10.238 0 0 0-.18 2.015c-.021 2.243-.008 4.486-.008 6.73v.648h-4.688V12.981h4.5v1.807a20.582 20.582 0 0 1 2.122-1.642c1.7-1.011 5.452-.807 6.9 1.238a6.72 6.72 0 0 1 1.168 3.828c.069 3.286.043 6.573.054 9.86a.543.543 0 0 1-.051.139z" transform="translate(-6.079 -5.354)" style="fill:#fff"></path>
      <path data-name="Rectangle 644" transform="translate(.403 7.619)" style="fill:#fff" d="M0 0h4.689v15.229H0z"></path>
      <path data-name="Path 1019" d="M2.7 5.482A2.7 2.7 0 0 1 0 2.693 2.716 2.716 0 0 1 2.749 0 2.741 2.741 0 1 1 2.7 5.482" transform="translate(-.001)" style="fill:#fff"></path>
      </svg>`
    },
    {
      socialurl:"https://www.instagram.com/conative_it_solutions/",
      description:`<svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="22.64" height="22.645" viewBox="0 0 22.64 22.645">
      <defs>
        <style>
          .cls-1 {
            fill: #fff;
          }
        </style>
      </defs>
      <path id="Path_1020" data-name="Path 1020" class="cls-1" d="M0,16.855V5.8c.014-.079.023-.159.043-.236A10.35,10.35,0,0,1,.52,3.809,6.052,6.052,0,0,1,6.277.025C9.636-.022,13,.011,16.357.015a6.033,6.033,0,0,1,3.57,1.108,6.154,6.154,0,0,1,2.707,5.257q.013,4.95,0,9.9a6.042,6.042,0,0,1-.115,1.273,6.144,6.144,0,0,1-4.655,4.905c-.331.092-.678.125-1.017.185H5.792c-.231-.036-.465-.06-.693-.112A6.169,6.169,0,0,1,.185,17.87C.094,17.54.06,17.194,0,16.855m2.007-5.525q0,2.453,0,4.905a4.491,4.491,0,0,0,.228,1.52,4.238,4.238,0,0,0,4.149,2.883c3.286,0,6.571.01,9.857-.009a4.864,4.864,0,0,0,1.5-.229,4.184,4.184,0,0,0,2.892-4.132c0-3.293.01-6.585-.009-9.877a4.8,4.8,0,0,0-.225-1.473,4.181,4.181,0,0,0-4.109-2.9q-4.962-.013-9.923,0A4.2,4.2,0,0,0,2.8,3.809,4.248,4.248,0,0,0,2.006,6.4q0,2.464,0,4.927" transform="translate(0 0)"></path>
      <path id="Path_1021" data-name="Path 1021" class="cls-1" d="M20.108,14.3a5.834,5.834,0,1,1-5.836-5.835A5.858,5.858,0,0,1,20.108,14.3m-5.826-3.823A3.82,3.82,0,1,0,18.1,14.3a3.825,3.825,0,0,0-3.814-3.827" transform="translate(-2.956 -2.964)"></path>
      <path id="Path_1022" data-name="Path 1022" class="cls-1" d="M25.98,5.851a1.467,1.467,0,1,1-1.473,1.456A1.478,1.478,0,0,1,25.98,5.851" transform="translate(-8.584 -2.05)"></path>
    </svg>`
    },
    {
      socialurl:"https://in.pinterest.com/conativeitsolutions/",
      description:`<svg xmlns="http://www.w3.org/2000/svg" width="18.649" height="23.963" viewBox="0 0 18.649 23.963">
      <g id="Pintrest" transform="translate(-494.412 -43.175)">
        <path id="Path_289" data-name="Path 289" d="M502.124,59.141c-.315,1.2-.619,2.4-.947,3.6a9.312,9.312,0,0,1-2.406,4.221c-.053.053-.11.1-.194.178a3.12,3.12,0,0,1-.14-.913,15.848,15.848,0,0,1-.089-3.634,32.592,32.592,0,0,1,.7-3.23c.351-1.543.717-3.083,1.086-4.622a.684.684,0,0,0-.044-.406,4.894,4.894,0,0,1,.292-4.295,2.3,2.3,0,0,1,1.749-1.2c1.551-.164,2.186,1.02,2.1,2.233a17.405,17.405,0,0,1-.843,3.392c-.133.472-.279.942-.376,1.422a2.048,2.048,0,0,0,1.759,2.471,3.669,3.669,0,0,0,3.461-1.529,6.974,6.974,0,0,0,1.395-3.313,7.639,7.639,0,0,0-.192-3.953,5.51,5.51,0,0,0-4.654-3.74,6.822,6.822,0,0,0-4.844.961,6.242,6.242,0,0,0-2.155,8.055.871.871,0,0,0,.134.224,1.17,1.17,0,0,1,.229,1.351c-.113.34-.173.7-.262,1.047-.083.322-.225.392-.529.244a4.658,4.658,0,0,1-2.017-2.09,8.385,8.385,0,0,1,1.046-9.183,9.009,9.009,0,0,1,5.947-3.131,10.065,10.065,0,0,1,5.623.669A7.983,7.983,0,0,1,513,50.778a9.624,9.624,0,0,1-2.379,7.657,6.5,6.5,0,0,1-5.688,2.274,3.558,3.558,0,0,1-2.738-1.543C502.189,59.153,502.159,59.152,502.124,59.141Z" transform="translate(0 0)" fill="#fff"></path>
      </g>
    </svg>`
    },
    {
      socialurl:"https://join.skype.com/invite/ubEWcG5vbx0x",
      description:`<svg id="Skype" xmlns="http://www.w3.org/2000/svg" width="26.496" height="26.537" viewBox="0 0 26.496 26.537">
      <defs>
        <style>
          .cls-1 {
            fill: #fff;
          }
        </style>
      </defs>
      <path id="Path_1024" data-name="Path 1024" class="cls-1" d="M25.577,13.268c-.051.578-.106,1.157-.151,1.736a.527.527,0,0,0,.06.275,7.465,7.465,0,0,1-3.458,10.6,7.27,7.27,0,0,1-6.658-.32.831.831,0,0,0-.523-.1A12,12,0,0,1,4.68,22.074a12,12,0,0,1-3.7-9.7,4.468,4.468,0,0,0,.086-.962,3.961,3.961,0,0,0-.4-.851A7.483,7.483,0,0,1,6.239.109a7.287,7.287,0,0,1,4.882.851.81.81,0,0,0,.521.1,12.076,12.076,0,0,1,11.01,4.275,12.178,12.178,0,0,1,2.924,7.932M13.037,21.085a8.966,8.966,0,0,0,3.845-.684,4.35,4.35,0,0,0,2.829-3.918,3.618,3.618,0,0,0-1.856-3.536,9.143,9.143,0,0,0-2.384-.952c-1.112-.29-2.231-.551-3.343-.838a3.732,3.732,0,0,1-1.541-.739,1.315,1.315,0,0,1-.052-2.048,3.1,3.1,0,0,1,.915-.57,4.819,4.819,0,0,1,2.5-.186,2.376,2.376,0,0,1,1.626.963c.187.247.344.516.514.775.142.215.279.433.425.645a1.294,1.294,0,0,0,1.474.445,1.393,1.393,0,0,0,1-1.166A2.582,2.582,0,0,0,18.3,7.35a5.139,5.139,0,0,0-2.67-1.62,9.945,9.945,0,0,0-3.738-.22A6.988,6.988,0,0,0,9.016,6.4a3.726,3.726,0,0,0-1.652,4.7,3.612,3.612,0,0,0,1.9,1.913,12.935,12.935,0,0,0,2,.751c.976.281,1.975.484,2.961.732a4.528,4.528,0,0,1,1.6.7,1.818,1.818,0,0,1,.2,2.695,3.087,3.087,0,0,1-1.1.718,5.23,5.23,0,0,1-2.5.247,2.888,2.888,0,0,1-2.345-1.552c-.145-.267-.258-.551-.409-.813a4.283,4.283,0,0,0-.519-.787,1.4,1.4,0,0,0-1.6-.228,1.317,1.317,0,0,0-.76,1.339,3.259,3.259,0,0,0,.37,1.265,5.065,5.065,0,0,0,2.424,2.307,8.312,8.312,0,0,0,3.459.694" transform="translate(-0.001 0)"></path>
    </svg>`
    }

  ]