import React from 'react'
import Meta from './Meta/Meta'
import Common_Banner from "./common/CommonBanner"
import { Link } from 'react-router-dom'
import YoutubeCarouselLatestHeader from './shopifyapps/youtube-latest-carousel/YoutubeCarouselLatestHeader'
import YoutubeCarouselLatestFooter from './shopifyapps/youtube-latest-carousel/YoutubeCarouselLatestFooter'

function ShopifyApp() {
	return (
		<>
			<YoutubeCarouselLatestHeader />
			<Meta
				title={"Youtube Latest Video Carousel"}
				desc={"Shopify App for Youtube Latest Video Carousel"}
				keywords={""}
			/>
			<Common_Banner title="Youtube Latest Video Carousel" />
			<section className="con-life-wr">
				<div className="center-wrapper">
				<div className="con-img-title-blk">
						<div
							className="con-about-img fadeIn"
							data-wow-duration="2s"
							data-wow-dealy="2s"
						>
							<img
								src={"../assets/imgupload/carouse-conative.jpg"}
								alt="Conative It"
							/>
						</div>
						<div className="con-overlay-title common-heading">
							<h2>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									Shopify App
								</span>
								<span
									className="wow fadeInDown"
									data-wow-duration="2s"
									data-wow-dealy="2s"
								>
									Youtube Latest Video Carousel
								</span>
							</h2>
						</div>
					</div>
					<div className="con-life-block">
						<div className="con-head-blk">
							<p>Add YouTube carousels to your store to boost engagement, enhance visuals, and increase conversions.</p>
							<p>Our app uniquely integrates YouTube carousels into your Shopify store, enhancing visual appeal and engagement. Merchants will love its simplicity and effectiveness. It solves the problem of static content by showcasing dynamic YouTube videos, driving customer interaction and increasing conversions. This feature-rich app helps merchants leverage video content to create a captivating shopping experience, ultimately boosting sales and customer satisfaction.</p>
						</div>
							<div className="con-lt-rt-wr">

								<div className="con-alternate-blk">
									<div class="common-heading con-life-heading-blk"></div>
									<div class="con-alternate-inner clearfix">
										<div className="con-left-blk wow pulse ">
											<figure className="con-life-img-blk">
												<img
													src={"/assets/imgupload/yt_Carousel_Banner_03.jpg"}
													alt="Conative Life"
													style={{ borderRadius: "14px" }}
												/>
											</figure>
										</div>
										<div className="con-right-blk wow fadeIn">
											<div className="common-heading con-life-heading-blk">
												<h3>Add a YouTube channel easily for dynamic content.</h3>
											</div>
											<div
												className="con-life-description-blk"
											>
												<p>Our app enables you to seamlessly integrate dynamic content from YouTube into your Shopify store. By simply entering your preferred YouTube channel and API key, you can enhance your store's appeal with engaging videos directly from your favorite creators. This straightforward process allows you to keep your audience entertained and informed with the latest updates and content from the selected channel, driving more engagement and traffic to your store.</p>
											</div>
										</div>
									</div>
								</div>

								<div className="con-alternate-blk">
									<div class="common-heading con-life-heading-blk"></div>
									<div class="con-alternate-inner clearfix">
										<div className="con-left-blk wow pulse ">
											<figure className="con-life-img-blk">
												<img
													src={"/assets/imgupload/yt_Carousel_Banner_02.jpg"}
													alt="Conative Life"
													style={{ borderRadius: "14px" }}
												/>
											</figure>
										</div>
										<div className="con-right-blk wow fadeIn">
											<div className="common-heading con-life-heading-blk">
												<h3>Effortless Content Management</h3>
											</div>
											<div
												className="con-life-description-blk"
											>
												<p>Managing your YouTube content on your Shopify store has never been easier. With our app, you can quickly change playlists and channels as needed, giving you complete control over the videos displayed. This flexibility ensures that your store remains up-to-date with the latest and most relevant content, keeping your audience engaged and informed.</p>
											</div>
										</div>
									</div>
								</div>

								<div className="con-alternate-blk">
									<div class="common-heading con-life-heading-blk"></div>
									<div class="con-alternate-inner clearfix">
										<div className="con-left-blk wow pulse ">
											<figure className="con-life-img-blk">
												<img
													src={"/assets/imgupload/yt_Carousel_Banner_01.jpg"}
													alt="Conative Life"
													style={{ borderRadius: "14px" }}
												/>
											</figure>	
										</div>
										<div className="con-right-blk wow fadeIn">
											<div className="common-heading con-life-heading-blk">
												<h3>Looks Amazing on any Screen</h3>
											</div>
											<div
												className="con-life-description-blk"
											>
												<p>The app is designed to be mobile responsive, ensuring that your video content looks great on all devices. Whether your customers are browsing on a desktop, tablet, or smartphone, they will have a seamless viewing experience. This feature is crucial in today’s mobile-first world, as it enhances user experience and ensures that your content reaches a broader audience effectively.</p>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div style={{marginTop:"50px", marginBottom:"20px", textAlign:"center"}}>
								<p>Please Refer to Our <Link to={"/apps/youtube-latest-carousel-privacy-policy"} style={{color:"#f84525"}}>Privacy Policy</Link> for Data Related Information</p>
							</div>
					</div>
				</div>
			</section>
			<YoutubeCarouselLatestFooter />
		</>
	)
}

export default ShopifyApp