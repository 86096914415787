import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import { useNavigate, useLocation } from "react-router-dom";
import "./header-submenu.css";
import ComapnySubmenu from "./companysubmenu";
import ServicesSubMenu from "./servicesSubMenu";
import IndustriesSubmenu from "./industriesSubMenu";
import PortfolioSubmenu from "./portfolioSubMenu";
import { offlineSocialLink } from "./offlineSocialLinkarr";
import { Link } from "react-router-dom";
import jQuery from "jquery";

const HeaderMenu = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(null);
  const location = useLocation()
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filler, setFiller] = useState([]);
  const [socialslink, setSocial] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [accordionActiveTab, setAccordionActiveTab] = useState(null)
  const id1 = "header-li-1"
  const id2 = "header-li-2"
  const id3 = "header-li-3"
  const accordion1 = "accordion-li-1"
  const accordion2 = "accordion-li-2"
  const accordion3 = "accordion-li-3"
  const currentPath = location.pathname;



  function handleSlugActive() {
    
    if (currentPath.includes("portfolio")) {
      setActiveTab(id3)
    } else if (currentPath.includes("services")) {
      setActiveTab(id2)
    } else {
      setActiveTab(id1)
    }
  }

  useEffect(() => {
    handleSlugActive()
    if(window.screen.width < 1024){
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [location.pathname])

  
  
  

  function handleIndustryClick() {


    try {
      props.close_menu();

      const industriesSection = document.getElementById('industries_section');

      if (window.location.pathname !== '/') {
        window.location.href = '/#industries_section';
      } else if (industriesSection) {
        industriesSection.scrollIntoView({ behavior: 'smooth' });
      }

    } catch (error) {
      
    }
  }



  var host = window.location.host;

  useEffect(() => {
    
    fetch(backendurl + "option-show")
      .then((response) => response.json())
      .then((data) => { setFiller(data) });
    fetch(backendurl + "social-media")
      .then((res) => res.json())
      .then((socialslink) => setSocial(socialslink));

  }, []);

  function manageActiveTab(id) {
    setActiveTab(id);
  }
  function manageAccordionTab(id) {
    if (accordionActiveTab === id) {
      setAccordionActiveTab(null)
    } else {
      setAccordionActiveTab(id);
    }
  }



  return (
    <>
      <div className="header-menu-wr">
        <div className="header-top-wrapper  clearfix">
          <div className="center-wrapper">
            <div className="header-logo-block left">
              <Link to="/">
                <img
                  src={"../assets/imgupload/" + (data?.image || "2022-11_Logo.png")}
                  alt="Conative It Solutions Logo"
                />
              </Link>
            </div>
            <div className="header-menu-block left">
              <nav className="header-menu ">
                <ul id="menu">
                  <li className="menu-item">
                    <Link to="/" className="menu-text">
                      Home
                    </Link>
                  </li>
                  <li onClick={() => {
                    manageActiveTab(id1);
                  }}
                    id={id1}
                    className={`menu-item ${activeTab === id1 ? 'header-menu-is-active' : ''}`}
                  >

                    <Link
                      id="header-tab-1" className="menu-text children">
                      Company
                    </Link>
                  </li>

                  <li onClick={() => {
                    manageActiveTab(id2);
                  }}
                    id={id2}
                    className={`menu-item ${activeTab === id2 ? 'header-menu-is-active' : ''}`}>
                    <Link id="header-tab-2" className="menu-text children">
                      Services
                    </Link>
                  </li>
                  <li onClick={() => {
                    manageActiveTab(id3);
                  }}
                    id={id3}
                    className={`menu-item ${activeTab === id3 ? 'header-menu-is-active' : ''}`}>

                    <Link id="header-tab-3" className="menu-text children">
                      Portfolio
                    </Link>
                  </li>

                  <li className="menu-item scroll-to-sec">
                    <Link onClick={handleIndustryClick}>Industries</Link>
                  </li>
                  <li className={`menu-item }`}

                  >
                    {socialslink?.length > 0 ? (
                      <a href="/contact-us" className="menu-text">
                        Contact Us
                      </a>
                    ) : (
                      <Link to="/" className="menu-text">
                        Contact Us
                      </Link>
                    )}

                  </li>
                </ul>
              </nav>
            </div>
            <div className="menu-close-icon right">
              <span onClick={props.close_menu}>X</span>
            </div>
          </div>
        </div>
        <div className="header-bottom-wrapper">
          <div className="center-wrapper">
            <div className="submenu-wrapper">
              <div className="header-conative-title">
                <h3>Conative</h3>
              </div>
              <div className="accordion-wrapper">
                <div className="accordion-head">
                  <h3>
                    <Link to="/">Home</Link>
                  </h3>
                </div>
              </div>


              <div
                className={`submenu-inner-component menu_tabs accordion-wrapper `}
                style={{ display:( isSmallScreen || activeTab === 'header-li-1') ? 'block' : 'none' }}
              >
                <div className="accordion-head"
                  onClick={() => {
                    manageAccordionTab(accordion1);
                  }}
                  id={accordion1}>
                  <h3>
                    <Link >Company</Link>
                  </h3>
                </div>
                <div className="accordion-content"
                  style={{ opacity: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-1' ? 1 : 0) : 1}`, maxHeight: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-1' ? "100%" : "0") : "100%"}` }}>
                  <ComapnySubmenu close_menu={props.close_menu} />
                </div>
              </div>


              <div
                className={`submenu-inner-component menu_tabs accordion-wrapper `}
                style={{ display: ( isSmallScreen || activeTab === 'header-li-2') ? 'block' : 'none' }}
                id="tab3"
              >
                <div className="accordion-head"
                  onClick={() => {
                    manageAccordionTab(accordion2);
                  }}
                  id={accordion2}>
                  <h3>
                    <Link  >Services</Link>
                  </h3>
                </div>
                <div className="accordion-content"
                  style={{
                    opacity: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-2' ? 1 : 0) : 1}`,
                    maxHeight: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-2' ? "100%" : "0") : "100%"}`, transition: '0'
                  }}>
                  <ServicesSubMenu close_menu={props.close_menu} />
                </div>
              </div>


              <div
                className={`submenu-inner-component menu_tabs accordion-wrapper `} style={{ display: ( isSmallScreen || activeTab === 'header-li-3') ? 'block' : 'none' }}
                id="tab4"
              >
                <div className="accordion-head"
                  onClick={() => {
                    manageAccordionTab(accordion3);
                  }}
                  id={accordion3}>
                  <h3>
                    <Link  >Portfolio</Link>
                  </h3>
                </div>
                <div className="accordion-content"
                  style={{ opacity: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-3' ? 1 : 0) : 1}`, maxHeight: `${isSmallScreen ? (accordionActiveTab === 'accordion-li-3' ? "100%" : "0") : "100%"}` }}>
                  <PortfolioSubmenu close_menu={props.close_menu} />
                </div>
              </div>


              <div className="submenu-inner-component accordion-wrapper">
                <div className="accordion-head scroll-to-section">
                  <h3>
                    <Link onClick={handleIndustryClick}>Industries</Link>
                  </h3>
                </div>
                {/* <div className="accordion-content">
                  <IndustriesSubmenu />
                </div> */}
              </div>
              <div className="accordion-wrapper">
                <div className="accordion-head">
                  <h3>
                    <Link to="/contact">Contact Us</Link>
                  </h3>
                </div>
              </div>
            </div>
            <div className="header-bottom-info-wrap">
              <h3>
                <strong>We are Hiring! Start your journey today</strong>
              </h3>
              {socialslink?.length > 0 ? (
                <Link to="/careers" className="common-round-btn">
                  Grow Career With Us
                </Link>
              ) : (
                <Link to="/" className="common-round-btn">
                  Grow Career With Us
                </Link>
              )}
            </div>
            <div className="header-contact-information clearfix">
              <div className="header-contact-left left">
                <div className="contact-details-block">
                  <span>or call us at</span>
                  <Link to={"tel:" + (data?.phone || "+91 82697 88173")}>{data?.phone || "+91 82697 88173"}</Link>
                </div>
                <div className="contact-details-block mail_us">
                  <span>or mail us at</span>
                  <ul className="inline-mail-list">
                    <li>
                      <Link to={"mailto:" + (data?.email || "info@conative.in")}>{data?.email || "info@conative.in"}</Link>
                    </li>
                    <li>
                      <Link className="hr_mail" to={"mailto:" + (data?.alternateemail || "hr@conative.in")}>
                        {data?.alternateemail || "hr@conative.in"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="social-icon-form-left right">
                <ul className="contact-social-icon-list">
                  {socialslink?.length > 0 ? (
                    <>
                      {socialslink?.map((social) => {
                        return social.socialurl != "" && (
                          <li key={social.socialurl}>
                            <Link
                              to={social.socialurl}
                              target="_blank"
                              rel="noreferrer"
                              dangerouslySetInnerHTML={{
                                __html: social.description,
                              }}
                            ></Link>
                          </li>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {offlineSocialLink?.map((social) => {
                        return social.socialurl != "" && (
                          <li key={social.socialurl}>
                            <Link
                              to={social.socialurl}
                              target="_blank"
                              rel="noreferrer"
                              dangerouslySetInnerHTML={{
                                __html: social.description,
                              }}
                            ></Link>
                          </li>
                        )
                      })}
                    </>
                  )}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMenu;
