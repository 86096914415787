import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ProjectSection() {
  const [data, setData] = useState([]);
  // const [img, setImg] = useState([]);
  const [gallerySlide, setGallerySlide] = useState([]);

  useEffect(() => {
    fetch(backendurl + "startpjt-show")
      .then((response) => response.json())
      .then((data) => setData(data));
    
    fetch(backendurl + "gallerySlideItem-show")
      .then((res) => res.json())
      .then((gallerySlide) => setGallerySlide(gallerySlide));
  }, []);
  // 
  return (
    <>
      
        <section className="get-started-wr" scroll-color="black">
   
            <div className="get-started-head">
              <div className="center-wrapper">
                {data.name ? <h6 className="wow fadeIn">{data.name}</h6> : <h6 className="wow fadeIn">Leading the market, with happy customers</h6> }
                {data.title ? (
                  <div className="get-started-description wow fadeInDown data-wow-duration='0.7s'">
                    <p>{data.title}</p>
                  </div>
                ) : (
                  <div className="get-started-description wow fadeInDown data-wow-duration='0.7s'">
                  <p>Why choose Conative?</p>
                </div>
                )} 
                {data.btnname ? (
                  <div className="get-started-btn wow fadeInDown data-wow-duration='0.5s'">
                    <Link to="/website-portfolio">
                      <span data-content="Let' Get Started">
                        {data.btnname}
                      </span>
                      <span className="arrow-icon">
                        <img
                          src="/assets/images/cta_lets_get_started.svg"
                          alt="Conative Icon"
                        />
                      </span>
                    </Link>
                    {/* <Link to="/">
                      <img
                        src="./assets/images/cta_lets_get_started.svg"
                        alt="Conative Icon"
                      />
                    </Link> */}
                  </div>
                ) : (
                  <div className="get-started-btn wow fadeInDown data-wow-duration='0.5s'">
                  <Link>
                    <span data-content="Let' Get Started">
                    Our Portfolio, Reason to Trust
                    </span>
                    <span className="arrow-icon">
                      <img
                        src="/assets/images/cta_lets_get_started.svg"
                        alt="Conative Icon"
                      />
                    </span>
                  </Link>
                </div>
                )}
              </div>
            </div>
         
            <div className="creator-content-area clearfix">
              <div className="get-started-slider-area left">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  slidesPerView={1}
                  navigation={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  // onSwiper={(swiper) => 
                  onSlideChange={() =>{}}
                  className="get-started-slide"
                >
                  <div className="get-started-slider">
                  {gallerySlide?.length > 0 ? (
                      gallerySlide.map((post, index) => (
                        <div className="get-started-slider-cls" key={index}>
                          <SwiperSlide>
                            <img
                              alt="Professional Life At Conative"
                              src={"/assets/imgupload/" + post.image}
                            />
                          </SwiperSlide>
                        </div>
                      ))
                    ) : (
                      <div className="get-started-slider-cls">
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-9_home_gallery.jpg"/>
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-10_Trip_Image.jpg"/>
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-9_Ganesh_Chaturthi_event.jpg"/>
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-9_Conative_Wall.jpg"/>  
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-9_Collage.jpg"/>
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-9_home_gallery.jpg"/>
                        </SwiperSlide>
                        <SwiperSlide>
                        <img alt="Professional Life At Conative" src="/assets/imgupload/2023-10_Trip_Image.jpg"/>
                        </SwiperSlide>
                      </div>
                    )}
                  </div>
                </Swiper>
              </div>
              <div className="get-started-content right">
                <div className="get-started-blk">
                  <h3 className="wow fadeInDown" data-wow-duration="1.5s">
                    <span>{data.description || "Conative"} </span>
                  </h3>
                  <div className="wow fadeInDown" data-wow-duration="1.5s">
                    {data.our_work_tagline ? (<span
                      dangerouslySetInnerHTML={{
                        __html: data.our_work_tagline,
                      }}
                    ></span>) : (
                      <span><h4>Want to explore more?<br/>
                      Get insights into<br/>
                      <strong>Life@Conative.</strong></h4></span>
                    )}
                    <span
                      dangerouslySetInnerHTML={{ __html: data.tagline }}
                    ></span>
                  </div>
                  <Link
                    to={data.our_work_url || "/"}
                    className="site-btn site-btn2 wow fadeInDown"
                    data-wow-duration="1.5s"
                  >
                    <span>Our Culture</span> <i className="fas fa-arrow-up"></i>
                  </Link>
                </div>
              </div>
            </div>
        </section>
    
    </>
  );
}

export default ProjectSection;
