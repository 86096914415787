import { useEffect,useState,useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { EventContext } from '../App';

const ScrollToTop = () => {
  const location = useLocation();
  // const {eventData,setEventData,setHaveEventData} = useContext(EventContext)



  
  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate()).padStart(2, '0');
  //   const mm = String(today.getMonth() + 1).padStart(2, '0');
  //   const yy = String(today.getFullYear());
  
  //   return `${yy}-${mm}-${dd}`;
  // };
  
  // const checkEventsForToday = async () => {
  //   try {
  //     const currentDate = getCurrentDate();
  
  //     const response = await axios.post("https://admin.conativeitsolutions.com/checkEventsForToday", {
  //       todaysDate: currentDate
  //     });
  
  //     if (response.status === 200) {
  //       const event = response.data;
        
  
  //       if (event && event?.events[0]?.eventsData?.eventDate && event?.events[0]?.eventsData?.eventDuration) {
  //         const startDate = new Date(event?.events[0]?.eventsData?.eventDate);
  //         const endDate = new Date(startDate);
  //         endDate.setDate(startDate.getDate() + parseInt(event?.events[0]?.eventsData?.eventDuration) + 1);
  
  //         const now = new Date();
          
          
          

  
  //         if (now >= startDate && now <= endDate) {
  //           setEventData(response);
  //           setHaveEventData(event?.events[0]?.eventName)
            
  //         } else {
            
  //         }
  //       } else {
          
  //       }
  //     } 
  //   } catch (error) {
  //     if(error.response.status === 400){
        
  //       setHaveEventData(undefined)
  //     }
  //     console.error(error, "Got an error while fetching data");
  //   }
  // };
  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return null; 
};

export default ScrollToTop;
