import React, { useEffect, useState } from "react";
import COMMON_BANNER from "../common/CommonBanner";
import backendurl from "../constants";
import { Link, useLocation } from "react-router-dom";
import Meta from "../Meta/Meta";
import $ from 'jquery';
import GraphicDesign from "../portfolio/GraphicDesign";

const GraphicsDesign = () => {
	const [data, setData] = useState([]);
	const [slicedData, setSlicedData] = useState([]);

	const [alldata, setAllData] = useState([]);
	const [alldesigns, setAllDesigns] = useState([]);

	const [slice_count, setSliceCount] = useState(0);
	const [custom_class, setCustomClass] = useState("");

	const search = useLocation();
	const [slug, setSlug] = useState("");

	useEffect(()=>{
		const slg = window.location.pathname?.split("/").pop()
		setSlug(slg)
		
	},[window.location.pathname])

	

	function makeSlices(sliceSize) {
		
		let arr = new Array();
		if(sliceSize === 1){
			for (let i = 0; i < data?.length; i += sliceSize) {
				arr.push(...data.slice(i, i+sliceSize))
			}
		}
		else {
			for (let i = 0; i < data?.length; i += sliceSize) {
				arr.push(data.slice(i, i+sliceSize))
			}
		}
		setSlicedData(arr);
	}

	async function fetchData() {
		try {
			let res, res_data;
			
			res = await fetch(backendurl + "graphicsDesignPost-single/" + slug);
			res_data = await res.json();
			
			setData(res_data?.images);
		} catch (error) {
			
		}
	}
	async function fetchAllData() {
		try {
			let res, res_data;
			
			res = await fetch(backendurl + "graphicsDesignPost-single/" + slug)
			res_data = await res.json();
			
			setAllData(res_data);

		} catch (error) {
			
		}
	}
	async function fetchAllDesign() {
		try {
			let res, res_data;
			
			res = await fetch(backendurl + "graphicsDesignPost-show");
			res_data = await res.json();
			
			setAllDesigns(res_data);

		} catch (error) {
			
		}
	}

	async function handleDataFetching () {
		try {
			await fetchData();
			await fetchAllData();
			await fetchAllDesign();
			// once all data is present
			
			await handleSlicing();
		} catch (err) {
			
		}
	}
	async function handleSlicing () {
		try {
			if (alldata?.design_type === "Logo Design") {
				setSliceCount(6);
				makeSlices(6);
			} else if (alldata?.design_type === "Flyer / Pamphlet Design" || alldata?.design_type === "Social Media Design") {
				setSliceCount(4);
				setCustomClass("four-col-wr");
				makeSlices(4);
			} else if (alldata?.design_type === "Brochure Design") {
				setSliceCount(2)
				setCustomClass("two-col-wr");
				makeSlices(2)
			} else if (alldata?.design_type === "Business Card Design") {
				setSliceCount(4)
				setCustomClass("four-col-wr four-up-down-wr");
				makeSlices(4)
			} else if (alldata?.design_type === "T-Shirt Design") {
				setSliceCount(2)
				setCustomClass("two-col-wr tshirt-col-wr");
				makeSlices(2)
			} else if (alldata?.design_type === "Corporate Brand Identity Design") {
				setSliceCount(1)
				setCustomClass("one-col-wr");
				makeSlices(1);
			}
		} catch (error) {
			
		}
	}

	useEffect(() => {
		setSlicedData([])
		handleDataFetching();
		handleSlicing();
	}, [data?.length, alldata?.length, alldesigns?.length, slug]);


	return (
		 slug === 'graphic-portfolio' ? 
		 	<GraphicDesign/>
		 	:
			<>
			<Meta
				title={alldata?.meta_title}
				desc={alldata?.meta_desc}
				keywords={alldata?.meta_keywords}
			/>
			<COMMON_BANNER title={alldata?.title} />
			{data ? (
				<section className="graphic-category-wr con-space-wr">
					<div className="center-wrapper">
						<div
							className="cits-inner-head-content cits-portfolio-wrapper"
							dangerouslySetInnerHTML={{ __html: alldata?.post_content }}
						></div>
						<div className="con-post-content logo-page-wr">
							<div className="post-tabs-blk">
								<div className="grpahic-sub-cat-wr" id="subcat">
									<ul className="grpahic-sub-category">
										{alldesigns?.toReversed()?.map((item) => (
											<li>
												<a
													href={"/graphic-portfolio/" + item.slug}
													data-tab="#logoTab"
													className={item.slug === slug ? "active" : ""}
												>
													{item.title}
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className="post-tabs-wr graphic-subcat-blk" id="logoTab">
								<div
									className={"grpahic-subcat-row " + custom_class}
									data-slice={slice_count}
								>
									{slicedData?.length >0 && slicedData?.map((slice, index) => (
										<div key={index} className="subcat-inner-wr">
											{
												Array.isArray(slice) ? slice?.map((item, idx) => (
												<div
													key={item}
													className="subcat-col wow pulse"
													data-wow-duration="2s"
													data-wow-dealy="2s"
												>
													<img src={"/assets/imgupload/" + item} alt="" />
												</div>
												)):
												(
													<div
														key={slice}
														className="subcat-col wow pulse"
														data-wow-duration="2s"
														data-wow-dealy="2s"
													>
														<img src={"/assets/imgupload/" + slice} alt="" />
													</div>
												)
											}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				""
			)}
		</>
	
	);
};

export default GraphicsDesign;
